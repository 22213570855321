
:root {
    --table-body-border: 1px solid rgba(10, 9, 3, 0.16);
}

table {
    thead th {
        background-color: var(--color-gray);
        border-radius: 2px;
    }

    tbody tr {
        /*&:not(:last-child) {
            border-block-end: var(--table-body-border) ;
        }*/

        &:nth-child(odd) {
            background-color: var(--secondary-light-color);
        }
    }

    td,
    th {
        padding: 15px;
        text-align: start;
        min-width: 120px;

        @include mobile {
            padding: 10px;
            min-width: 140px;
        }
    }

    @include desktop {
        font-size: 14px;
    }
}

.Table-Wrapper {
    max-width: 100%;
    overflow-x: auto;
}
