/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --video-play-icon-background: var(--primary-dark-color);
    --video-play-icon-hover-background: var(--primary-dark-color);
    --video-play-icon-hover-scale: 1.05;
}

.VideoPlayIcon {
    cursor: pointer;
    transition: transform .25s ease-out;

    &-Background {
        fill: var(--video-play-icon-background);
        transition: fill .25s ease-out;
    }

    &-Play {
        fill: var(--color-white);
    }

    &-Border {
        stroke: #FAEEED;
    }

    &:hover {
        transform: scale(var(--video-play-icon-hover-scale));

        .VideoPlayIcon {
            &-Background {
                fill: var(--video-play-icon-hover-background);
            }
        }
    }
}
