/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

@import '../../style/base/swiper';

.PushContentHomepage {
    &-Wrapper {
        @include mobile {
            padding-inline: 0;
        }
    }

    &-Content {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        row-gap: 40px;
        column-gap: 10px;
    }

    .swiper {
        flex-basis: 100%;
        width: 100%;
        overflow: visible;
    }

    .swiper-button-next-pch,
    .swiper-button-prev-pch {
        @include swiper-button(prev);

        position: relative;
        inset: auto;
        margin: 0;
        width: 64px;
        height: 64px;
        margin-block-end: calc(#{fluid(15px, 90px, 810px, 1560px)} * -1);

        &::after {
            font-size: 18px;
        }

        @include mobile {
            display: none;
        }
    }

    .TextPlaceholder {
        &::after {
            display: block;
            width: 100%;
            height: 150px;
        }
    }

    &-Item {
        @include mobile {
            display: flex;
            overflow: hidden;
        }
    }

    &-ItemImage {
        aspect-ratio: 487 / 465;

        .Image-Image {
            object-fit: cover;
        }
    }

    &-ItemImageWrapper {
        @include mobile {
            flex-basis: 60%;
        }
    }

    &-ItemContent {
        background-color: var(--primary-base-color);
        color: var(--color-white);
        z-index: 10;

        @include animation;

        @include mobile {
            padding: 20px;
            padding-left: 0;
            flex-basis: 40%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        @include desktop {
            padding: 50px;
            padding-top: 10px;
        }

        &::after {
            content: " ";
            background-color: var(--primary-base-color);
            position: absolute;
            left: 0;
            right: 0;
            z-index: -1;
            transition: all .2s ease-in;

            @include desktop {
                top: -70px;
                transform: skewY(-7deg);
                height: 114px;
            }

            @include mobile {
                transform: skewX(-15deg);
                top: 0;
                bottom: 0;
                left: -60px;
            }
        }
    }

    &-ItemSuperTitle {
        font-weight: bold;

        @include mobile {
            font-size: 12px;
        }

        @include desktop {
            font-size: 16px;
        }
    }

    &-ItemTitle {
        font-weight: 300;

        @include mobile {
            font-size: 20px;
            margin-top: 5px;
        }

        @include desktop {
            font-size: 32px;
            margin-top: 12px;
            min-height: 117px;
        }

        &::after {
            display: block;
            content: "";
            width: 40px;
            height: 20px;
            background: url($path-img + 'arrow-link.svg') no-repeat center;
            background-size: 100% auto;

            @include animation;

            @include desktop {
                opacity: 0;
                margin-top: 10px;
                position: absolute;
            }

            @include mobile {
                margin-top: 5px;
                position: relative;
            }
        }
    }

    &-ItemLink {
        @include desktop {
            &:hover {
                .PushContentHomepage-ItemTitle {
                    &::after {
                        opacity: 1;
                        height: 20px;
                    }
                }

                .PushContentHomepage-ItemContent {
                    padding-top: 0;
                    padding-bottom: 60px;

                    &::after {
                        top: -75px;
                    }
                }
            }
        }
    }

    .swiper-slide {
        @include desktop {
            max-width: 487px; // @TODO temporary
        }
    }

    .swiper-pagination {
        position: relative;
        bottom: 0;
        margin-top: 15px;

        @include desktop {
            display: none;
        }
    }
}
