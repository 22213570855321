/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.HomePage {
    overflow: hidden;
    padding-block-end: #{fluid(20px, 100px)};

    @include desktop {
        margin-block-start: calc(-1 * var(--header-total-height) - var(--content-top-banner-height) - 15px); // 15px : when main nav links are on 2 lines
        padding-block-start: calc(var(--header-total-height) + var(--content-top-banner-height) + 15px);
    }

    > section {
        padding-block: #{fluid(15px, 90px)};

        &:first-child {
            padding-block: #{fluid(15px, 50px)};
        }
    }
}

.Header-Wrapper_isHomePage ~ .Breadcrumbs {
    display: none;
}
