/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.NewIssueForm {
    &-SelectField {
        margin-block-start: 0;
    }

    &-OrderFieldGroup {
        margin-block-start: 24px;

        &:has(.Field_hasError) {
            .NewIssueForm-SearchWrapper {
                border-color: var(--primary-error-color);
            }
        }
    }

    &-OrderFieldWrapper {
        display: flex;
        align-items: center;

        .Field-Wrapper {
            width: 100%;
        }

        .Field {
            margin-block-start: 0;
        }

        .ChevronIcon {
            display: inline-block;
            width: 14px;
            height: 14px;
            pointer-events: none;
            position: absolute;
            inset-block-start: calc(var(--input-height) / 2 - 7px);
            inset-inline-end: 12px;
        }
    }

    &-OrderField {
        --input-border-color-focus: var(--input-border-color);

        input {
            //height: var();
            cursor: pointer;
        }

        &_isExpanded {
            --input-border-radius: 15px 15px 0 0;

            input {
                &,
                &:focus {
                    border-block-end-color: transparent !important;
                }
            }
        }
    }

    &-SearchWrapper {
        display: none;
        position: absolute;
        top: var(--input-height);
        z-index: 5;
        width: 100%;
        min-height: 60px;
        padding: 0 15px 10px;
        background-color: var(--color-white);
        border: {
            width: 0 1px 1px;
            style: solid;
            color: var(--primary-divider-color);
        };

        border-radius: 0 0 15px 15px;

        &_isVisible {
            display: block;
        }
    }

    &-SearchInputWrapper {
        display: flex;
        align-items: center;

        .SearchIcon {
            position: absolute;
            inset-inline-end: 20px;
            opacity: .5;
        }
    }

    &-SearchInput {
        --input-border-color: transparent;
        --input-border-color-focus: var(--input-border-color);
        --input-border-radius: 20px;

        width: 100%;
        height: 40px;
        background-color: var(--secondary-light-color);
        font-size: 12px;
    }

    &-OrderList {
        min-height: 53px;
        margin-block-start: 8px;
    }

    &-Order {
        &:not(:last-child) {
            border-block-end: 1px solid var(--primary-divider-color);
        }

        button {
            display: flex;
            flex-wrap: wrap;
            column-gap: .3em;
            width: 100%;
            padding-block: 10px;
            padding-inline: 10px;
            font-size: 14px;
            text-align: start;
            cursor: pointer;

            &:hover {
                background-color: var(--secondary-light-color);
            }
        }

        &Id {
            font-weight: 700;
        }

        &Date {
            &::before {
                content: "-";
                margin-inline-end: .3em;
            }
        }

        &Status {
            flex-basis: 100%;
            font-style: italic;
            font-size: 12px;
        }
    }

    &-TextArea {
        textarea {
            height: 200px;
        }
    }

    &-FileField {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-block-start: 15px;

        .Field {
            margin-block-start: 0;

            [type='file'] {
                height: auto;
                opacity: 1;
                border-color: var(--input-border-color);

                & + label {
                    all: unset;
                    display: none;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: baseline;
                    column-gap: 10px;
                    margin-block-start: 5px;

                    p {
                        display: none;
                    }

                    button {
                        all: unset;
                        font-size: 13px;
                        font-weight: 500;
                        text-decoration: underline;
                        text-underline-offset: .15em;
                        color: var(--primary-dark-color);
                        cursor: pointer;
                    }

                    &.FieldFile-Label_hasFile {
                        display: flex;
                    }
                }
            }

            .FieldFile {
                &-AllowedTypes {
                    display: none;
                }
            }
        }
    }

    &-FileActions {
        font-weight: 500;
        font-size: 12px;
        color: var(--primary-dark-color);
        text-align: center;

        .Button {
            margin-inline-end: .3em;
            font-weight: 500;
            text-decoration: underline;
            text-underline-offset: .15em;
        }
    }

    &-Actions {
        margin-block-start: 20px;

        .Button {
            width: 100%;
        }
    }
}
