/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.FaqContact {
    padding: 30px;
    background-color: var(--secondary-light-color);

    @include mobile {
        margin-left: -14px;
        margin-right: -14px;
    }

    &-Title {
        margin-block: 0 20px;
        font-weight: 300;
        font-size: 22px;
        line-height: 1.2;
        text-transform: none;
        text-align: center;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
    }

    .CustomerService {
        flex-direction: column;
        align-items: center;

        &-Title {
            margin-bottom: 0.75em;
        }

        .Button_CustomerService {
            width: auto;
        }
    }
}
