/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --header-height: 90px;
    --header-nav-height: 50px;
    --header-button-margin: 15px;
    --header-checkout-content-height: 50px;

    @include mobile {
        --header-total-height: calc(var(--header-height) + var(--offline-notice-height) + var(--demo-notice-height) + env(safe-area-inset-top) + var(--top-page-banner-height, 0));
    }

    @include desktop {
        --header-top-menu-height: 40px;
        // --header-height: calc(var(--header-nav-height) + var(--header-top-menu-height) + var(--menu-total-height));
        // --header-height: 175px;
        --header-nav-height: 54px; // 18 (margin-top) + 20 (height) + 16 (margin-bottom)
        --header-checkout-content-height: 125px;
        --header-height: calc(var(--header-checkout-content-height) + var(--header-nav-height));
    }
}

@mixin button-visible {
    opacity: 1;
    height: 25px;
    width: 25px;
    pointer-events: all;
    overflow: visible;
    cursor: pointer;
}

.Header {
    &-MinicartItemCount {
        bottom: -3px;
        top: inherit;
        right: -4px !important;
        pointer-events: none;

        @include mobile {
            min-width: 15px;
            height: 15px;
            font-size: 10px;
        }
    }

    &-WishlistItemCount {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 17px;
        height: 17px;
        padding: 2px;
        background: var(--primary-base-color);
        border-radius: 8px;
        font-size: 11px;
        font-weight: 700;
        color: var(--color-white);
        position: absolute;
        inset-block-end: -2px;
        inset-inline-end: -4px;
        pointer-events: none;

        @include mobile {
            min-width: 15px;
            height: 15px;
            font-size: 10px;
        }
    }

    @include mobile {
        inset-block-start: calc(var(--demo-notice-height) + var(--top-page-banner-height));
        border-bottom-width: 0;
        transform: translateY(0);
        transition: transform 200ms cubic-bezier(.47, 0, .745, .715);

        .hideOnScroll & {
            transform: translateY(calc(-1 * 100% - var(--top-page-banner-height)));
        }

        &-Nav {
            padding-inline: 0;
            border-bottom: 1px solid var(--primary-divider-color);
        }

        &_isCheckout {
            .Header {
                &-LogoWrapper {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    @include desktop {
        position: relative;
        background: transparent;

        &-Content {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: auto;
            max-width: var(--content-wrapper-width);
            min-height: var(--header-checkout-content-height);
            padding-inline: var(--content-wrapper-padding);
        }

        &-TopMenu,
        &-Nav,
        &-Checkout {
            width: 50%;
            margin: 0;
            max-width: none;
            padding-inline: 0;
        }

        &-Nav {
            padding-top: 10px;
            display: flex;
        }

        &-LogoWrapper {
            order: 2;
            width: 25%;
            margin-block: 10px 0;
            margin-inline: auto;
            position: relative;
        }

        &_isCheckout {
            .Header {
                &-Content {
                    align-items: center;
                }

                &-Nav,
                &-Checkout {
                    width: 37.5%;
                }

                &-Nav {
                    order: -1;
                    padding-top: 0;
                }

                &-LogoWrapper {
                    order: initial;
                    margin-block: auto;
                }
            }
        }
    }

    @include wide-desktop {
        &-TopMenu,
        &-Nav,
        &-Checkout {
            width: 40%;
        }

        &-Nav {
            padding-top: 16px;
        }

        &-LogoWrapper {
            order: initial;
            width: 20%;
            margin: 46px 0 24px;
        }

        &_isCheckout {
            .Header {
                &-Nav,
                &-Checkout {
                    width: 40%;
                }

                &-Nav {
                    order: -1;
                }

                &-LogoWrapper {
                    margin-block: auto;
                }
            }
        }
    }

    &-MenuButton {
        display: inline-block;
        cursor: pointer;
        z-index: 100;
        width: 45px;
        height: 45px;
        border: none;
        border-radius: 0;
        padding: 0;
        background: #fff;
        line-height: 0.6;
        text-align: center;

        @include desktop {
            display: none;
        }

        > span {
            // the second divider
            display: inline-block;
            // position: relative;
            height: 2px;
            width: 16px;
            border-radius: 1px;
            background: #293335;
            vertical-align: middle;
            // no-txt
            font-size: 0;
            text-indent: 100%;
            position: absolute;
            top: 22px;
            left: 12px;

            &::before,
            &::after {
                // the first & the third dividers
                display: inline-block;
                position: absolute;
                content: "";
                height: 2px;
                width: 26px;
                border-radius: 1px;
                background: #293335;
                // for the hover state
                transition: all 200ms;
                transform-origin: left center;
            }

            &::before {
                top: -8px;
                left: 0;
                //width: 22px;
                transform: scale(.8, 1);
            }

            &::after {
                top: 8px;
                left: 0;
                //width: 10px;
                transform: scale(.4, 1);
            }
        }

        &:focus {
            outline: none
        }

        //&:hover > span::after,
        //&:hover > span::before {
        //    width: 34px;
        //    left: 0;
        //}

        &.Button_active {
            > span {
                height: 0;

                &::after,
                &::before {
                    //top: 0;
                    //left: 0;
                    //width: 26px;
                }

                &::after {
                    transform: rotate(-45deg) translate(0, 2px) scale(1);
                }

                &::before {
                    transform: rotate(45deg) translate(0, -1px) scale(1);
                }
            }
        }
    }

    &-LogoWrapper {
        @include mobile {
            margin: 0;
        }
    }

    &-Button {
        &_type {
            &_minicart {
                margin-inline-start: var(--header-button-margin);

                @include mobile {
                    margin-inline-end: var(--content-wrapper-padding-mobile);
                }

                @include desktop {
                    width: 30px;
                    height: 28px;
                }
            }

            &_wishlist {
                @include button-visible;

                margin-inline-start: var(--header-button-margin);

                @include mobile {
                    margin-inline-start: auto;

                    .HeartIcon {
                        path {
                            stroke-width: 1.3;
                        }
                    }
                }

                @include desktop {
                    --wishlist-heart-size: 30px;

                    height: 30px;
                    width: 30px;

                    .HeartIcon {
                        path {
                            stroke-width: 1.6;
                        }
                    }
                }
            }
        }
    }

    &-SearchButton {
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        text-align: left;

        .SearchIcon {
            flex: 0 0 auto;
            margin-inline-end: 9px;
        }

        > span {
            flex: 1 1 auto;
            cursor: text;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 14px;
        }
    }

    &-Wrapper {
        @include mobile {
            margin-block-end: calc(var(--header-total-height) + var(--top-page-banner-min-height));
        }

        @include desktop {
            margin-block-end: 0;
        }

        &_isCheckout {
            // margin-block-end: var(--header-checkout-content-height);
            margin-block-end: 0;
        }

        &_isHomePage {
            @include desktop {
                .Header {
                    border-block-end-color: transparent;
                }
            }
        }
    }

    &_isCheckout {
        .Header {
            &-Button {
                &_isVisible {
                    @include desktop {
                        @include button-visible;
                    }
                }
            }

            &-Button_type_close,
            &-Button_type_back {
                @include desktop {
                    display: block;
                }
            }

            &-Button_type_back {
                @include button-visible;

                width: 22%;
                align-items: center;

                &.Header-Button_isVisible {
                    display: inline-flex;
                }

                .ChevronIcon {
                    margin-right: 5px;
                }

                span {
                    font-size: 11px;
                    font-weight: 500;
                }

                @include desktop {
                    width: auto;

                    .ChevronIcon {
                        margin-right: 15px;
                    }

                    span {
                        font-size: 10px;
                        letter-spacing: .2em;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    .StoreLocatorLink {
        @include desktop {
            margin-inline-start: var(--header-button-margin);

            .StoreLocatorIcon {
                width: 30px;
                height: 27px;
            }
        }
    }

    &-MyAccountContainer {
        display: flex;
        margin-inline-start: var(--header-button-margin);

        @include mobile {
            margin-inline-start: var(--header-button-margin);
        }
    }

    &-MyAccount {
        @include desktop {
            .UserIcon {
                width: 28px;
                height: 25px;

                &_isPro {
                    width: 32px;
                    height: 34px;
                }

                &_isStudent {
                    height: 30px;
                    transform: translate(0, -6px);
                }
            }
        }
    }

    &-TopMenu {
        align-items: center;
        justify-content: flex-start;

        > * {
            margin-right: 30px;
        }

        a {
            --link-color: var(--default-color);

            font-weight: 500;
            font-size: 10px;
            text-transform: uppercase;
            letter-spacing: .2em;
        }
    }

    &-Switcher {
        &Button {
            display: inline-flex;
            align-items: center;

            .ChevronIcon {
                margin-left: 5px;
            }
        }

        &Content {
            display: none;
            position: absolute;
            //top: calc(100% + 10px);
            top: 100%;
            left: 0;
            min-width: 360px;
            z-index: 20;

            &_isVisible {
                display: flex;
            }
        }

        .StoreSwitcher {
            margin-inline-start: 0;
            padding-block: 15px;
            padding-inline: 0 15px;
            // background: #fff;
        }
    }

    &-Checkout {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .CustomerService {
            .Button {
                margin-inline: 0;

                &_Contact {
                    display: none;
                }
            }
        }

        @include ultra-narrow-desktop {
            padding-top: 0;

            .CustomerService {
                &-Title {
                    display: none;
                }
            }

            .PaymentMethods {
                display: none;
            }
        }
    }
}
