/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.StoreSwitcher {
    --input-height: 45px;

    display: flex;
    border-block-end: none;

    @include mobile {
        margin: 0;
    }

    @include desktop {
        flex-shrink: 0;
    }

    &-CurrentWebsiteFlag {
        width: 20px;
        height: 20px;
        position: absolute;
        inset-block-start: 50%;
        inset-inline-start: 15px;
        z-index: 10;
        transform: translate(0, -50%);
    }

    .Field {
        &-Wrapper {
            & + .Field-Wrapper {
                margin-inline-start: 15px;
            }

            @include mobile {
                flex: 1 0 auto;
            }
        }

        &Select {
            border: 1px solid var(--input-border-color);
            border-radius: var(--input-border-radius);
            background-color: var(--input-background-color);

            &_isExpanded {
                border-radius: var(--input-border-radius) var(--input-border-radius) 0 0;
            }

            &-Clickable {
                padding-inline: 15px 0;
            }

            &-Select {
                margin-block-start: 0;
                padding-block: 12px;
            }

            &-Options {
                inset-inline-start: -1px;
                width: calc(100% + 2px);
            }

            &-Option {
                padding-inline: 15px;
            }

            .Footer & {
                &_isExpanded {
                    border-radius: 0 0 var(--input-border-radius) var(--input-border-radius);
                }

                &-Select {
                    border-end-end-radius: var(--input-border-radius);
                }

                &-Options {
                    inset-block-start: auto;
                    inset-block-end: 100%;
                    border-width: 1px 1px 0;

                    &_isExpanded {
                        border-start-start-radius: var(--input-border-radius);
                        border-start-end-radius: var(--input-border-radius);
                        border-end-end-radius: 0;
                        border-end-start-radius: 0;
                    }
                }
            }
        }
    }

    #WebsiteSwitcher_wrapper {
        text-transform: capitalize;

        .FieldSelect-Clickable {
            padding-inline-start: 45px;
        }
    }

    .ChevronIcon {
        inset-inline-end: 15px;
    }
}
