/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Locations {
    &-Title {
        margin-block: 15px;
        padding-inline: 1em;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
    }

    &-Geolocation {
        margin-block-end: 20px;
        text-align: center;

        .Button {
            font-weight: 500;
            text-decoration: underline;
            text-underline-offset: .15em;
        }
    }

    @include mobile {
        .LocationAutosuggest {
            margin-block-end: 15px;
        }
    }

    .TextPlaceholder {
        display: block;

        &_length {
            &_block {
                line-height: 56px;

                &::after {
                    display: block;
                }
            }

            &_long {
                margin-block-end: 12px;
            }

            &_medium {
                margin-block-start: 5px;
            }

            &_short {
                margin-block-start: 25px;
            }
        }
    }

    &-PlaceholderItem {
        padding-block: 25px;
        padding-inline: var(--content-wrapper-padding-mobile);
    }

    &-Selected {
        display: flex;
        padding-block-start: 10px;
        padding-block-end: 25px;
        border-block-end: 1px solid var(--primary-divider-color);

        .LocationDetails {
            flex: 1 1 auto;

            &-StreetBlock,
            &-CityBlock {
                font-size: var(--paragraph-font-size-mobile);
                font-weight: var(--paragraph-font-weight-mobile);
                line-height: var(--paragraph-line-height-mobile);

                @include desktop {
                    font-size: var(--paragraph-font-size);
                    font-weight: var(--paragraph-font-weight);
                    line-height: var(--paragraph-line-height);
                }
            }

            &-Name,
            &-StreetLine {
                margin-bottom: 6px;
            }
        }

        &ChangeButton {
            flex: 0 0 auto;
            margin-inline-start: 20px;
            font-size: 12px;
            font-weight: 500;
            text-decoration: underline;
            text-underline-offset: 0.15em;

            @include mobile {
                width: auto;
            }
        }
    }
}
