/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.FieldFileAdvanced {
    &-Overview {
        max-width: 500px;
        margin-inline: auto;
        margin-block-end: 20px;
        aspect-ratio: 32 / 20;

        > label {
            display: block;
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }

    &-OverviewEmpty {
        display: block;
        width: 100%;
        height: 100%;

        &Inner {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
        }

        span {
            display: block;
            width: 137px;
            height: 100px;
            background: url($path-img + 'id-card.svg') no-repeat center;
            background-size: contain;
        }

        // CORNERS

        &,
        &Inner {
            &::before,
            &::after {
                content: "";
                display: block;
                width: 23px;
                height: 23px;
                background: url($path-img + 'corner.svg') no-repeat center;
                background-size: contain;
                position: absolute;
                transform-origin: center;
            }
        }

        &::before {
            top: 0;
            left: 0;
        }

        &::after {
            top: 0;
            right: 0;
            transform: rotate(90deg);
        }

        &Inner::before {
            bottom: 0;
            left: 0;
            transform: rotate(-90deg);
        }

        &Inner::after {
            bottom: 0;
            right: 0;
            transform: rotate(180deg);
        }
    }

    &-OverviewPicture {
        display: block;
        width: 100%;
        height: 100%;

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

    &-OverviewFile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 20px;
        background-color: var(--primary-light-color);

        &Type {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100px;
            height: 100px;
            margin-block-end: 10px;

            span {
                display: none;
            }

            &::before {
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                background: url($path-img + 'documents/doc-file.svg') no-repeat center;
                background-size: contain;
            }

            &_ext_pdf {
                &::before {
                    background-image: url($path-img + 'documents/doc-pdf.svg');
                }
            }

            &_type_video {
                &::before {
                    background-image: url($path-img + 'documents/doc-video.svg');
                }
            }

            &_type_image {
                &::before {
                    background-image: url($path-img + 'documents/doc-image.svg');
                }
            }
        }

        &Name {
            font-weight: 700;
            font-size: 15px;
        }
    }

    &-Description {
        margin-block-end: 30px;

        p {
            font-style: italic;
            font-size: 13px;
            font-weight: 300;
            line-height: 1.5;
            text-align: center;
        }
    }

    &-InputWrapper {
        display: none;
    }

    &-AllowedTypes,
    &-MaxAllowedSize {
        margin-block-end: 0.35em;
        font-style: italic;
        font-size: 12px;
    }

    &-Buttons {
        text-align: center;

        &:not(:first-child) {
            margin-block-start: 20px;
        }

        .Button {
            margin-block-start: 0;

            &_likeLink {
                margin-block-start: 0;
                font-weight: 500;
                text-decoration: underline;
                text-underline-offset: .15em;
            }

            @include desktop {
                width: auto;
            }

            & + p {
                margin-block: .5em;
            }
        }
    }

    &-ModifyButton {
        // ...
    }
}
