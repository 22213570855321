/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.TutorialOptin {
    padding-inline: var(--content-wrapper-padding);
    background-color: #f0bab8;
    color: var(--color-white);

    @media (max-width: 1120px) {
        background-image: url('/style/images/tuto/bg-tuto-nl.jpg');
        background-repeat: no-repeat;
        background-position: -240px center;
        background-size: auto 100%;
    }

    @include mobile {
        padding-inline: var(--content-wrapper-padding-mobile);
        background-image: none;
        padding-block: 40px;
    }

    &-Content {
        text-align: center;
        width: 1260px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;

        @include desktop {
            padding-left: var(--content-wrapper-padding);
            padding-right: var(--content-wrapper-padding);
            min-height: 515px;
            background-image: url('/style/images/tuto/bg-tuto-nl.jpg');
            background-repeat: no-repeat;
            background-position: left center;
            background-size: auto 100%;
            display: flex;
            align-items: center;
            justify-content: right;
        }

        @media (max-width: 1120px) {
            background-image: none;
        }
    }

    &-ContentInner {
        @include desktop {
            width: 520px;
        }
    }

    &-Title {
        font-weight: 300;
        font-size: var(--h1-font-size);

        @include mobile {
            font-size: 22px;
        }
    }

    &-SubTitle {
        font-weight: 700;
        font-size: 20px;

        @include mobile {
            font-size: 14px;
        }
    }

    .NewsletterSubscription {
        @include desktop {
            margin-top: 40px;
        }

        @include mobile {
            margin-top: 20px;
        }

        .FieldForm-Fieldset {
            .Field {
                &_type {
                    &_email {
                        input {
                            border-color: #EAEAEA;

                            @include desktop {
                                height: 60px;
                            }
                        }
                    }

                    &_checkbox {
                        --box-color: transparent;
                        --imported_input_border_color: var(--color-white);

                        [type='checkbox']:checked + .input-control::after {
                            --checkmark-color: var(--primary-dark-color);
                        }

                        a {
                            color: var(--color-white);
                            text-decoration: underline;

                            &:hover {
                                text-decoration: none;
                            }
                        }

                        .Field-CheckboxLabel:not([class*='_isDisabled']):hover {
                            color: var(--color-white);
                        }

                        @include desktop {
                            margin-block-start: 2em;
                            padding-inline-start: 20px;

                            & + .Field-ErrorMessages {
                                padding-inline-start: calc(28px + var(--imported_checkboxes_width));
                            }
                        }

                        @include mobile {
                            & + .Field-ErrorMessages {
                                padding-inline-start: calc(16px + var(--imported_checkboxes_width));
                            }
                        }
                    }
                }
            }
        }

        .Button {
            background-color: var(--primary-dark-color);
            border-color: var(--primary-dark-color);
            font-size: 12px;

            @include desktop {
                height: 60px;
            }
        }
    }
}
