/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.TutosPage {
    overflow: hidden;

    &-Content {
        @include desktop {
            padding-block-end: 60px;
        }
    }

    &-WrapperItems {
        // ...
    }

    &-Items {
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 20px;

        @include desktop {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &-Item {
        &,
        &:last-child {
            margin-block-end: 0;
        }

        &::before {
            display: none;
        }
    }

    &-FeaturedItems {
        margin-block-end: 30px;
        padding-block: 30px;

        &::before {
            @include full-page-cover(true);

            z-index: -1;
            background-color: var(--secondary-light-color);
        }

        &Title {
            margin-block: 0 1.3em;
            text-align: center;
            text-transform: uppercase;
        }

        @include desktop {
            padding-block-start: 40px;

            .TutosPage-Item {
                &:first-child:last-child {
                    grid-column-start: 2;
                }
            }
        }
    }

    &-LoadMore {
        padding-block: 30px;
        text-align: center;
    }

    &-PushOptin {
        > * {
            @include full-page-width();
        }
    }

    &-Filters {
        margin-block: 20px;

        @include desktop {
            margin-block: 30px;
        }
    }

    // Below from CategoryFilterOverlay

    &-FilterBtn {
        @include filter-button();
    }

    &-Attributes {
        @include desktop {
            --selected-attributes-border-color: var(--color-black);
            --selected-attributes-border-radius: 21px;
            --selected-attributes-background-color: var(--color-white);

            display: flex;
            flex-wrap: wrap;
            margin-right: 25px;

            .ExpandableContent {
                margin-right: 15px;
                margin-bottom: 15px;
                border: none;

                &-Heading {
                    cursor: pointer;
                    font-size: 12px;
                    letter-spacing: 1.2px;
                    font-weight: bold;
                }

                &-Button {
                    padding: 8px 20px;
                    border: 1px solid var(--selected-attributes-border-color);
                    border-radius: var(--selected-attributes-border-radius);
                    z-index: 10;

                    &_isContentExpanded {
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                        border-bottom-color: var(--selected-attributes-background-color);
                    }
                }

                &-Content {
                    display: none;
                    margin: -1px 0 0;
                    overflow: hidden;
                    position: absolute;
                    left: 0;
                    transition: height .1s ease-out;
                    width: 320px;
                    z-index: 9;
                    padding: 20px 20px 15px;
                    background-color: var(--selected-attributes-background-color);
                    border: 1px solid var(--selected-attributes-border-color);
                    border-radius: var(--selected-attributes-border-radius);
                    border-top-left-radius: 0;

                    &_isContentExpanded {
                        display: block;
                    }
                }

                &:first-of-type {
                    border-top: none;
                }
            }
        }
    }

    &FilterPopup {
        .Popup-Main {
            display: flex;
            flex-direction: column;
            padding-block-end: 0;

            > *:last-child {
                margin-block-start: auto;
            }
        }
    }

    &-PopupAttributes {
        @include desktop {
            .ExpandableContent {
                &-Button {
                    padding-block: 15px;
                }

                &-Content {
                    max-height: 0;
                    overflow: hidden;
                    opacity: 0;
                    margin-top: 0;

                    &_isContentExpanded {
                        opacity: 1;
                        max-height: 100%;
                    }
                }
            }
        }
    }

    &-PopupActions {
        padding: 15px;
        border-block-start: 1px solid var(--primary-divider-color);

        @include desktop {
            padding: 30px 0;
        }
    }

    &-PopupSubmitButton {
        width: 100%;
    }

    .PageCard-Title {
        font-weight: 300;
    }

    .PageCard-Link {
        --link-color: var(--imported_link_color, var(--primary-dark-color));
        --link-hover: var(--imported_linkHover_color, var(--default-secondary-base-color));
    }
}
