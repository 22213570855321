/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.NewsletterSubscription {
    --border-radius: 30px;
    --input-height: 40px;

    .FieldForm {
        &-Body {
            display: flex;
        }

        &-Fields {
            flex-grow: 1;
        }

        &-Fieldset {
            .Field {
                &_type {
                    &_email {
                        input {
                            padding: 0 20px;
                            border-radius: var(--border-radius) 0 0 var(--border-radius);
                            border-right-width: 0;
                            font-size: 12px;

                            &::placeholder {
                                color: var(--input-color);
                                opacity: .7;
                            }
                        }
                    }

                    &_checkbox {
                        margin-block-start: 1em;
                        padding-inline-start: 8px;
                        text-align: start;

                        input {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }

    .Button {
        --button-height: var(--input-height);
        --button-background: var(--secondary-base-color);
        --button-border: var(--secondary-base-color);
        --button-border-radius: 0 var(--border-radius) var(--border-radius) 0;
        --button-font-size: 10px;
        --button-hover-height: var(--button-height);

        flex-shrink: 0;
        width: auto;
        margin-top: 0;

        &:not([disabled]):hover {
            border-radius: var(--button-border-radius);
        }
    }
}
