/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.HeartIcon {
    transform-origin: center;
    will-change: transform;

    &-Fill {
        fill: var(--color-black);
        transition: transform .3s linear;
        transform: translateY(110%);
        will-change: transform;
    }

    &:hover {
        fill: transparent;

        .HeartIcon-Fill {
            fill: var(--primary-base-color);
        }
    }

    &_isLoading {
        animation-name: heartFadeInOut;
        animation-iteration-count: infinite;
        animation-duration: 1.2s;
    }

    &_isActive {
        // fill: var(--color-black);
        fill: transparent;
        stroke: var(--color-black);
        animation-name: heartFadeOut;
        animation-iteration-count: 1;
        animation-duration: .3s;
        animation-delay: .3s;
        animation-timing-function: cubic-bezier(0.76, 0, 0.24, 1);

        .HeartIcon-Fill {
            transform: translateY(0);
        }

        &:hover {
            // fill: var(--primary-base-color);
            fill: transparent;
            stroke: var(--primary-base-color);
        }
    }
}

@keyframes heartFadeInOut {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(.97);
    }

    35% {
        transform: scale(.9);
    }

    45% {
        transform: scale(1.1);
    }

    55% {
        transform: scale(.9);
    }

    65% {
        transform: scale(1.1);
    }

    75% {
        transform: scale(1.03);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes heartFadeOut {
    0% {
        transform: scale(1);
    }

    20% {
        transform: scale(1.25);
    }

    25% {
        transform: scale(1.3);
    }

    100% {
        transform: scale(1);
    }
}
