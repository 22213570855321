/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ImageZoomPopup {
    .Popup-Header,
    .Popup-Main {
        padding: 0;
    }

    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .swiper-button-prev,
    .swiper-button-next {
        width: 64px;
        height: 64px;
        margin-top: 0;
        transform: translateY(-50%);

        &::after {
            font-size: 18px;
        }
    }

    .swiper-button-prev {
        left: 20px;
    }

    .swiper-button-next {
        right: 20px;
    }

    .ProductGallery-SliderImageButton {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        height: 100%;
    }

    .ProductGallery {
        .Image {
            flex-grow: 0;
            flex-shrink: 0;
            display: block;
            width: auto;
            max-width: 100%;
            // height: auto;
            max-height: 100%;
            margin: 0 auto;
            padding: 0;
            aspect-ratio: var(--aspect-ratio-product-image);

            &-Image {
                position: relative;
                min-width: 100px; // bug lazyload
            }
        }

        .VideoThumbnail {
            .Image {
                min-width: 320px;
            }
        }
    }

    @include mobile {
        .Popup-Content {
            border-radius: 0;
        }

        .ProductGallery {
            margin-block-end: 0;
        }

        .swiper-button-prev,
        .swiper-button-next {
            display: none;
        }
    }
}
