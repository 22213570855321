/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --restricted-content-overlay-size: 60px;
    --restricted-content-overlay-padding-top: 50%;
}

.RestrictedContentOverlay {
    @include full-cover();

    display: flex;
    justify-content: center;
    gap: 10px;
    background-color: rgba(255,255,255, .7);
    padding-block-start: calc(var(--restricted-content-overlay-padding-top) - var(--restricted-content-overlay-size) / 2);

    &-Content {
        display: flex;
        flex-direction: column;
        gap: 4px;
        justify-content: center;
        align-items: center;
        width: var(--restricted-content-overlay-size);
        height: var(--restricted-content-overlay-size);
        border-radius: 50%;
        background-color: var(--primary-dark-color);

        &_pro {
            background-color: var(--pro-dark-color);
        }

        &_student {
            background-color: var(--student-dark-color);
        }

        &::before {
            content: "";
            display: block;
            width: 18px;
            height: 19px;
            background: url($path-img + 'lock-white.svg') no-repeat center;
            background-size: auto 100%;
        }

        span {
            font-size: 8px;
            color: var(--color-white);

            strong {
                font-weight: 700;
                color: inherit;
                text-transform: uppercase;
            }
        }
    }
}
