/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.NewIssue {
    --button-height: 50px;
    --button-padding: 20px;
    --button-hover-padding: var(--button-padding);
    --button-hover-height: var(--button-height);
    --hollow-button-height: var(--button-height);
    --hollow-button-hover-height: var(--button-height);
    --hollow-button-border: var(--default-color);
    --hollow-button-color: var(--default-color);

    text-align: center;

    &-Title {
        text-transform: uppercase;
    }

    &-Des {
        font-size: 16px;
        font-weight: 300;
    }

    .Button {
        --button-padding: 25px;
        --button-hover-padding: 25px;
        --hollow-button-padding: 25px;
        --hollow-button-hover-padding: 25px;

        width: auto;
    }

    &-ContactPhone {
        a {
            --button-font-size: 16px;

            justify-content: space-between;
            background-color: var(--secondary-base-color);
            border-color: var(--secondary-base-color);

            &::before {
                content: "";
                display: block;
                width: 27px;
                height: 31px;
                margin-inline-end: 10px;
                background: url($path-img + 'phone-white.svg') no-repeat center;
                background-size: contain;
            }
        }
    }

    &-OpeningHours {
        margin-block-start: 5px;
        font-weight: 600;
        font-size: 13px;
        color: #969696;
    }

    &-ContactOnlineWrapper {
        margin-block-start: 30px;
    }

    &-ContactOnline {
        --button-font-weight: 700;

        &::before {
            content: "";
            display: block;
            width: 27px;
            height: 20px;
            margin-right: 10px;
            background: url($path-img + 'mail.svg') no-repeat left;
            background-size: contain;
        }
    }
}
