:root {
    --swiper-navigation-size: 64px;
    --swiper-navigation-color: #000;
    --swiper-navigation-font-size: 18px;
    --swiper-pagination-color: #EDC1BD;
}

[dir="ltr"] .Slider-Arrow_isPrev {
    left: -20px !important;

    @include mobile {
        display: none;
    }
}

[dir="ltr"] .Slider-Arrow_isNext {
    right: -20px !important;

    @include mobile {
        display: none;
    }
}

.Slider-Arrow {
    height: var(--swiper-navigation-size) !important;
    width: var(--swiper-navigation-size) !important;
}

.Slider-Arrow_isPrev,
.Slider-Arrow_isNext {
    background-color: #fff;
    border: 1px solid var(--swiper-navigation-color);
    border-radius: 50%;

    svg {
        display: none;
    }

    &::after {
        display: block;
        content: "";
        position: absolute;
        background: url('/style/images/arrow.svg') no-repeat left center;
        width: 12px;
        height: 20px;
        background-size: 100% 100%;
        top: 50%;
        left: 50%;
        margin-left: -6px;
        margin-top: -10px;
    }

    @include mobile() {
        --swiper-navigation-size: 38px;
        --swiper-navigation-font-size: 12px;
    }
}

.Slider-Arrow_isPrev {
    &::after {
        transform: rotate(-180deg);
    }
}

.Slider-Crumbs {
    grid-gap: 3px !important;

    .Slider-Crumb {
        width: 10px;
        height: 10px;
        border: solid 1px $brown;
        background: white;
    }

    .Slider-Crumb_isActive {
        width: 10px;
        height: 10px;
        background: $brown;
    }
}

