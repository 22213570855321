/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.FieldPhoneNumber {
    .react-tel-input {
        .form-control {
            width: 100%;
            padding: var(--input-padding);
            padding-inline-start: 60px;
            border-radius: var(--input-border-radius);
            border-color: var(--input-border-color);
            font-family: $f-default;
            font-size: 14px;

            &.invalid-number {
                border-color: var(--primary-error-color);
            }
        }

        .country-list {
            li {
                margin-block-end: 0;

                &::before {
                    display: none;
                }
            }

            .search-box {
                min-width: 0;
                width: auto;
            }
        }
    }

    &.Field-Wrapper_hasFloatingLabel {
        .react-tel-input {
            .form-control {
                padding-block: 24px 8px;
            }
        }

        .Field-LabelContainer {
            inset-inline-start: 61px;
        }
    }
}
