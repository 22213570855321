/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.TrainingCard {
    height: 100%;

    &::before {
        content: none;
    }

    &-Inner {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &-FigureReview {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &-Figure {
        flex-grow: 1;
    }

    &-Picture {
        aspect-ratio: 23/28;

        .Image-Image {
            object-fit: cover;
        }
    }

    &-Content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
    }

    &-Name {
        font-weight: normal;
        font-size: 20px;
        margin-bottom: 10px;
    }

    &-ContentInner {
        padding-bottom: 20px;

        @media (min-width: 1161px) {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    &-Availabilities {
        flex-basis: 190px;
        display: flex;
        column-gap: 8px;
        align-items: center;

        svg {
            width: 24px;
            height: 31px;
        }
    }

    &-AvailabilitiesStores {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
    }

    &-AvailabilitiesLink {
        .Button_likeLink {
            font-weight: 500;
            font-size: 14px;
        }
    }

    &-Description {
        @media (min-width: 1161px) {
            flex-basis: calc(100% - 250px);
        }

        @media (max-width: 1160px) {
            margin-bottom: 30px;
        }

        p {
            margin-bottom: 0;
        }
    }

    .StoreLocatorIcon {
        &:hover {
            cursor: inherit;
            fill: inherit;
        }
    }
}
