/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --content-top-banner-color: var(--color-white);
    --content-top-banner-bgcolor: var(--color-black);
    /* stylelint-disable-next-line length-zero-no-unit */
    --content-top-banner-height: 0px;
}

.ContentTopPromoBanner {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 45px;
    padding: 1em var(--content-wrapper-padding-mobile);
    background-color: var(--content-top-banner-bgcolor);
    z-index: 99;

    p, a {
        font-size: 12px;
        font-weight: 500;
        color: var(--content-top-banner-color);
        //text-transform: uppercase;
        text-align: center;
    }

    p {
        margin-block-end: 0;
    }

    a {
        text-decoration: none;
    }

    @include desktop {
        padding-inline: var(--content-wrapper-padding);

        p, a {
            font-size: 14px;
        }
    }
}
