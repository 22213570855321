:root {
    --swiper-navigation-size: 64px;
    --swiper-navigation-color: #000;
    --swiper-navigation-font-size: 18px;
    --swiper-pagination-color: var(--secondary-base-color);
    --swiper-pagination-bullet-width: 10px;
    --swiper-pagination-bullet-height: 10px;
    --swiper-pagination-bullet-inactive-color: #ffffff;
    --swiper-pagination-bullet-inactive-opacity: 1;
}

/////////////////////////////////////////
// PAGINATION
/////////////////////////////////////////

.swiper-pagination-bullet {
    border: solid 1px var(--swiper-pagination-color);

    &.swiper-pagination-bullet_video {
        border-radius: 0;
        border: none;
        background: transparent url($path-img + 'play-gallery.svg') no-repeat center;
        background-size: auto 100%;

        &.swiper-pagination-bullet-active {
            background-image: url($path-img + 'play-gallery-active.svg');
        }
    }
}

/////////////////////////////////////////
// SCROLLBAR
/////////////////////////////////////////

.swiper-scrollbar {
    background: rgba(237, 193, 189, 0.7) !important;
}

/////////////////////////////////////////
// NAVIGATION
/////////////////////////////////////////

.swiper-button-prev,
.swiper-button-next,
[class^="swiper-button-prev"],
[class^="swiper-button-next"] {
    width: var(--swiper-navigation-size);
    background-color: #fff;
    border: 1px solid var(--swiper-navigation-color);
    border-radius: 50%;

    &::after {
        font-size: var(--swiper-navigation-font-size);
        font-weight: 800;
    }

    @include mobile() {
        --swiper-navigation-size: 38px;
        --swiper-navigation-font-size: 12px;
    }
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next,
[class^="swiper-button-prev"],
.swiper-rtl [class^="swiper-button-next"] {
    left: calc(0px - (var(--swiper-navigation-size) / 2));

    &::after {
        content: 'prev';
    }

    @include mobile() {
        margin-left: 6px;
    }
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev,
[class^="swiper-button-next"],
.swiper-rtl [class^="swiper-button-prev"] {
    right: calc(0px - (var(--swiper-navigation-size) / 2));

    &::after {
        content: 'next';
    }

    @include mobile() {
        margin-right: 6px;
    }
}

.swiper-button-prev ~ .swiper-button-prev,
[class^="swiper-button-prev"] ~ [class^="swiper-button-prev"],
.swiper-button-next ~ .swiper-button-next,
[class^="swiper-button-next"] ~ [class^="swiper-button-next"] {
    display: none;
}

[class^="swiper-button-prev"],
[class^="swiper-button-next"] {
    .swiper-button-disabled {
        opacity: .35;
        cursor: auto;
        pointer-events: none
    }

    .swiper-button-hidden {
        opacity: 0;
        cursor: auto;
        pointer-events: none
    }
}

.swiper-navigation-disabled {
    [class^="swiper-button-prev"],
    [class^="swiper-button-next"] {
        display: none!important
    }
}

@mixin swiper-button($direction: prev) {
    position: absolute;
    top: 50%;
    width: var(--swiper-navigation-size);
    height: var(--swiper-navigation-size);
    margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid var(--swiper-navigation-color);
    border-radius: 50%;
    color: var(--swiper-navigation-color,var(--swiper-theme-color));

    &::after {
        font-family: swiper-icons, sans-serif;
        text-transform: none!important;
        letter-spacing: 0;
        font-variant: initial;
        line-height: 1;
        font-size: var(--swiper-navigation-font-size);
        font-weight: 800;
    }

    &.swiper-button-disabled {
        opacity: .35;
        cursor: auto;
        pointer-events: none
    }

    &.swiper-button-hidden {
        opacity: 0;
        cursor: auto;
        pointer-events: none
    }

    &.swiper-button-lock {
        display: none;
    }

    @include mobile() {
        --swiper-navigation-size: 38px;
        --swiper-navigation-font-size: 12px;
    }

    /*@if $direction == prev {
        right: auto;
        left: calc(0px - (var(--swiper-navigation-size) / 2));
    }

    @else {
        left: auto;
        right: calc(0px - (var(--swiper-navigation-size) / 2));
    }*/
}
