/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --imported_checkboxes_border_radius: 5px;
    --imported_checkboxes_width: 20px;
    --imported_checkboxes_height: 20px;
}

.Field {
    &-CheckboxLabel, &-RadioLabel {
        &:not([class*='_isDisabled']):hover {
            @include desktop {
                color: var(--default-color);
            }
        }

        .input-control {
            @include mobile {
                min-width: 0;
                min-height: 0;
            }
        }
    }

    &_type {
        &_checkbox,
        &_radio {
            display: inline-flex;

            span {
                font-size: 1.3rem;
            }

            label {
                &:first-of-type {
                    display: block;
                    padding-block-start: 2px;
                }
            }

            input {
                width: var(--imported_checkboxes_width);
            }

            .input-control {
                flex: 0 0 auto;
            }

            &.Field_hasError {
                [type='checkbox'], [type='radio'] {
                    & + .input-control {
                        border-color: var(--primary-error-color);
                    }
                }
            }
        }

        &_password {
            input {
                padding-right: 50px;
            }

            .password-reveal {
                --button-height: 15px;
                --button-border-radius: 0;
                --button-padding: 0;
                --button-background: transparent;
                --button-border-width: 0;
                --button-hover-height: var(--button-height);
                --button-hover-border-radius: var(--button-border-radius);
                --button-hover-padding: var(--button-padding);
                --button-hover-background: var(--button-background);

                @include no-txt();

                width: 23px;
                min-width: 0;
                margin: 0;
                background-image: url($path-img + 'eye-pink.svg');
                background-repeat: no-repeat;
                background-position: center;
                position: absolute;
                right: 15px;
                bottom: 18px;
                overflow: visible;

                &:focus-visible {
                    outline: 1px dotted var(--primary-dark-color);
                }
            }

            [type="text"] + .password-reveal {
                &::after {
                    content: "";
                    width: 130%;
                    border-top: 1px solid var(--primary-base-color);
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
            }
        }
    }

    [type='checkbox'], [type='radio'] {
        + .input-control {
            width: var(--imported_checkboxes_width);
            height: var(--imported_checkboxes_height);
            border: 1px solid var(--imported_input_border_color, var(--input-border-color));

            [dir="ltr"] & {
                margin-right: 8px;

                &::after {
                    left: calc(50% - 6px);
                }
            }

            &::after {
                box-shadow: none;
                width: 12px;
                height: 12px;
                transform: none;
                will-change: background-color;
                top: calc(50% - 6px);
            }
        }

        &:checked {
            + .input-control {
                [dir="ltr"] & {
                    &::after {
                        left: calc(50% - 6px);
                        top: calc(50% - 6px);
                    }
                }
            }
        }

        &:hover {
            & + .input-control {
                @include desktop {
                    --box-color: inherit;
                }
            }

            &:not(:checked) {
                & + .input-control {
                    @include desktop {
                        &::after {
                            --checkmark-color: inherit;
                        }
                    }
                }
            }
        }
    }

    [type='checkbox'] {
        + .input-control {
            &::after {
                border-radius: 3px;
            }
        }
    }

    [type='radio'] {
        + .input-control {
            &::after {
                border-radius: 50%;
            }
        }
    }

    &-Wrapper {
        &_checkboxes {
            display: flex;
            flex-wrap: wrap;
        }

        &_type {
            &_checkbox,
            &_radio {
                .Field-ErrorMessages {
                    margin-inline-start: calc(var(--imported_checkboxes_width) + 8px);
                }
            }
        }

        &_hasFloatingLabel {
            input, textarea, select {
                padding-block: 24px 8px;
            }

            .Field {
                &-LabelContainer {
                    position: absolute;
                    inset-inline-start: calc(var(--input-padding-inline) + 1px); // add border width
                    inset-block-start: 11px;
                    z-index: 2;
                    max-width: 100%;
                    margin-block-end: 0;
                    transform-origin: left center;
                    transform: scale(.8);
                    transition: transform .2s ease;
                    pointer-events: none;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &-Label {
                    padding-block-end: 0;
                    font-weight: 600;
                    font-size: 14px;
                }
            }

            &.Field-Wrapper_isEmpty {
                .Field {
                    &-LabelContainer {
                        transform: scale(1) translateY(8px);
                    }

                    &-Label {
                        font-weight: 300;
                    }
                }
            }
        }
    }

    &-SubLabelContainer {
        margin-block-start: 5px;
        margin-inline-start: 12px;
    }

    &-SubLabel {
        font-weight: 300;
    }

    &-Error {
        &Messages {
            margin-inline-start: 12px;
        }

        &Message {
            line-height: 1.2;
        }
    }
}
