/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --content-wrapper-width: 1556px; // 1492 + 32 * 2
    --content-wrapper-padding: 32px;
    --content-wrapper-padding-mobile: 14px;
}

.ContentWrapper {
    max-width: var(--content-wrapper-width);
    padding-inline: var(--content-wrapper-padding);
    margin-inline: auto;

    @include mobile {
        padding-inline: var(--content-wrapper-padding-mobile);
    }
}
