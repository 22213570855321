/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.TrainingPage {
    --product-list-page-columns: 2;

    @media (min-width: 600px) {
        --product-list-page-columns: 3;
    }

    @include desktop {
        --product-list-page-columns: 2;
    }

    @media (min-width: 1024px) {
        --product-list-page-columns: 3;
    }

    @include very-wide-desktop {
        --product-list-page-columns: 4;
    }

    @include mobile {
        &-Wrapper {
            &.CategoryPage-Wrapper {
                padding-inline: var(--content-wrapper-padding-mobile);
            }
        }

        .CategoryDetails {
            &-Heading {
                text-align: center;
            }

            &-Description {
                padding-inline: 0;
            }

            &-ExpandableTextContent {
                border-block-end: 1px solid var(--primary-divider-color);
            }
        }
    }

    .CategoryDetails-Description {
        @include mobile {
            [data-content-type='text'] {
                text-align: center;
            }
        }

        p {
            font-size: #{fluid(14px, 20px)};
            font-weight: 500;
            line-height: 1.4;
        }

        #html-body & { // to override page-builder style
            .pagebuilder-column-group {
                margin-block: #{fluid(20px, 85px)};
            }

            .pagebuilder-column {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: #{fluid(12px, 35px)};

                img {
                    width: #{fluid(60px, 96px)};
                }

                p {
                    font-weight: 300;
                    font-size: #{fluid(12px, 16px)};

                    strong {
                        display: block;
                    }
                }
            }

            @include mobile {
                .pagebuilder-column-group {
                    margin-block-start: 0;
                    gap: #{fluid(10px, 20px)};
                    justify-content: center;
                }

                .pagebuilder-column {
                    flex-direction: row;
                    align-items: center;
                    width: 100%;
                    max-width: 380px;

                    p {
                        strong {
                            display: block;
                        }
                    }

                    [data-content-type='text'] {
                        text-align: left;
                    }
                }
            }
        }
    }

    &-Content {
        @include desktop {
            display: flex;
            flex-direction: row-reverse;
            gap: 9.7185%;
        }

        .CategoryPage-ProductListWrapper {
            @include mobile {
                padding-inline: 0;
            }
        }
    }

    &-ProductListTitle,
    &-CenterListTitle {
        margin-block-end: 2.2em;
        font-size: 18px;
        text-transform: uppercase;

        @include mobile {
            margin-block-start: 15px;
            font-size: 12px;
            letter-spacing: 1.2px;
            text-align: center;
        }
    }

    &-ProductListDescription {
        @include mobile {
            p {
                font-size: 14px;
                font-weight: 300;
                text-align: center;
                line-height: 1.4;
                margin-bottom: 20px;
            }
        }
    }

    &-CenterList {
        @include desktop {
            min-width: 360px;
        }
    }

    &-ProductList {
        flex: 1 1 auto;

        @include mobile {
            border-block-end: 1px solid var(--primary-divider-color);
        }
    }

    .TrainingCenterList {
        &-Center {
            display: flex;
            justify-content: space-between;
            gap: 20px;

            @include desktop {
                padding: 20px;
            }

            @include mobile {
                padding: 12px 14px;
            }

            &:nth-child(odd) {
                background-color: var(--secondary-light-color);
            }
        }

        &-CenterName {
            font-weight: 600;

            @include desktop {
                font-size: 14px;
            }

            @include mobile {
                font-size: 12px;
            }
        }

        &-CenterSessions {
            @include desktop {
                font-size: 14px;
            }

            @include mobile {
                font-size: 12px;
            }
        }

        .Button {
            font-weight: 500;
            font-size: 14px;
            text-decoration: underline;
        }
    }

    .TrainingCard {
        &-Name {
            font-size: #{fluid(14px, 20px)};

            @include mobile {
                max-width: 150px;
            }
        }

        &-Content {
            @include mobile {
                margin-top: 5px;
            }
        }

        &-ContentInner {
            padding-bottom: 0;
        }

        &-AvailabilitiesLink {
            .Button_likeLink {
                text-decoration: underline;

                @include mobile {
                    font-size: 12px;
                }
            }
        }

        &-AvailabilitiesStores {
            @include mobile {
                font-size: 9px;
            }
        }
    }
}
