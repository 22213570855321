/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.LocationDetails {
    line-height: 1.4;

    &-Name {
        margin-block-end: 0.2em;
        padding-inline-end: 15px;
        font-weight: 700;
        font-size: 16px;
    }

    &-StreetBlock,
    &-CityBlock {
        // font-weight: 300;
        font-size: 14px;
    }

    &-HoursBlock {
        display: inline-flex;
        flex-direction: column;
        min-width: 240px;
        margin-block-start: 20px;
        font-size: 13px;
        font-weight: 500;
    }

    &-HoursLine {
        display: flex;
        padding-block: 0.3em;

        &:not(:first-child) {
            border-block-start: 1px solid var(--primary-divider-color);
        }
    }

    &-HoursColumn {
        &:first-child {
            flex-basis: 33%;
            margin-right: 2em;
        }

        &:last-child {
            flex-basis: 66%;
            white-space: nowrap;
            min-width: 170px;
        }
    }
}
