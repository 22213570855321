/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ClientIssueCard {
    background-color: var(--secondary-light-color);
    padding: 20px;

    &-Header {
        display: flex;
        justify-content: space-between;
        column-gap: 15px;
    }

    &-Status {
        display: flex;
        align-items: center;
        font-size: 11px;
        color: #676767;

        &Bullet {
            width: 15px;
            height: 15px;
            margin-right: 5px;
            border-radius: 50%;
            background-color: #F0B583;

            &_Status_id {
                &_3 {
                    background-color: #B9D9AD;
                }
            }
        }
    }

    &-Date {
        font-weight: 500;
        font-size: 12px;
        text-align: end;
    }

    &-Content {
        padding: 20px;
    }

    &-Order {
        // font-weight: 300;
        font-size: 12px;
    }

    &-Subject {
        --h3-font-weight: 700;
        --h3-font-weight-mobile: 700;
        --h3-font-size: 16px;
        --h3-font-size-mobile: 16px;
        --h3-text-transform: none;
        --h3-text-transform-mobile: none;
        --h3-line-height: 1.5;
        --h3-line-height-mobile: 1.5;

        margin-block: 0 .5em;
    }

    &-Body {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: var(--paragraph-font-size);
    }

    &-LinkWrapper {
        display: flex;
        justify-content: flex-end;
    }

    &-Link {
        width: auto;
        font-weight: 500;
        text-decoration: underline;
        text-underline-offset: .15em;
    }
}
