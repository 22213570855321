/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.PushCollectInStore {
    &Homepage {
        .HomePage & {
            padding-block: #{fluid(15px, 50px)};
        }

        .PushCollectInStore {
            width: 1140px;
            max-width: 100%;
            margin-inline: auto;
        }
    }

    @include mobile {
        display: grid;

        &_isProduct {
            margin-block-start: 0;
            padding: 20px 0;
            border-top: 1px solid var(--expandable-content-divider-color);
        }
    }

    @include desktop {
        display: flex;
        align-items: center;
    }

    &::before {
        display: inline-block;
        content: "";
        background: url($path-img + 'icon-click-collect.svg') no-repeat center;
        background-size: 100% 100%;
        vertical-align: middle;

        @include mobile {
            width: 85px;
            height: 93px;
            margin-right: 22px;
            grid-column: 1;
            grid-row: 1/3;
        }

        @include desktop {
            width: 111px;
            height: 123px;
            margin-right: 30px;
        }
    }

    &-Content {
        display: inline-block;
        vertical-align: middle;

        @include mobile {
            grid-column: 2;
            grid-row: 1/2;
        }
    }

    &-Title {
        display: inline-block;
        margin: 0;
        margin-right: 5px;

        @include mobile {
            font-size: 15px;
            margin-bottom: 5px;
        }

        @include desktop {
            font-size: 20px;
        }
    }

    &-Description {
        display: inline-block;

        @include mobile {
            font-size: 14px;
        }

        @include desktop {
            font-size: 16px;
        }
    }

    .Button_likeLink {
        text-decoration: underline;
        font-weight: 500;

        @include mobile {
            font-size: 14px;
            grid-column: 2;
            grid-row: 2;
            display: block;
        }

        @include desktop {
            font-size: 16px;
            margin-left: 55px;
        }
    }

    &_isProduct {
        .PushCollectInStore-Description {
            font-size: 14px;
        }

        .Button_likeLink {
            @include mobile {
                font-size: 14px;
            }

            @include desktop {
                font-size: 14px;
            }
        }

        @include desktop {
            flex-direction: column;
            align-items: baseline;
            justify-content: center;
            min-block-size: 80px;
            margin-block-start: 35px;
            padding-block: 10px;
            padding-inline: 90px 25px;
            background-color: var(--secondary-light-color);

            &::before {
                background: url($path-img + 'icon-collect.svg') no-repeat center;
                width: 56px;
                height: 56px;
                position: absolute;
                left: 20px;
            }

            .Button_likeLink {
                margin-left: 0;
                display: block;
            }
        }

        @include mobile {
            flex-direction: column;
            align-items: baseline;
            justify-content: center;
            min-height: 120px;
            display: flex;
            padding-left: 114px;
            margin-left: -14px;
            margin-right: -14px;
            max-width: calc(100% + 28px);

            &::before {
                position: absolute;
                left: 14px;
            }
        }
    }
}
