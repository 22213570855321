/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.FieldSelect {
    &-Select {
        height: var(--input-height);
        margin-block-start: 0;
        padding-block: 14px;
    }

    &_isExpanded {
        .FieldSelect-Select {
            border-radius: var(--input-border-radius) var(--input-border-radius) 0 0;
            border-block-end-color: transparent !important;
        }
    }

    &-Option {
        font-size: 14px;
        font-weight: 400;

        &_isGroup {
            cursor: default;
            font-weight: 700;

            &:hover,
            &:focus {
                @include desktop {
                    background: var(--color-white);
                }
            }

            & ~ .FieldSelect-Option:not(.FieldSelect-Option_isGroup) {
                padding-inline-start: 40px;
            }
        }

        &_isSelected {
            background-color: var(--secondary-light-color);
            font-weight: 600;
        }
    }

    &-Options {
        &Wrapper {
            @include desktop {
                &_isExpanded {
                    overscroll-behavior: contain;
                }
            }
        }

        &_isExpanded {
            border-end-end-radius: var(--input-border-radius);
            border-end-start-radius: var(--input-border-radius);

            @include desktop {
                .Field_hasError & {
                    border-color: var(--primary-error-color);
                }
            }
        }
    }

    &#category-sort_wrapper {
        @include desktop {
            &.FieldSelect_isExpanded {
                --selected-attributes-border-radius: 21px;

                .FieldSelect-OptionsWrapper_isExpanded {
                    max-height: inherit;
                }

                .FieldSelect-Options_isExpanded {
                    border-color: var(--color-black);
                    border-bottom-left-radius: var(--selected-attributes-border-radius);
                    border-bottom-right-radius: var(--selected-attributes-border-radius);
                    padding-bottom: 20px;
                }

                .FieldSelect-Clickable {
                    .FieldSelect-SortSelect {
                        border-bottom-color: transparent;
                        border-radius: var(--selected-attributes-border-radius);
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }
            }
        }
    }
}
