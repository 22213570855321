/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.VideoPopup {
    --popup-max-width: none;
    --popup-background: rgba(0,0,0,.8);

    justify-content: center;

    .Popup {
        &-Header {
            display: block;
            padding: 0;

            @include desktop {
                min-height: 60px;
            }
        }

        &-Heading {
            color: var(--color-white);
        }

        &-CloseBtn {
            inset-block-start: -4px;
            inset-inline-end: 14px;

            .CloseIcon {
                fill: var(--color-white);
            }

            @include desktop {
                inset-block-start: -13px;
                inset-inline-end: -6px;
            }
        }

        &-Content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            background: transparent;
            padding-block: 50px;

            @include mobile {
                height: 100%;
                max-height: none;
                border-radius: 0;
                padding-block: 15px;
            }
        }

        &-Main {
            @include mobile {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            @include desktop {
                flex: 0 0 auto;
            }
        }
    }

    &-VideoPlayerWrapper {
        display: flex;
        align-items: center;

        @include mobile {
            flex: 1 1 auto;
        }
    }

    &-VideoPlayer {
        height: 0;
        padding-block-start: 56.3%; // 16x9 proportion

        @include mobile {
            max-width: calc((100vh - 90px) / .563);
        }
    }
}
