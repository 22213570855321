/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --top-page-banner-color: var(--color-white);
    --top-page-banner-bgcolor: var(--color-black);
    /* stylelint-disable-next-line length-zero-no-unit */
    --top-page-banner-height: 0px;
}

.TopPagePromoBanner {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: var(--top-page-banner-height);
    padding: 5px 25px;
    background: var(--top-page-banner-bgcolor);
    z-index: 1;
    transition: transform 200ms cubic-bezier(.47, 0, .745, .715);

    @at-root .isTopPagePromoBannerVisible {
        --top-page-banner-height: 24px;
    }

    &-Content {
        // ...
    }

    p, a {
        font-size: 9px;
        font-weight: 500;
        color: var(--top-page-banner-color);
        text-transform: uppercase;
        text-align: center;
    }

    p {
        margin-block-end: 0;
    }

    a {
        text-decoration: underline;
    }

    &-CloseButton {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
    }

    .CloseIcon {
        display: block;
        fill: var(--top-page-banner-color);
    }

    @include mobile {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 810;

        .hideOnScroll & {
            transform: translateY(-100%);
        }
    }

    @include desktop {
        @at-root .isTopPagePromoBannerVisible {
            --top-page-banner-height: 32px;
        }

        p, a {
            font-size: 12px;
        }

        &-CloseButton {
            right: 5px;
        }
    }
}
