.CmsPage {
    [data-content-type$='block'] {
        .block {
            p:last-child {
                margin-bottom: 1rem;
                margin-top: 0;
            }
        }
    }
}
