
:root {
    //// reset

    // h1 desktop
    --imported_h1_font_size: 40px;
    --imported_h1_font_weight: 300;
    --imported_h1_line_height: 1.3;
    --imported_h1_text_transform: none;

    // h1 mobile
    --imported_h1Mobile_font_size: 26px;
    --imported_h1Mobile_font_weight: 300;
    --imported_h1Mobile_line_height: 1.3;
    --imported_h1Mobile_text_transform: none;

    // h2 desktop
    --imported_h2_line_height: 1.3;
    --imported_h2_text_transform: none;

    // h2 mobile
    --imported_h2Mobile_line_height: 1.3;
    --imported_h2Mobile_text_transform: none;

    // h3 desktop
    --imported_h3_font_size: 20px;
    --imported_h3_font_weight: 800;
    --imported_h3_text_transform: uppercase;

    // h3 mobile
    --imported_h3Mobile_font_size: 18px;
    --imported_h3Mobile_font_weight: 800;
    --imported_h3Mobile_text_transform: uppercase;

    // h4 desktop
    --h4-font-size: 20px;
    --h4-font-weight: 600;

    // h4 mobile
    --h4Mobile-font-size: 15px;
    --h4Mobile-font-weight: 600;

    // paragraph
    --imported_paragraph_font_size: 15px;
    --imported_paragraph_line_height: 1.3;
    --imported_paragraph_font_weight: 300;

    // paragraph mobile
    --imported_paragraphMobile_font_size: 14px;
    --imported_paragraphMobile_line_height: 1.3;


    //// button

    --default-button-radius: 30px;
    --default-button-height: 50px;
    --button-border-width: 1px;
    --button-letter-spacing: 1px;
    --button-font-size: 13px;
    --button-font-weight: 600;


    //// input

    --imported_input_border_radius: 15px;
    --imported_input_border_color: #D3D3D3;
    // --imported_input_padding: 16px 15px;


    //// link

    --imported_link_color: var(--primary-dark-color);
    --imported_link_border_bottom: none;
    --imported_linkHover_color: var(--color-black);


    //// product image
    --ratio-product-image: 112.5%;
    --aspect-ratio-product-image: 0.8888; // 100 / 112.5


    //// CUSTOM

    --default-color: #{$black};

    --pro-base-color: #{$pro-base-color};
    --pro-light-color: #{$pro-light-color};
    --pro-dark-color: #{$pro-dark-color};

    --student-base-color: #{$student-base-color};
    --student-light-color: #{$student-light-color};
    --student-dark-color: #{$student-dark-color};

    --login-account-secondary-color: var(--secondary-light-color);
}

body {
    font-family: $f-default;
}

#root {
    // overflow: hidden;

    @include mobile {
        padding-top: var(--header-total-height);
    }
}

main {
    @include mobile {
        margin-block-start: 0;
    }
}

input,
textarea,
select {
    display: block;
    width: 100%;
}

h4 {
    margin-bottom: 1em;
    font-size: var(--h4-font-size);
    font-weight: var(--h4-font-weight);

    @include mobile() {
        font-size: var(--h4Mobile-font-size);
        font-weight: var(--h4Mobile-font-weight);
    }
}
