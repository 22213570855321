/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.NoMatch {
    display: block;
    overflow: hidden;

    &-Wrapper {
        text-align: start;
        padding-top: 30px;
    }

    &-Header {
        @include desktop {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 140px;
            padding-bottom: 60px;
            padding-top: 50px;
        }

        @include ultra-narrow-desktop {
            flex-direction: column;
            gap: 40px;
        }
    }

    &-Title {
        span {
            font-size: 0;
            background-image: url($path-img + '404/404.svg');
            background-size: 100% auto;
            background-repeat: no-repeat;
            background-position: center;
            display: block;
            margin-left: auto;
            margin-right: auto;

            @include mobile {
                width: 300px;
                height: 120px;
            }

            @include desktop {
                width: 650px;
                height: 230px;
            }
        }
    }

    &-Subtitle {
        font-weight: 300;
        line-height: initial;
        margin-bottom: 10px;

        @include mobile {
            font-size: 26px;
        }

        @include desktop {
            font-size: 60px;
        }
    }

    &-Description {
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1.2px;
        font-weight: bold;

        @include mobile {
            margin-bottom: 25px;
        }

        @include desktop {
            margin-bottom: 50px;
        }
    }

    &-Content {
        text-align: center;
        margin-top: 25px;

        .CmsBlock-Wrapper {
            @include desktop {
                width: 420px;
                margin-left: auto;
                margin-right: auto;
            }

            p {
                @include desktop {
                    font-size: 16px;
                }
            }
        }
    }

    &-OrSeparator {
        font-style: italic;

        @include desktop {
            font-size: 16px;
            margin-top: 20px;
        }
    }

    &-RelatedPages {
        padding-block: 30px 10px;

        @include desktop {
            padding-block: 80px;
        }

        &::before {
            @include full-page-cover(true);

            z-index: -1;
            background-color: var(--secondary-light-color);
        }

        &Title {
            margin-block-start: 0;
            text-align: center;

            @include mobile {
                font-size: 12px;
                text-transform: uppercase;
                letter-spacing: 1.2px;
            }

            @include desktop {
                margin-block-end: 70px;
                font-size: 30px;
                font-weight: 300;
            }
        }
    }

    &-Items {
        @include desktop {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 20px;
        }
    }

    &-Item {
        margin-block-end: 0;

        &::before {
            display: none;
        }
    }
}
