/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.LocationList {
    padding-inline: var(--content-wrapper-padding-mobile);

    &-Item {
        margin-block-end: 0;
        padding-block: 25px;
        border-block-end: 1px solid var(--primary-divider-color);

        &:last-child {
            border-block-end-width: 0;
        }

        &::before {
            display: none;
        }
    }
}
