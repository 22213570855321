/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.PushNewsHomepage {
    &-Wrapper {
        // ...
    }

    &-Title {
        @include homepageSectionTitle();
    }

    &-Items {
        &_display {
            &_grid {
                @include desktop {
                    display: flex;
                    justify-content: center;
                    gap: 20px;

                    > * {
                        flex-basis: calc(#{percentage(1 / 3)} - 13.33px);
                    }
                }
            }

            &_slider {
                @include mobile {
                    margin-inline: calc(var(--content-wrapper-padding-mobile) * -1);

                    .swiper {
                        padding-inline: var(--content-wrapper-padding-mobile);
                    }
                }
            }
        }
    }
}
