/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.SagaPage {
    overflow: hidden;

    img {
        height: auto;
        aspect-ratio: auto;
    }

    .hidden {
        visibility: hidden;
        transition: 1s;
    }

    .visible {
        visibility: visible;
        transition: 1s;
    }

    .tagSpecial {
        font-family: $f-special;
        font-size: #{fluid(40px, 60px)};
        color: var(--primary-dark-color);
    }

    .wrapper {
        position: relative;
        z-index: 20;

        @include wrap();
    }

    .explore {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 3.6px;
        transform: rotate(270deg);
        display: inline-block;
        position: absolute;
        margin-top: 500px;
        z-index: 20;
        left: -20px;

        @include mobile {
            display: none;
        }

        @media (min-width: 1660px) {
            margin-top: 650px;
            left: 0;
        }

        .ChevronIcon_direction_left {
            margin-right: 10px;
            display: inline-block;
            vertical-align: bottom;
        }
    }

    .section-saga-intro {
        background-color: var(--secondary-light-color);
        padding-block-start: #{fluid(10px, 140px)};

        @include wide-desktop {
            background-color: $white;

            &::after {
                display: block;
                content: "";
                position: absolute;
                top: -45px;
                right: 0;
                left: 0;
                height: 1254px;
                background-color: var(--secondary-light-color);
            }

            &::before {
                display: block;
                content: "";
                position: absolute;
                background: url($path-img + 'circle-white.svg') no-repeat center;
                width: 613px;
                height: 701px;
                left: -150px;
                margin-top: 100px;
                z-index: 1;
            }
        }

        h1 {
            font-size: #{fluid(40px, 110px)};
            line-height: 1.1;
            margin-block-start: 0.35em;

            @include mobile {
                br {
                    display: none;
                }
            }
        }

        .inner {
            margin-block-end: #{fluid(40px, 140px)};

            @include wide-desktop {
                padding-inline-start: 200px;
            }
        }

        .photo {
            width: 100%;
            aspect-ratio: 1706 / 860;
            overflow: hidden;

            &::after {
                content: "";
                display: block;
                background-color: var(--primary-base-color);
                position: absolute;
                inset: 0;
                transform-origin: left center;
                transform: scale(0, 1);
                transition: transform 1s ease;
            }

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform: translate(-100%, 0);
                transition: transform 1s ease 1s;
                z-index: 10;
            }

            &.is-inview {
                &::after {
                    transform: scale(1, 1);
                }

                img {
                    transform: translate(0, 0);
                }
            }

            @include mobile {
                aspect-ratio: 1;
            }
        }
    }

    .section-saga-intro-2 {
        .photos-femme {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            .photo {
                @include outOfView(0, 100px, 0);

                &-2 {
                    transition-delay: .2s;
                }

                &-3 {
                    transition-delay: .4s;
                }

                &.is-inview {
                    @include inView();
                }
            }

            @include mobile {
                flex-wrap: wrap;
                gap: 20px;
                align-items: initial;

                .photo-1 {
                    flex-basis: 100%;
                    margin-block-end: 20px;
                }

                .photo-2 {
                    flex-basis: calc(50% - 10px);
                }

                .photo-3 {
                    flex-basis: calc(50% - 10px);
                    margin-block-start: 55px;
                }
            }

            @include desktop {
                margin-inline-start: 60px;

                .photo-1 {
                    width: 480px;
                    order: 2;
                }

                .photo-2 {
                    width: 225px;
                    margin-inline-end: 25px;
                    order: 1;
                }

                .photo-3 {
                    width: 225px;
                    height: auto;
                    margin-inline-start: 145px;
                    order: 3;
                }
            }
        }

        .text {
            margin-block: #{fluid(40px, 140px)};
            text-align: center;
        }

        p {
            margin-block-end: 0;
            font-size: #{fluid(20px, 50px)};
            font-weight: 100;
            line-height: 1.3;

            .light {
                position: relative;
                display: inline-block;

                /*&,
                div {
                    text-decoration: underline;
                    text-decoration-thickness: 0.2em;
                    text-underline-offset: -0.1em;
                    text-decoration-color: #f3e5e3;
                    text-decoration-skip-ink: none;
                }*/

                &::before {
                    content: "";
                    display: inline-block;
                    width: 100%;
                    height: .2em;
                    background-color: #f3e5e3;
                    position: absolute;
                    left: 0;
                    bottom: .2em;
                    z-index: -1;
                    transform-origin: left center;
                    transform: scale(0, 1);
                    transition: transform 1.5s ease;
                    will-change: transform;
                }

                &.is-inview-delay {
                    &::before {
                        transform: scale(1, 1);
                    }
                }
            }
        }
    }

    .section-saga-year {
        width: 1430px;
        max-width: 100%;
        margin-inline: auto;
        padding: 0 20px;

        @include mobile {
            padding: 0 16px;
        }

        .item {
            margin-block-start: 12vh;

            &:nth-child(odd) {
                flex-direction: row;

                .year {
                    @include outOfView(200px);

                    @include mobile {
                        display: block;
                        text-align: end;
                    }
                }

                h2 {
                    @include outOfView(200px);
                }

                .text {
                    @include outOfView(200px);
                }

                .photo {
                    @include outOfView(-200px);
                }

                .is-inview {
                    @include inView();
                }
            }

            @media (max-width: 1023px) {
                margin-block-start: 45px;
            }

            @include mobile {
                flex-wrap: wrap;
                padding: 0;
            }

            @include desktop {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                background: url($path-img + 'circle.svg') no-repeat center;
                min-height: 650px;
            }

            &.item-1 {
                @include desktop {
                    .year {
                        margin-inline-start: 30px;
                    }
                }

                h2 {
                    svg {
                        left: -40px;
                    }
                }
            }

            &.item-2 {
                h2 {
                    @include desktop {
                        margin-inline-start: 140px;
                    }

                    svg {
                        right: -50px;
                        width: 313px;
                        height: 116px;
                        top: 10px;
                    }
                }
            }

            &.item-3 {
                h2 {
                    svg {
                        right: -50px;
                        width: 351px;
                        height: 143px;
                    }
                }
            }

            &.item-4 {
                h2 {
                    @include desktop {
                        float: right;
                        margin-inline-end: 50px;
                    }

                    svg {
                        right: -20px;
                        width: 353px;
                        height: 151px;
                    }
                }
            }

            &.item-5 {
                .year {
                    @include desktop {
                        margin-inline-start: -65px;
                    }
                }

                .text {
                    @include desktop {
                        padding-inline-start: 0;
                        max-width: 505px;
                    }
                }

                h2 {
                    svg {
                        left: -40px;
                        width: 277px;
                        height: 118px;
                        top: 10px;
                    }
                }
            }
        }

        .photo {
            flex-basis: 50%;

            @include outOfView(200px);
        }

        .content {
            flex-basis: 50%;

            @include mobile {
                max-width: 500px;
            }
        }

        .year {
            position: relative;
            z-index: 10;
            display: block;
            font-weight: 500;
            font-size: #{fluid(100px, 200px)};

            @include outOfView(-200px);

            @include desktop {
                margin-inline-start: 85px;
            }
        }

        .text {
            width: 100%;
            max-width: 602px;
            padding-block-start: 40px;
            padding-inline-start: 120px;
            clear: both;

            @include outOfView(-200px);

            @media (max-width: 1023px) {
                padding-inline-start: 0;
                padding-block-start: 0;
            }

            p {
                margin-block-end: 1.5em;
                font-size: #{fluid(14px, 20px)};
                font-weight: 300;
            }
        }

        h2 {
            position: relative;
            display: inline-block;
            margin-block-start: -60px;
            font-family: $f-special;
            font-weight: 500;
            font-size: #{fluid(40px, 100px)};
            color: var(--primary-base-color);
            white-space: nowrap;

            @include outOfView(-200px);

            svg {
                position: absolute;
                width: 306px;
                height: 140px;
                z-index: -1;
            }

            @media (max-width: 1023px) {
                svg {
                    display: none;
                }
            }

            @include mobile {
                display: block;
                margin-block-start: -30px;
                text-align: center;
            }
        }

        .is-inview {
            @include inView();
        }
    }

    .section-saga-today {
        margin-top: 12vh;

        @include mobile {
            background: transparent linear-gradient(180deg, #F0F0F2 0%, #F6F6F6 54%, #F8F8F8 100%) 0% 0% no-repeat padding-box;
        }

        .banner {
            background: #B0CCBD;
            background: url($path-img + 'saga/bg-fougere.jpg') no-repeat center;
            background-size: cover;
            width: 100%;
            display: flex;
            flex-direction: column;
            text-align: center;
            align-items: center;
            justify-content: center;
            height: 100vh;
            overflow: hidden;

            .mask-2022 {
                position: absolute;
                transform: translate3d(0px, 0px, 0px) scale(3, 3);
                opacity: 0;
            }

            @include desktop {
                height: 100vh;
            }

            @include tablet {
                margin-top: 50px;
            }

            .number {
                font-weight: 800;
                text-align: center;
                line-height: 1;
                color: #333;
                position: absolute;
                top: 300px;

                @include mobile {
                    font-size: 115px;
                }

                @include desktop {
                    font-size: 410px;
                }

                @include tablet {
                    font-size: 300px;
                }
            }

            .before {
                text-transform: uppercase;
                color: $white;
                font-size: 30px;
                letter-spacing: 30px;
                text-align: center;
                font-weight: 500;
                position: absolute;
                top: 50%;
                margin-top: -15px;
                opacity: 0;

                @include animation;

                @include mobile {
                    font-size: 16px;
                    letter-spacing: 5px;
                    flex-basis: 100%;
                    top: 60%;
                }

                span {
                    letter-spacing: 0;
                }
            }

            .title {
                font-family: $f-special;
                color: $white;
                font-size: 70px;
                text-align: center;
                line-height: 1.2;
                margin-top: 47vh;
                opacity: 0;

                @include animation;

                @include mobile {
                    font-size: 40px;
                    width: 200px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        .section-gray {
            display: flex;
            justify-content: left;

            @media (max-width: 1023px) {
                flex-wrap: wrap;
            }

            @media (min-width: 1024px) {
                background: transparent linear-gradient(180deg, #F0F0F2 0%, #F6F6F6 54%, #F8F8F8 100%) 0% 0% no-repeat padding-box;
                height: 960px;
            }

            .photo {
                width: calc(100% - 535px);
                flex-basis: calc(100% - 535px);
                visibility: hidden;

                @include outOfView(-200px);

                &.is-inview {
                    @include inView();
                }

                .inner {
                    max-height: 100%;
                    aspect-ratio: 1;
                }

                @media (max-width: 1023px) {
                    flex-basis: 100%;
                }

                @media (min-width: 1400px) {
                    width: 60%;
                    flex-basis: 60%;
                }

                img {
                    @media (min-width: 1024px) {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            .content {
                flex-basis: 535px;
                width: 535px;
                padding-right: 20px;
                position: relative;
                z-index: 10;
                margin-left: 40px;
                padding-top: 120px;

                @media (max-width: 1023px) {
                    flex-basis: 100%;
                    width: 100%;
                    margin-inline: var(--content-wrapper-padding-mobile);
                    padding-top: 35px;
                }

                p {
                    font-size: #{fluid(18px, 25px)};
                    line-height: 1.8;

                    .light {
                        text-decoration: underline;
                        text-decoration-color: transparent;
                        text-decoration-thickness: 0;
                        text-underline-offset: -0.05em;
                        text-decoration-skip-ink: none;
                        transition: text-decoration-color 1s ease;

                        &.is-inview {
                            text-decoration-color: #d6e3dd;
                            text-decoration-thickness: 0.4em;
                        }
                    }
                }

                img {
                    width: 120px;
                    margin-left: auto;
                    margin-right: auto;
                    display: block;
                    margin-bottom: 74px;

                    @include mobile {
                        width: 85px;
                        margin-bottom: 30px;
                    }

                    &#photoFeuillesNature {
                        padding-top: 2em;
                        width: 310px;
                        height: 310px;
                        
                        @include mobile {
                            width: 156px;
                            height: 156px;
                        }
                    }
                }
            }
        }

        .section-photos {
            display: flex;

            @media (max-width: 1023px) {
                flex-direction: column-reverse;
                margin-block-start: 25px;
            }

            img {
                @include outOfView(100px);

                &.is-inview {
                    @include inView();
                }
            }

            .first {
                @media (max-width: 1023px) {
                    margin-inline: var(--content-wrapper-padding-mobile);
                }

                img {
                    width: calc(100% + 95px);
                    margin-block-start: 440px;

                    @media (max-width: 1023px) {
                        width: 100%;
                        margin-block-start: 20px;
                    }
                }
            }

            .second {
                @media (min-width: 1024px) {
                    margin-block-start: -230px;
                }

                @media (max-width: 1023px) {
                    margin-inline: var(--content-wrapper-padding-mobile);
                }

                img {
                    &:first-child {
                        width: 310px;
                        height: auto;
                        z-index: 10;

                        @media (max-width: 1023px) {
                            width: 45%;
                            margin-block-start: 0;
                        }
                    }

                    &:last-child {
                        width: calc(100% - 230px);
                        margin-block-start: -80px;
                        float: right;

                        @media (max-width: 1023px) {
                            width: 70%;
                            margin-block-start: -40px;
                        }
                    }
                }
            }

            .bg-feuilles {
                position: absolute;
                visibility: visible;
                z-index: -1;
            }
        }
    }
}
