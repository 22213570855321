
%loader {
    position: relative;
    width: 60px;
    height: 60px;
    background: transparent url('/style/images/loader.svg') no-repeat center;
    background-size: 100%;
    box-sizing: border-box;
    animation: rotate 2s linear infinite;
    transform-origin: 50% 50%;

    span {
        display: none;
    }
}
