/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

@import '../../style/base/swiper';

.InstagramPosts {
    &-Title {
        @include homepageSectionTitle();

        margin-block-end: 0;
    }

    &-Account {
        font-weight: 600;
        text-align: center;
    }

    &-Header {
        margin-block-end: #{fluid(20px, 50px)};
    }

    &-Content {
        @include mobile {
            margin-inline: calc(var(--content-wrapper-padding-mobile) * -1);
        }
    }

    &-PostLink {
        display: block;
    }

    &-PostImage {
        .Image-Image {
            object-fit: cover;
        }
    }

    &-MediaTypeIcon {
        position: absolute;
        inset-block-start: 0;
        inset-inline-end: 0;
        margin: 8px;
    }

    .swiper {
        @include desktop {
            margin-inline: 65px;
        }

        @media (min-width: 1730px) {
            margin-inline: 0;
        }
    }

    .swiper-button-prev-ip,
    .swiper-button-next-ip {
        @include swiper-button(prev);

        @include mobile {
            display: none;
        }

        @include desktop {
            width: 64px;
            height: 64px;
            margin-top: 0;
            transform: translateY(-50%);

            &::after {
                font-size: 18px;
            }
        }
    }

    .swiper-button-prev-ip {
        inset-inline-start: -15px;

        @media (min-width: 1730px) {
            margin-inline-start: -90px;
        }
    }

    .swiper-button-next-ip {
        inset-inline-end: -15px;

        @media (min-width: 1730px) {
            margin-inline-end: -90px;
        }
    }
}
