@include mobile {
    .pagebuilder-mobile-hidden {
        display: none;
    }
}

@include desktop {
    .pagebuilder-mobile-only {
        display: none;
    }
}

[data-content-type='button-item'] {
    max-width: 100%;

    [data-element='link'],
    [data-element='empty_link'] {
        max-width: 100%;
        word-wrap: break-word;
    }
}

a,
button,
div {
    &.pagebuilder-button-link {
        @include button-likeLink;

        --button-font-size: var(--paragraph-font-size);

        box-shadow: none;
        box-sizing: border-box;
        background: none;
        display: inline-block;

        @include mobile {
            --button-font-size: var(--paragraph-font-size-mobile);
        }
    }

    &.pagebuilder-button-primary {
        --button-background: var(--primary-dark-color);
        --button-border: var(--primary-dark-color);
        --button-hover-background: var(--primary-base-color);
        --button-hover-border: var(--primary-base-color);

        @include button;
    }

    &.pagebuilder-button-secondary {
        --hollow-button-color: var(--default-color);
        --hollow-button-border: var(--default-color);

        @include button-hollow;
    }

    &.pagebuilder-button-primary,
    &.pagebuilder-button-secondary {
        text-decoration: none;
        box-shadow: none;
        margin-right: 10px;
        margin-bottom: 10px;

        @include mobile {
            --hollow-button-height: 40px;
            --hollow-button-hover-height: 40px;
            --button-height: 40px;
            --button-hover-height: 40px;
            --button-padding: 25px;
            --button-hover-padding: 25px;
            --button-font-size: 10px;

            min-width: 150px;
        }

        @include desktop {
            min-width: 260px;
        }
    }
}

.pagebuilder-column-group {
    @include mobile {
        flex-wrap: wrap;
        gap: 20px;
    }

    @include desktop {
        gap: 5.5%;
    }
}

.pagebuilder-column {
    box-sizing: border-box;

    @include mobile {
        background-attachment: scroll !important;
        flex-basis: 100%;
        max-width: 100%;
    }

    h1, h2, h3, h4, h5, h6 {
        &:first-child {
            margin-block-start: 0;
        }
    }

    h2 {
        font-weight: 900;
        text-transform: uppercase;

        @include desktop {
            font-size: 25px;
        }

        @include mobile {
            text-align: left;
            font-size: 18px;
            margin-top: 0;
        }
    }

    h4 {
        text-transform: uppercase;

        @include desktop {
            margin-bottom: 3rem;
        }
    }

    figure {
        img {
            width: 100%;
        }

        @include desktop {
            &.max-480 {
                img {
                    max-width: 480px !important;
                }
            }
        }
    }
}

.mobile-col {
    @include mobile {
        .pagebuilder-column-group {
            flex-direction: row;

            .pagebuilder-column {
                width: calc(50% - 10px) !important;
                flex-basis: calc(50% - 10px);

                figure[data-content-type='image'] {
                    margin-left: 0 !important;
                    margin-right: 0;
                }
            }
        }
    }
}

.pagebuilder-video-wrapper {
    max-width: 1130px;
    margin-inline: auto;
}

.pagebuilder-video-container {
    position: relative;
    padding-top: 56.25%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .video-thumbnail-link {
        @include full-cover();

        transition: opacity .3s ease-out;
        z-index: 10;
        cursor: pointer;

        &:hover {
            --video-play-icon-transform: scale(1.2);
        }
    }

    .video-thumbnail {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

[data-content-type='Landing_ProductReview'] {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    column-gap: 20px;

    .block {
        &__col {
            &-left {
                display: flex;
                flex-direction: column;
                align-items: center;

                > * {
                    width: 100%;
                    max-width: 358px;
                }
            }
        }

        &__content {
            --paragraph-font-style: italic;
            --paragraph-font-weight-mobile: 300;
            --paragraph-font-style-mobile: var(--paragraph-font-style);
            --paragraph-font-size-mobile: 20px;

            ul {
                display: flex;
                margin-block-end: 10px;

                li {
                    --paragraph-font-style: normal;
                    --paragraph-font-style-mobile: normal;

                    margin-block-end: 0;
                    margin-inline-end: 1px;
                    color: var(--primary-base-color);
                    font-size: 25px;

                    &::before {
                        display: none;
                    }
                }
            }

            .block__reviewer {
                font-weight: 600;
                font-size: 14px;
            }

            a {
                display: block;
                margin-block-start: 20px;
                text-decoration: underline;
            }
        }
    }

    .ProductListWidget-Page {
        display: block;

        .ProductCard {
            & + .ProductCard {
                display: none;
            }
        }
    }

    .ProductListPage {
        grid-template-columns: 1fr;
    }

    //@include desktop {
    @media (min-width: 600px) {
        flex-direction: row;
        align-items: center;
        width: 100%;
        max-width: 990px;
        margin-inline: auto;

        .block {
            &__col {
                flex-basis: 50%;

                &-left {
                    align-items: flex-end;
                }
            }

            &__content {
                --paragraph-font-size: 20px;
                --paragraph-font-size-mobile: 20px;

                padding-inline: 12% 5%;

                a {
                    margin-block-start: 50px;
                }
            }
        }

        .ProductList {
            padding-block-end: 0;
        }
    }

    @include wide-desktop {
        .block {
            &__content {
                --paragraph-font-size: 22px;

                padding-inline: 25% 10%;
            }
        }
    }
}

[data-content-type='Landing_Review'] {
    @include desktop {
        display: grid;
        grid-template-columns: 40% auto;
        gap: 20px;
        align-items: center;
        max-width: 1070px;
        margin-inline: auto;
        padding: 8.5rem 0;
    }

    @include ultra-narrow-desktop {
        padding-block: 4rem;
    }

    &::before {
        @include full-page-cover(true);

        background-color: $beige;

        @include mobile {
            inset-block-start: 75px;
            height: calc(100% - 75px);
        }
    }

    .description {
        --paragraph-font-size-mobile: 16px;
        --paragraph-font-style: italic;
        --paragraph-font-style-mobile: italic;
        --paragraph-line-height: 1.5;

        margin-block: 1.5rem 3rem;

        @include desktop {
            margin-block: 1.2em 0;
            font-size: clamp(16px, calc(0.8vw + 9.52px), 22px);
        }
    }

    .block {
        &__visual {
            img {
                display: block;
                width: 100%;
                max-width: 320px;

                @include mobile {
                    max-width: 200px;
                    margin-inline: auto;
                }
            }
        }

        &__title {
            margin-block-start: 2.5rem;
            font-size: 30px;
            font-weight: 700;

            @include desktop {
                margin-block-start: 0;
                font-size: clamp(30px, calc(1.33vw + 19.2px), 40px);
            }
        }

        &__subtitle {
            margin-block-start: 5px;
            font-size: 16px;
            font-weight: 500;
        }
    }

    .CategoryPage & {
        padding-inline: 30px;

        @include mobile {
            display: grid;
            column-gap: 25px;
            grid-template-columns: 33.3333% auto;
            align-items: center;
            padding: 20px;

            &::before {
                inset-block-start: 0;
                height: 100%;
            }

            .block {
                &__title {
                    margin-block-start: 0;
                    font-size: clamp(15px, calc(1.61vw + 8.97px), 22px);
                    text-transform: uppercase;
                }

                &__subtitle {
                    margin-block-start: 0;
                    font-size: clamp(11px, calc(0.69vw + 8.41px), 14px);
                }
            }

            .description {
                --paragraph-font-style-mobile: normal;

                margin-block-end: 0;
                font-size: clamp(12px, calc(0.46vw + 10.28px), 14px);
            }
        }
    }
}

/**
 * Tabs:
 * Copy with override of packages/page-builder/src/style/content-type/tabs/_default.scss
 * (in order to use it in category page description)
 */

[data-content-type='tabs'] {
    --max-num-lines: 0;

    .tabs-navigation {
        display: block;
        font-size: 0;
        margin: 0;
        padding: 0;
        list-style-type: none;
        border-block-end: 1px solid var(--primary-divider-color);

        li:first-child {
            margin-left: 0 !important;
        }

        li.tab-header {
            background: none !important;
            border: none !important;
            border-bottom: 0;
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            display: inline-block;
            margin: 0;
            max-width: 100%;
            overflow-wrap: break-word;
            position: relative;
            word-wrap: break-word;
            z-index: 1;

            @include mobile {
                width: 50%;
            }

            &::after {
                display: block;
                content: "";
                width: 1px;
                height: 20px;
                background: var(--primary-divider-color);
                position: absolute;
                inset-block-start: 50%;
                inset-inline-end: 0;
                margin-block-start: -10px;
            }

            &::before {
                display: none;
            }

            &:not(:first-child) {
                margin-left: -1px;
            }

            &:last-child {
                border-right: none !important;

                &::after {
                    display: none;
                }
            }

            a.tab-title {
                border-right: 0;
                color: var(--color-black);
                cursor: pointer !important;
                display: block;
                font-weight: 700 !important;
                position: relative;
                padding-block: 1.4rem;
                transition: all .3s;
                vertical-align: middle;
                white-space: normal;
                text-transform: uppercase;
                letter-spacing: 1.1px;

                @include mobile {
                    padding-inline: 0 30px !important;
                    font-size: 10px !important;
                }

                @include desktop {
                    padding-inline: 50px !important;
                    font-size: 11px !important;
                }

                span {
                    color: #7f7f7f;

                    &.tab-title {
                        display: block;
                    }
                }

                &:hover {
                    text-decoration: none;
                }
            }

            &.ui-tabs-active {
                a.tab-title {
                    span {
                        color: black;
                    }
                }

                &::before {
                    position: absolute;
                    bottom: -1px;
                    height: 2px;
                    background: black;
                    left: 0;
                    right: 0;
                    top: inherit;
                    margin: 0;
                }
            }

            &.ui-state-active {
                background: var(--color-white);
                z-index: 19;

                a.tab-title {
                    position: relative;
                    transition: all .3s;
                }
            }

            &.has-image {
                @include mobile {
                    padding-top: 10px;
                }

                img {
                    display: inline-block;
                    vertical-align: middle;

                    @include mobile {
                        margin-right: 10px;
                        margin-left: 10px;
                    }

                    @include desktop {
                        margin-right: 30px;
                    }
                }

                a {
                    text-decoration: none;
                    padding-top: 0;
                    color: black;
                    display: flex;
                    align-items: center;

                    span {
                        color: black;
                        text-align: left;
                    }
                }

                &::after {
                    height: calc(100% - 1.4rem);
                    inset-block: auto 0;
                    margin-block-start: 0;
                }

                &.ui-tabs-active {
                    &::before {
                        display: block;
                        content: "";
                        position: absolute;
                        bottom: -1px;
                        background: black;
                        right: 0;
                        top: inherit;
                        z-index: 1;

                        @include mobile {
                            left: 0;
                            height: 4px;
                        }

                        @include desktop {
                            left: 50px;
                            height: 2px;
                        }
                    }
                }
            }
        }
    }

    .tabs-content {
        border: none !important;
        box-sizing: border-box;
        overflow: visible;
        position: relative;
        z-index: 9;

        [data-content-type='tab-item'] {
            box-sizing: border-box;
            min-height: inherit;
            padding-block-end: 15px;

            &:not(:first-child) {
                display: none;
            }
        }
    }

    &.tab-align-left {
        .tabs-content {
            border-top-left-radius: 0 !important;
        }
    }

    &.tab-align-right {
        .tabs-content {
            border-top-right-radius: 0 !important;
        }
    }

    .ExpandableContent {
        &-Content {
            &_isContentExpanded {
                padding-block-end: 15px;

                > *:first-child {
                    margin-block-start: 0;
                }
            }
        }
    }

    [data-content-type='row'][data-appearance='contained'] & {
        width: 1240px;
        max-width: 100%;
        margin-inline: auto;
    }
}
