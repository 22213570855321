/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.PushCollectionsHomepage {
    &-Title {
        @include homepageSectionTitle();
    }

    &-Content {
        @include mobile {
            margin-inline: calc(var(--content-wrapper-padding-mobile) * -1);
        }
    }

    &-Items {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 3px;
    }

    &-Item {
        margin-block-end: 0;

        &::before {
            display: none;
        }
    }
}
