/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.PushTrainingHomepage {
    &-Image {
        display: block;
        aspect-ratio: 746 / 550;

        .Image-Image {
            object-fit: cover;
        }
    }

    &-Description {
        max-width: 1280px;
        margin-inline: auto;
        text-align: center;
        overflow: hidden;

        p {
            margin-block-end: 1.5em;
            font-weight: 300;
            font-size: #{fluid(20px, 60px)};
        }
    }

    &-Title {
        @include mobile {
            font-size: 34px;
            line-height: 1;
        }
    }

    &-Main {
        @include desktop {
            display: grid;
            grid-template-columns: 50% auto;
            column-gap: 5.7vw;
            margin-block-start: #{fluid(50px, 100px, 810px, 1560px)};
        }
    }

    &-ImageWrapper {
        @include desktop {
            grid-row-start: 1;
            grid-row-end: 3;

            &::before {
                content: "";
                display: block;
                width: 360px;
                height: 230px;
                background-image: url($path-img + 'home/poudre.jpg');
                background-size: 100% auto;
                position: absolute;
                top: -85px;
                left: -165px;
                z-index: -1;
            }
        }
    }

    &-Footer {
        .Button {
            @include buttonHollowWithArrow();
        }

        @include desktop {
            grid-column: 2;
            display: flex;
            align-items: flex-end;
        }
    }

    &-Content {
        h2 {
            @include desktop {
                font-size: 30px;
                margin-top: 0;
            }
        }
    }

    &-Items {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-block: 40px 35px;

        li {
            margin-block-end: 0;
        }
    }

    &-Item {
        border-block-end: solid 1px #E2E2E2;
    }

    .TrainingCard-Availabilities {
        @include desktop {
            justify-content: end;
        }
    }
}
