/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

$select-option-height: 36px !default; // from FieldSelect.style
$field-margin-block-start: 24px;
$field-margin-block-start-mobile: 14px;

.ContactForm {
    --primary-success-color: var(--input-border-color);

    &-Legend {
        &_isHidden {
            display: none !important;
        }

        &.type {
            > p {
                margin-block-end: 0;
                font-size: 12px;
                font-weight: 400;
                padding-block-end: 8px;
            }

            label {
                font-size: 12px;
            }

            .Field-Wrapper {
                &_radios {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 0 30px;
                }

                &,
                &:first-of-type {
                    .Field {
                        margin-block: 5px;
                    }
                }
            }
        }

        &.optin {
            .Field_type_checkbox {
                label {
                    font-size: 1.3rem;
                }
            }
        }

        @include desktop {
            &.company,
            &.personnalInfo,
            &.product {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: $field-margin-block-start;
            }

            &.product {
                .Field-Wrapper_type_file {
                    grid-column-start: span 2;
                }
            }
        }
    }

    .Field-Wrapper {
        &:first-of-type {
            .Field {
                margin-block-start: $field-margin-block-start;

                @include mobile {
                    margin-block-start: $field-margin-block-start-mobile;
                }
            }
        }
    }

    @include desktop {
        .FieldSelect {
            &-Options {
                &Wrapper {
                    &_isExpanded {
                        max-height: $select-option-height * 8;
                    }
                }
            }
        }
    }

    .Field {
        input[readonly] {
            background-color: #f5f5f5;

            &:focus {
                border-color: var(--input-border-color);
            }
        }
    }

    .Field_type_file {
        > p {
            margin-block-end: 0;
            font-size: 12px;
            font-weight: 400;
            padding-block-end: 8px;
        }

        button {
            &:hover {
                color: var(--hollow-button-hover-color);
            }
        }
    }

    .FieldFileAdvanced {
        display: flex;
        align-items: center;
        gap: 0 30px;
        margin-block-end: 15px;

        &-Overview {
            flex-basis: 140px;
            margin: 0;
            aspect-ratio: 1;

            &Empty {
                span {
                    width: 30%;
                    height: 30%;
                    background-image: url($path-img + 'picture.svg');
                }
            }

            &FileType {
                width: 30%;
                height: 30%;
            }

            &FileName {
                font-size: 11px;
                word-break: break-all;
            }
        }
    }

    &-FileButton {
        font-weight: 500;
        text-decoration: underline;
        text-underline-offset: 0.15em;
    }

    .FieldForm {
        &-Fields {
            margin-block-end: 1.5em;
        }

        &-Actions {
            text-align: center;

            [type="submit"] {
                @include desktop {
                    width: 100%;
                    max-width: 200px;
                }
            }
        }
    }
}
