/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.SearchPage {
    .CategoryDetails {
        margin-block-end: 10px;

        @include desktop {
            margin-block-end: 20px;
        }
    }

    .SearchResultsCategories {
        @include mobile {
            margin-block-end: 20px;
            padding-inline: var(--content-wrapper-padding-mobile);
        }

        @include desktop {
            &-ItemLink {
                padding: 10px 17px;
                font-size: 14px;
            }
        }
    }

    .SearchResultsTabs {
        margin-block-end: 30px;

        @include desktop {
            margin-block-end: 50px;

            &::before,
            &::after {
                display: none;
            }
        }
    }

    &-TabList {
        .Loader + .SearchPage-TabListContent {
            min-height: 300px;
        }

        @include mobile {
            padding-inline: var(--content-wrapper-padding-mobile);
        }
    }

    &-TabListContent {
        &_type {
            &_pages {
                display: grid;
                grid-template-columns: 1fr;
                grid-column-gap: 20px;
                grid-row-gap: 20px;

                @include desktop {
                    grid-template-columns: repeat(3, 1fr);
                }
            }

            &_contents {
                display: grid;
                grid-template-columns: 1fr;
                grid-row-gap: 30px;
            }
        }
    }

    &-TabListItemGroup {
        &Title {
            margin-block: 0 .8em;
            font-size: 15px;
        }

        &Content {
            display: grid;
            grid-template-columns: 1fr;
            grid-row-gap: 20px;
        }
    }

    &-TabListButton {
        margin-block-start: 50px;
        text-align: center;

        @include desktop {
            margin-block-start: 80px;
        }
    }

    &-NoMatch {
        text-align: center;
    }

    .ProductListPage {
        > .TrainingCard,
        > .ProductListContentPush {
            display: none;
        }
    }
}
