/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.PushPageHomepage {
    &-Wrapper {
        &::after {
            @include full-page-cover(true);

            height: 72vw;
            background-color: $pink2;
            opacity: .3;
            z-index: -1;
        }

        @include mobile {
            padding-block-start: #{fluid(15px, 90px)};
            padding-inline-end: 0;
        }
    }

    &-Title {
        @include homepageSectionTitle();
    }

    &-Items {
        // ...
    }

    &-Item {
        // ...
    }

    &-Footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        text-align: center;

        .Button {
            @include buttonHollowWithArrow();
        }
    }

    .PageCard {
        padding: 2em;

        &-Visual {
            &Inner {
                aspect-ratio: 61 / 65;
            }

            .Image-Image {
                @include animation;
            }
        }

        &-Title {
            font-size: 18px;

            @include desktop {
                flex-basis: calc(100% - 100px);
            }

            @include mobile {
                padding-inline-end: 65px;
                line-height: 1;
            }
        }

        &-WrapperLink {
            &:hover {
                .PageCard-VisualInner {
                    img {
                        transform: scale(1.05);
                    }
                }

                .PageCard-Link {
                    &::before {
                        transform: scale(1.05);
                    }
                }
            }
        }

        &-Link {
            span {
                --link-color: var(--imported_link_color, var(--primary-dark-color));
            }

            @media (min-width: 1161px) {
                margin-top: -78px;
                font-size: 18px;
                text-align: center;
            }

            &::before {
                @media (min-width: 1161px) {
                    content: "";
                    display: block;
                    background: url($path-img + 'play.svg') no-repeat center;
                    background-size: 100% 100%;
                    width: 93px;
                    height: 93px;
                    margin-inline: auto;
                    margin-block-end: 1.5rem;

                    @include animation;
                }
            }
        }

        &-Content {
            @media (min-width: 1161px) {
                padding: 30px 40px;
                padding-right: 30px;
                display: flex;
                justify-content: space-between;
            }
        }
    }

    .swiper-pagination {
        position: relative;
    }

    @include desktop {
        --offset-top: 0;
        --column-gap: #{fluid(64px, 64px, 810px, 1560px)};

        .HomePage & {
            padding-block: 0;
        }

        &-Wrapper {
            max-width: 1448px;
            padding: 2em 0;

            &::after {
                block-size: 72%;
                inset-block-start: var(--offset-top);
                height: 100%;
            }
        }

        &-Title {
            justify-content: center;
            margin-block-end: 0;
            padding-bottom: 1em;
        }

        &-Items {
            flex: 1;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            column-gap: var(--column-gap);
            align-items: top;
        }

        &-Item {
            flex: 1;
            // ...
        }

        .PageCard {
            max-width: 330px;
            padding: 0;
            margin: 0 auto;

            &-Content {
                padding: 30px 20px;
            }
        }

        &-Footer {
            justify-content: flex-start;
            padding-block-start: calc(var(--offset-top) * 1.2);
            inset-block-end: 0;
        }

        &-Wrapper_items {
            // ...
        }
    }
}
