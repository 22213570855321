/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --reset-attribute-color: var(--color-black, #000);

    @include desktop {
        --reset-attribute-color: #616161;
    }
}

.ResetAttributes {
    display: flex;
    flex-wrap: wrap;

    > * {
        margin-right: 8px;
    }

    &-Title {
        display: none;
    }

    &-MobileTitle {
        display: none;
    }

    &-Group {
        display: flex;
        flex-wrap: wrap;
    }

    &-AttributeValue {
        flex-direction: row-reverse;
        margin: 5px 0;
        padding: 5px 5px 5px 12px;
        border: 1px solid var(--reset-attribute-color);
        border-radius: 20px;
        color: var(--reset-attribute-color);

        &:not(:last-child) {
            margin-right: 8px;
        }
    }

    &-AttributeText {
        inset-inline-start: 0;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: .1em;
        text-transform: uppercase;
    }

    &-AttributeLabel {
        display: none;
    }

    &-CloseIcon {
        margin-left: 7px;

        .CloseIcon {
            display: block;
            fill: var(--reset-attribute-color);

            &:hover {
                fill: var(--primary-base-color);
            }
        }
    }

    @include mobile {
        margin-block: 0 10px;
    }

    @include desktop {
        margin-bottom: 0;

        &-MobileTitle {
            display: block;
            margin-block: 10px;
            margin-inline: 0 15px;
            font-size: 14px;
            font-weight: 500;
            color: var(--reset-attribute-color);
        }
    }
}
