/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.DocumentCategories {
    &-Title {
        margin-block: 0 20px;
        text-align: center;
        text-transform: uppercase;

        @include desktop {
            margin-block-end: 2em;
        }
    }

    &-Items {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 20px;

        @include desktop {
            grid-template-columns: repeat(4, 1fr);
            grid-row-gap: 50px;
        }
    }

    &-Item {
        margin-block-end: 0;

        &::before {
            display: none;
        }
    }
}
