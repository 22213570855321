/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.DocumentSearch {
    &-SearchWrapper {
        &_isOpened {
            input {
                --input-border-color-focus: var(--input-border-color);

                border-end-start-radius: 0;
                border-end-end-radius: 0;
                border-block-end-color: transparent;
            }
        }
    }

    &-SearchInput {
        margin-block-start: 0;
    }

    @include narrow-mobile {
        .Field-Wrapper {
            &_hasFloatingLabel {
                .Field-Label {
                    font-size: 12px;
                }
            }
        }
    }

    &-ClearButton {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        block-size: 37px;
        inline-size: 37px;
        background-color: var(--primary-base-color);
        border-radius: 50%;
        cursor: pointer;
        position: absolute;
        inset-block-start: 7px;
        inset-inline-end: 10px;
        z-index: 3;

        &:hover {
            background-color: var(--primary-dark-color);
        }

        svg {
            fill: var(--color-white);
        }
    }

    &-Suggested {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        padding: 15px;
        background-color: var(--color-white);
        border: 1px solid var(--input-border-color);
        border-block-start-width: 0;
        border-radius: 0 0 var(--input-border-radius) var(--input-border-radius);
        /* position: absolute;
        inset-block-start: var(--input-height);
        inset-inline-start: 0;
        z-index: 10; */

        > p {
            margin-block-end: 0;
        }
    }

    &-SuggestionsCount {
        font-weight: 700;
    }

    &-SuggestionsList {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }

    .DocumentCard {
        &-Title {
            font-size: #{fluid(14px, 18px, 375px, 810px)};
        }

        @include narrow-mobile {
            &-Actions {
                --button-font-size: 9px;
                --hollow-button-padding: 15px;
            }
        }
    }
}
