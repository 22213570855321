/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.TrainingDetails {
    &-Description {
        ul {
            li {
                &::before {
                    content: "";
                    display: inline-block;
                    width: 5px;
                    height: 5px;
                    margin-inline: 0 0.5em;
                    padding: 0;
                    border-radius: 50%;
                    background: var(--paragraph-color);
                    position: relative;
                    top: -2px;
                    left: auto;
                }
            }
        }
    }
}
