/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ExpandableText {
    &-Content {
        max-height: calc(var(--max-num-lines, 3) * 33px);
        overflow: hidden;

        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 33px;
            position: absolute;
            inset-inline-start: 0;
            inset-block-start: calc(calc(var(--max-num-lines, 3) - 1) * 33px);

            @include scrimGradient(#fff, 'to top');
        }

        /*&,
        div[data-content-type="text"] {
            display: -webkit-box;
            -webkit-line-clamp: var(--max-num-lines, initial);
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }*/

        &_isExpanded {
            max-height: none;

            &::after {
                display: none;
            }

            /*&,
            div[data-content-type="text"] {
                -webkit-line-clamp: initial;
            }*/
        }
    }

    &-Button {
        display: none;
        color: var(--link-color);
        text-decoration: underline;
        cursor: pointer;

        &_isVisible {
            display: inline;
        }
    }
}
