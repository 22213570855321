/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.FaqPage {
    &-Wrapper {
        padding-top: 3rem;

        @include desktop {
            display: grid;
            grid-template-columns: 300px auto;
            grid-column-gap: 40px;
        }

        @include wide-desktop {
            grid-template-columns: 480px auto;
            grid-column-gap: percentage(1 / 12);
        }

        > h1, > p {
            text-align: center;
        }
    }

    &-Sidebar {
        p {
            font-weight: 500;
            font-size: 20px;
        }
    }

    &-Search {
        @include desktop {
            margin-top: 5rem;
        }

        button {
            text-decoration: underline;

            &::before {
                display: inline-block;
                content: "";
                width: 16px;
                height: 16px;
                vertical-align: middle;
                background: url('/style/images/search-icon-pink.svg');
                margin-right: 10px;
            }
        }

        input {
            border-radius: 30px;
            background: url('/style/images/search-icon-grey.svg');
            background-size: 20px 20px;
            background-repeat: no-repeat;
            background-position: 25px center;
            padding-left: 60px;
            height: 40px;
        }
    }

    &-DetailsEmpty {
        text-align: center;
        font-weight: 500;
        font-size: 20px;
    }

    &-Content {
        @include desktop {
            padding-top: 15rem;
        }
    }
}
