/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --popup-background: rgba(0, 0, 0, .7);

    @include desktop {
        --popup-max-width: 490px;
        --popup-content-padding: 40px;
    }
}

.Popup {
    justify-content: flex-end;
    z-index: 900;

    @include mobile {
        justify-content: center;
        align-items: flex-end;
    }

    &_isVisible {
        @include mobile {
            inset-block-start: 0;
            height: 100%;
        }
    }

    &-Content {
        display: flex;
        flex-direction: column;
        height: 100%;
        max-height: none;
        border-radius: 0;
        padding: 0;
        overflow: visible;

        &::after {
            display: none;
        }

        @include mobile {
            height: auto;
            max-height: calc(100% - 20px);
            border-radius: 20px 20px 0 0;
        }

        @include desktop {
            max-width: var(--popup-max-width);
        }
    }

    &-Header {
        flex: 0 0 auto;
        padding-block: 35px 1px;
        padding-inline: var(--popup-content-padding);

        @include mobile {
            display: block;
        }

        @include desktop {
            padding-block-start: 50px;
        }
    }

    &-Heading {
        margin-block-end: 1.5em;
        font-weight: 700;

        @include desktop() {
            font-size: 24px;
        }
    }

    & &-CloseBtn {
        inset-block-start: 8px;
        inset-inline-end: 8px;

        @include desktop {
            inset-inline-end: 25px;

            .CloseIcon {
                height: 50px;
                width: 50px;
            }
        }
    }

    &-BackBtn {
        @include back-link();

        position: absolute;
        inset-block-start: 17px;
        inset-inline-start: 10px;

        & + .Popup-Heading {
            padding-block-start: 10px;
        }

        @include desktop {
            inset-block-start: 25px;
            inset-inline-start: 35px;
        }
    }

    &-Main {
        flex: 1 1 auto;
        padding-inline: var(--popup-content-padding);
        padding-block: 0 var(--popup-content-padding);
        overflow-x: hidden;
        overflow-y: auto;
        overscroll-behavior: contain;
    }

    .NotificationList {
        width: 100%;
        left: auto;
    }

    &.CartOverlayPopup {
        padding-right: 15px;

        .Popup-Heading {
            margin-bottom: 5px;
        }
    }
}

.scrollDisabled {
    @include desktop {
        position: relative;
        overflow: hidden;
        scrollbar-gutter: stable;
        scrollbar-width: none;
    }
}

.NotificationList {
    z-index: 99999;
}
