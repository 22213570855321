/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';


:root {
    --contained-max-width: 1240px;
    --contained-wrapper-width: calc(var(--contained-max-width) + var(--content-wrapper-padding) * 2);
}

.CmsPage {
    overflow-x: hidden;
    --paragraph-line-height-mobile: 1.6;
    --h1-font-weight: 300;
    --h4-font-size: 14px;
    --h4-font-weight: bold;

    h3 {
        @include mobile {
            font-size: 15px;
            margin-top: 0;
        }
    }

    strong {
        font-weight: 600;
    }

    ul {
        margin-inline-start: 0;
    }

    .CmsPage-Wrapper {
        margin-top: 0;
    }

    &-Heading {
        margin-block-start: 2rem;
        padding-inline: var(--content-wrapper-padding-mobile);

        @include mobile {
            display: block;
            text-align: center;
        }

        @include desktop {
            max-width: calc(1240px + (var(--content-wrapper-padding) * 2));
            margin-inline: auto;
            padding-inline: var(--content-wrapper-padding);
        }

        @include wide-desktop() {
            margin-block-start: 4vw;
            margin-block-end: 30px;
        }
    }

    [data-content-type='row'] {
        &[data-appearance='contained'] {
            max-width: var(--content-wrapper-width);
            padding-inline: var(--content-wrapper-padding);

            @include mobile {
                padding-inline: var(--content-wrapper-padding-mobile);
            }
        }

        &[data-appearance='full-width'] {
            > .row-full-width-inner {
                max-width: var(--content-wrapper-width);
                padding-inline: var(--content-wrapper-padding);

                @include mobile {
                    padding-inline: var(--content-wrapper-padding-mobile);
                }
            }
        }

        &:first-child {
            [data-element='inner'] {
                padding-top: 0;
            }

            [data-content-type='text'] {
                @include mobile {
                    text-align: center;
                }
            }
        }

        &:last-child {
            [data-element='inner'] {
                padding-bottom: 0;
            }
        }
    }

    [data-content-type='heading'],
    [data-content-type='text'],
    [data-content-type="html"],
    .pagebuilder-column-group {
        width: 100%;
        max-width: 1240px;
        margin-inline: auto;
    }

    &-ShareButton {
        margin-block: 40px 0;
        padding-inline: var(--content-wrapper-padding-mobile);
        text-align: center;

        @include desktop {
            margin-block: 4vw 0;
        }

        .CmsPage_type_tutorial &, .CmsPage_type_landing & {
            margin-block: 0 40px;

            @include desktop {
                margin-block: 0 3vw;
            }
        }
    }

    h2[data-content-type='heading'] {
        font-size: var(--h1-font-size);
        font-weight: var(--h1-font-weight);
        margin-top: 4vw;

        @include desktop {
            margin-bottom: 2vw;
        }

        @include mobile {
            font-size: 22px;
            font-weight: var(--h1-font-weight-mobile);
        }
    }

    [data-element='inner'] {
        > h2[data-content-type='heading'] {
            font-size: var(--h1-font-size);
            font-weight: var(--h1-font-weight);

            @include mobile {
                font-size: 22px;
                font-weight: var(--h1-font-weight-mobile);
            }
        }
    }

    [data-content-type='buttons'] {
        display: flex;
        flex-wrap: wrap;

        @include desktop {
            gap: 20px 30px;
            margin-top: 3vw;
        }

        @include mobile {
            gap: 10px 20px;
            margin-top: 0;
        }
    }

    [data-content-type='text'],
    [data-element='description'] {
        p, ul {
            margin-block: 1.5em 0;
        }

        *:first-child {
            margin-top: 0;
        }

        p {
            font-weight: 300;
            margin-bottom: 2rem;
        }

        p, ul, li {
            font-weight: 300;
            line-height: 1.5;

            strong {
                font-weight: 600;
            }

            a {
                font-size: 1em;
                font-weight: 500;
                text-decoration: underline;

                &, span {
                    color: var(--link-color);
                }

                &:hover {
                    &, span {
                        color: var(--link-hover);
                    }
                }
            }

            @include mobile {
                span[style^="font-size"] {
                    font-size: inherit;
                }
            }

            @include desktop {
                line-height: 1.7;
            }
        }

        ul {
            li {
                &::before {
                    margin-left: 0;
                    content: "";
                    display: inline-block;
                    margin-right: 0.5em;
                    width: 5px;
                    height: 5px;
                    padding: 0;
                    border-radius: 50%;
                    background: var(--paragraph-color);
                    position: relative;
                    top: -2px;
                    left: auto;
                }
            }
        }

        &.text-video {
            max-width: 990px;
            margin-left: auto;
            margin-right: auto;
            padding-right: 0;

            @include desktop {
                padding-left: 90px;
                margin-top: 45px;
                margin-bottom: 4vw;
            }

            @include mobile {
                margin-block: 20px;
            }

            p {
                @include desktop {
                    padding-left: 45px;
                }

                @include mobile {
                    padding-left: 20px;
                }

                &::before {
                    display: block;
                    content: "";
                    position: absolute;
                    top: 10px;
                    bottom: 10px;
                    width: 1px;
                    background: $black;
                    left: 0;
                }
            }
        }
    }

    figure[data-content-type='image'] {
        @include mobile() {
            margin-left: 0 !important;
            margin-right: 0;

            #html-body & {
                margin-top: 0;
            }
        }
    }

    a,
    button,
    div {
        &.pagebuilder-button-secondary {
            @include button-hollow;
        }
    }

    .widget {
        &.block-product-link {
            display: inline;
        }
    }

    .pagebuilder-column-group {
        @include mobile {
            margin-block: 20px;
        }

        @include desktop {
            margin-block: 4vw;
        }
    }

    .pagebuilder-column {
        &:first-child {
            @include outOfView(-200px);
        }

        &:last-child {
            @include outOfView(200px);
        }

        &.is-inview {
            @include inView();
        }
    }

    [data-content-type='products'] {
        &[data-appearance="carousel"] {
            .RenderWhenVisible {
                @media (min-width: 1024px) {
                    min-height: 52vh;
                }
            }

            .ProductCard-Name {
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }

        &[data-appearance="grid"] {
            @include mobile {
                margin-bottom: 35px;
            }

            .RenderWhenVisible {
                @include desktop {
                    min-height: 56vh;
                }

                .ProductCard {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    [data-content-type='Landing_Header'] {
        background: #DD724E;

        @media (min-width: 1140px) {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;

            .block__visual {
                width: 60%;
            }
        }

        img {
            width: 100%;
            display: block;
            margin-right: 100%;
            transition: all 2s ease;

            @media (min-width: 1140px) {
                height: 100%;
                width: auto;
                float: right;
            }
        }

        .block__visual {
            &.is-inview {
                img {
                    margin-right: 0;
                }
            }
        }

        .block__suptitle {
            font-family: $f-special;
            font-size: 30px;
            color: $white;
            visibility: visible;

            @media (min-width: 1140px) {
                font-size: 60px;
                margin-left: 100px;
            }

            @media (min-width: 1680px) {
                margin-left: 130px;
                margin-top: 100px;
            }
        }

        .block__title {
            color: $white;
            font-size: 39px;
            font-weight: bold;
            visibility: visible;

            @media (min-width: 1140px) {
                font-size: 80px;
                margin-left: -76px;
                z-index: 1;
                margin-bottom: 135px;
            }

            @include very-wide-desktop {
                font-size: 100px;
            }

            @media (min-width: 1680px) {
                font-size: 130px;
                margin-left: -94px;
            }
        }

        .block__content {
            padding: 0 20px;
            padding-top: 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .block__title,
            .block__suptitle {
                @include outOfView(200px, 0, 0, 3s);
            }

            &.is-inview {
                .block__title,
                .block__suptitle {
                    @include inView();
                }
            }
        }

        .block__subtitle {
            display: inline-block;
            color: #DD724E;
            background: $white;
            font-size: 11px;
            letter-spacing: 2.2px;
            text-transform: uppercase;
            font-weight: 500;
            padding: 13px 15px;
            margin-top: 30px;
            margin-left: -20px;

            @media (min-width: 1140px) {
                font-size: 15px;
                position: absolute;
                bottom: 0;
            }
        }
    }

    [data-content-type='Landing_TwoColumns'] {
        @include desktop {
            margin-block: 4vw;
        }

        @include mobile {
            margin-block: 18px;
        }

        .block__cols {
            @include desktop {
                display: flex;
                column-gap: 10%;
            }
        }

        .block__col-left {
            @include desktop {
                flex-basis: max-content;
            }

            @media (min-width: 1024px) {
                width: 38%;
                flex-basis: auto;
            }
        }

        .block__col-right {
            @include desktop {
                margin-top: 8.5vw;
            }
        }

        .block__visual {
            @include outOfView(0, 100px);

            @include mobile {
                margin-left: -20px;
                margin-right: -20px;
            }

            img {
                display: block;
                width: 100%;
            }
        }

        .block__content {
            .title {
                font-size: 20px;
                margin-top: 2.5rem;
                line-height: 1.5;

                @include mobile {
                    margin-bottom: 2.5rem;
                }

                @media (min-width: 1125px) {
                    font-size: 30px;
                    margin-top: 6vw;
                    margin-bottom: 0;
                }
            }
        }

        .is-inview {
            .block__visual {
                @include inView();
            }
        }

        .block__background {
            position: absolute;
            top: 0;
            z-index: -1;

            .block__visual {
                visibility: visible !important;
                animation-name: inherit !important;
            }

            @include mobile {
                display: none;
            }
        }

        &[data-image-background-position='right'] {
            .block__background {
                right: 0;
            }
        }

        &[data-image-background-position='center'] {
            .block__background {
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    [data-content-type='page_push'] {
        @include desktop {
            margin-block: 4vw;
        }

        @include mobile {
            margin-block: 2rem;
        }

        .block__main {
            @include desktop {
                display: flex;
                justify-content: center;
            }
        }

        .block__visual {
            @include outOfView(-200px);

            @include mobile {
                margin-left: -20px;
                margin-right: -20px;
            }

            img {
                display: block;
                width: 100%;
            }

            &.is-inview {
                @include inView();
            }
        }

        .block__content {
            display: flex;
            margin-top: -2.7rem;

            @include desktop {
                max-width: 425px;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                margin-top: 0;
                margin-left: -35px;
            }

            p {
                font-size: 30px;
                font-weight: bold;
                flex-basis: calc(100% - 100px);

                @include mobile {
                    padding-right: 20px;
                }

                @include desktop {
                    padding-right: 50px;
                    font-size: 60px;
                    flex-basis: inherit;
                }
            }

            a {
                text-align: center;
                text-decoration: underline;
                margin-top: - 10px;
                flex-basis: 100px;

                @include desktop {
                    margin-top: 5vw;
                    flex-basis: inherit;
                }

                &::before {
                    display: block;
                    content: "";
                    background: url($path-img + 'play.svg') no-repeat center;
                    background-size: 100% 100%;
                    width: 66px;
                    height: 62px;
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 0.5rem;

                    @include desktop {
                        display: inline-block;
                        vertical-align: middle;
                        margin-bottom: 0;
                        margin-right: 16px;
                        width: 105px;
                        height: 99px;
                    }
                }
            }

            p, a {
                @include outOfView(200px);

                &.is-inview {
                    @include inView();
                }
            }
        }

        .block__background {
            position: absolute;
            top: 0;
            left: -100px;

            @include mobile {
                display: none;
            }

            .block__visual {
                visibility: visible;
            }
        }

        &[data-image-background-position='right'] {
            .block__background {
                right: -100px;
                top: 200px;
            }
        }
    }

    [data-content-type='Landing_Review'] {
        margin-block: 2rem;

        @include desktop {
            margin-block: 4vw;

            .block {
                &__content {
                    @include outOfView(200px);

                    &.is-inview {
                        @include inView();
                    }
                }

                &__visual {
                    @include outOfView(-200px);

                    &.is-inview {
                        @include inView();
                    }
                }
            }
        }
    }

    [data-content-type='Landing_ThreeColumns'] {
        margin-block: 4vw;

        .block {
            &__main {
                @include desktop {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            &__desc {
                margin-top: 25px;

                @include desktop {
                    padding-left: 85px;
                }

                p {
                    font-size: 16px;
                    font-weight: 100;

                    @include desktop {
                        font-size: 20px;
                        line-height: 1.5;
                    }
                }

                .action-primary {
                    @include button;

                    --button-padding: 75px;
                    --button-hover-padding: var(--button-padding);
                    --button-letter-spacing: 1px;
                    --button-height: 50px;

                    background-color: transparent;
                    border-color: var(--primary-dark-color);
                    color: var(--primary-dark-color);
                    margin-top: 30px;

                    @include mobile {
                        width: auto;
                    }
                }
            }

            &__title {
                font-size: 40px;
                font-weight: bold;
                margin-top: -30px;

                @include desktop {
                    font-size: 60px;
                }
            }

            &__supertitle {
                font-weight: 500;
                font-size: 40px;
                font-family: $f-special;
                color: #E7BCB8;
                display: inline-block;

                &::after {
                    display: block;
                    content: "";
                    position: absolute;
                    background: url($path-img + 'circle-word.svg') no-repeat left center;
                    width: 146px;
                    height: 58px;
                    background-size: 100% 100%;
                    z-index: -1;
                    top: 0;
                    right: -22px;
                }
            }

            &__visual {
                @include outOfView(0, 100px);

                @include desktop {
                    flex-basis: 36%;
                }

                @include mobile {
                    margin-left: -20px;
                    margin-right: -20px;
                    margin-top: 30px;
                }

                img {
                    display: block;
                    width: 100%;
                }

                &.is-inview {
                    @include inView();
                }
            }

            &__header {
                text-align: center;

                @include desktop {
                    display: none;
                }
            }

            &__product {
                @media (max-width: 1170px) {
                    display: none;
                }

                @include desktop {
                    padding-right: 2rem;
                    width: 20%;
                }

                /* .ProductListWidget-Page {
                    @include desktop {
                        display: block;
                        width: 350px;
                    }
                } */

                .ProductListPage {
                    grid-template-columns: 1fr;
                }

                .ProductList {
                    .ProductCard + .ProductCard {
                        display: none;
                    }
                }
            }

            &__content {
                @include outOfView(200px);

                @include desktop {
                    max-width: 500px;
                }

                .block__title {
                    @include desktop {
                        max-width: 425px;
                        margin-left: -35px;
                        margin-top: 25px;
                    }
                }

                .block__supertitle {
                    @include mobile {
                        display: none;
                    }

                    @include desktop {
                        padding-left: 85px;
                    }
                }

                &.is-inview {
                    @include inView();
                }
            }
        }
    }

    [data-content-type='Landing_Wall'] {
        margin-block: 4vw;

        @include mobile {
            margin-left: -15px;
            margin-right: -15px;
        }

        @include desktop {
            display: flex;
            column-gap: 20px;
        }

        .block {
            &__item__visual {
                img {
                    display: block;
                    width: 100%;
                }
            }

            &__item {
                display: block;

                @include outOfView(0, 100px);

                &.is-inview {
                    @include inView();
                }

                @include mobile {
                    width: calc(50% - 2px);
                    float: left;
                    margin: 1px;
                }

                &::after {
                    display: block;
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    opacity: 0.6;
                    background: linear-gradient(360deg, rgba(21,38,46,0.8) 0%, rgba(255,255,255,0.1110819327731093) 100%);
                }
            }

            &__item__content {
                position: absolute;
                z-index: 10;
                bottom: 15px;
                color: $white;
                left: 15px;

                .block__item__cat {
                    font-weight: 100;
                    font-size: 14px;
                }

                .block__item__title {
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: 22px;
                }
            }

            &__col-right {
                @include desktop {
                    width: 50%;
                }

                @include desktop {
                    display: flex;
                    gap: 17px;
                    flex-wrap: wrap;

                    .block__item {
                        width: calc(50% - 12px);
                    }
                }
            }

            &__col-left {
                @include desktop {
                    width: calc(50% - 7px);
                }

                .block__item__content {
                    bottom: 30px;
                }

                .block__item {
                    @include mobile {
                        width: 100%;
                    }

                    @include desktop {
                        height: 100%;
                        background: red;
                    }
                }

                .block__item__cat {
                    font-size: 16px;
                }

                .block__item__title {
                    font-size: 25px;
                }
            }
        }
    }

    [data-content-type='Landing_ProductReview'] {
        @include desktop {
            margin-block: 4vw;

            .block {
                &__col {
                    &-left {
                        @include outOfView(-200px);
                    }

                    &-right {
                        @include outOfView(200px);
                    }

                    &.is-inview {
                        @include inView();
                    }
                }
            }
        }
    }

    .columns-category {
        @include mobile {
            margin-block: 4rem;
            margin-inline: -24px;
        }

        @include desktop {
            margin-block: 4vw;
            margin-inline: auto;
            max-width: 1070px;
        }

        .pagebuilder-column-group {
            @include desktop {
                gap: 0;
                margin-top: inherit;
                margin-bottom: inherit;
            }
        }

        figure {
            display: block;
            position: relative;
            width: 100%;
            overflow: hidden;

            @include outOfView(0, 100px);

            @include mobile {
                margin-bottom: 35px;

                &::after {
                    content: '';
                    display: block;
                    padding-bottom: 100%;
                }
            }

            @include desktop {
                margin-bottom: 25px;
            }

            img {
                width: 100%;
                display: block;

                @include mobile {
                    position: absolute;
                }
            }

            a {
                display: block;
                width: 100%;
                height: 100%;
            }

            figcaption {
                font-weight: bold;
                text-transform: uppercase;
                width: calc(100% - 10px);
                position: absolute;
                bottom: 0;
                background: $white;

                @include mobile {
                    font-size: 11px;
                    letter-spacing: 2.2px;
                    padding: 7px 10px;
                }

                @include desktop {
                    font-size: 14px;
                    letter-spacing: 2.8px;
                    width: auto;
                    padding: 16px 40px 14px 18px;
                }

                &::after {
                    display: block;
                    content: "";
                    position: absolute;
                    background: url($path-img + 'arrow.svg') no-repeat left center;
                    background-size: 100% 100%;

                    @include mobile {
                        width: 4px;
                        height: 8px;
                        top: 10px;
                        right: 10px;
                    }

                    @include desktop {
                        width: 5px;
                        height: 9px;
                        top: 21px;
                        right: 20px;
                    }
                }
            }

            &.is-inview {
                @include inView();
            }
        }

        @include desktop {
            .pagebuilder-column {
                &:first-child {
                    padding-right: 2rem;
                    margin-top: 80px;
                }

                &:last-child {
                    padding-left: 2rem;
                    margin-top: 80px;
                }
            }
        }

        @include mobile {
            .pagebuilder-column {
                flex-basis: 50% !important;
                padding: 10px;
                width: 50% !important;

                &:nth-child(2) {
                    margin-top: 45px;
                }

                &:last-child {
                    flex-basis: 100% !important;
                    flex-direction: row !important;
                    column-gap: 20px;
                    margin-top: -65px;
                    align-items: flex-start;

                    figure {
                        width: 50%;

                        &:last-child {
                            margin-top: 45px !important;
                        }
                    }
                }

                &-group {
                    justify-content: space-between;
                    gap: 0;
                }
            }
        }
    }

    .section-formation {
        margin-block: 4vw;

        @include outOfView(0, 100px);

        &.is-inview {
            @include inView();
        }

        [data-content-type='products'] {
            margin-block: 2vw;

            .TrainingCard-Content {
                margin-top: 1rem;
            }

            .TrainingCard-Name {
                font-size: 18px;
            }

            &[data-appearance="grid"] {
                .RenderWhenVisible {
                    @include desktop {
                        min-height: 60vh;
                    }
                }
            }
        }

        &::before {
            display: block;
            content: "";
            bottom: 0;
            background: $beige;
            position: absolute;

            @include mobile {
                left: -20px;
                right: -20px;
                top: 0;
            }

            @include desktop {
                top: 0;
                left: -100%;
                right: -100%;
            }
        }
    }

    .acces-rapide {
        @include desktop {
            margin-block: 4vw;
            text-align: center;
        }

        @include mobile {
            margin-block: 4rem;
        }

        p {
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 4.2px;
            font-size: 14px;

            @include desktop {
                display: inline-block;
            }
        }

        ul {
            margin-top: 0;

            @include desktop {
                display: inline-block;
            }

            li {
                list-style: none;
                margin-bottom: 0;

                @include desktop {
                    display: inline-block;
                    border-right: 1px solid #E7E5E3;
                    padding: 0 30px;

                    &:last-child {
                        border: none;
                    }
                }

                a {
                    text-decoration: underline;
                    font-size: 13px;
                }

                &::before {
                    display: none;
                }
            }
        }
    }

    div[data-content-type='tabs'] {
        @include mobile {
            .ExpandableContent {
                margin-inline: -14px;
                padding-inline: 14px;

                &:last-child {
                    margin-block-end: 4rem;
                }

                &-Content {
                    &_isContentExpanded {
                        margin-block-start: 3rem;
                    }
                }
            }
        }

        .tabs-navigation {
            @include desktop {
                margin-block: 4vw;
            }
        }

        .tabs-content {
            margin-block-start: 100px;
        }
    }

    .pagebuilder-video-wrapper {
        max-width: 1130px;
        margin-inline: auto;
    }

    .pagebuilder-video-container {
        position: relative;
        padding-top: 56.25%;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .video-thumbnail-link {
            @include full-cover();

            transition: opacity .3s ease-out;
            z-index: 10;
            cursor: pointer;

            &:hover {
                --video-play-icon-transform: scale(1.2);
            }
        }

        .video-thumbnail {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .Slider-Crumbs {
        margin-block-end: 0;
    }

    // reset animations for contents in tabs

    .tabs-content {
        .pagebuilder-column:first-child,
        .pagebuilder-column:last-child {
            @include inView();
        }

        [data-content-type='Landing_Header'] {
            .block__content {
                .block__title,
                .block__suptitle {
                    @include inView();
                }
            }
        }

        [data-content-type='Landing_TwoColumns'] {
            .block__visual {
                @include inView();
            }
        }

        [data-content-type='page_push'] {
            .block__visual {
                @include inView();
            }

            .block__content {
                p, a {
                    @include inView();
                }
            }
        }

        [data-content-type='Landing_Review'] {
            .block {
                &__content,
                &__visual {
                    @include inView();
                }
            }
        }

        [data-content-type='Landing_ThreeColumns'] {
            .block__visual,
            .block__content {
                @include inView();
            }
        }

        [data-content-type='Landing_Wall'] {
            .block___item {
                @include inView();
            }
        }

        [data-content-type='Landing_ProductReview'] {
            .block {
                &__col {
                    &-left,
                    &-right {
                        @include inView();
                    }
                }
            }
        }

        .columns-category {
            figure {
                @include inView();
            }
        }

        .section-formation {
            @include inView();
        }
    }
}

.CmsPage_type_landing {
    .CmsPage-Heading {
        display: none;
    }

    [data-content-type='text'] {
        p {
            font-size: 20px;
            font-weight: 500;
        }
    }

    .CmsPage-Content {
        @include desktop {
            margin-bottom: 3vw;
        }

        @include mobile {
            margin-bottom: 40px;
        }
    }

    .ProductListWidget.swiper:not(.swiper-scrollbar-disabled) {
        @include mobile {
            margin-bottom: 0;
        }

        @media (min-width: 810px) and (max-width: 1700px) {
            margin-left: 100px;
            margin-right: 100px;
        }
    }

    .largeur100 .ProductListWidget.swiper {
        @media (min-width: 810px) and (max-width: 1700px) {
            margin-left: 0px;
            margin-right: 0px;
        }
    }

    .ProductCard-Footer {
        margin-top: inherit;
    }

    .swiper-button-next,
    .swiper-button-prev {
        @include mobile {
            display: none;
        }

        @include desktop {
            width: 64px;
            height: 64px;
            top: 50%;

            &::after {
                font-size: 18px;
            }
        }
    }

    .swiper-button-next {
        @media (min-width: 1701px) {
            margin-right: -90px;
        }

        @include desktop {
            right: 0;
        }
    }

    .swiper-button-prev {
        @media (min-width: 1701px) {
            margin-left: -90px;
        }

        @include desktop {
            left: 0;
        }
    }
}

.CmsPage_type_cms {
    [data-element='inner'] > [data-element='main'] {
        @include outOfView(0, 100px);

        &.is-inview {
            @include inView();
        }
    }
}

.CmsPage_type_tutorial {
    @include desktop {
        margin-top: -52px;
    }

    .CmsPage-Content {
        @include desktop {
            margin-top: 8.5vw;
            margin-bottom: 3vw;
        }

        @include mobile {
            margin-bottom: 40px;
        }
    }

    .PageBuilderSlider {
        @include mobile {
            margin-left: -14px;
            margin-right: -14px;
            max-width: inherit;
        }

        /*@include desktop {
            margin-bottom: 6vw;
        }*/

        .pagebuilder-slide-wrapper {
            @include desktop {
                height: 600px !important;
            }
        }
    }

    div[data-content-type='row'] {
        &::before {
            display: block;
            content: "";
            width: 60%;
            background: $default-secondary-light-color;
            position: absolute;
            top: 0;
            bottom: 70%;
            left: -30%;
            z-index: -1;
        }
    }
}

.Tutorial {
    &-Header {
        @include mobile {
            padding-left: var(--content-wrapper-padding-mobile);
            padding-right: var(--content-wrapper-padding-mobile);
            margin-bottom: 30px;
        }

        @include desktop {
            max-width: var(--content-wrapper-width);
            padding-inline: var(--content-wrapper-padding);
            margin-inline: auto;
            display: grid;
            grid-template-columns: 59% auto;
            gap: 36px;
        }
    }

    &-Heading {
        @include mobile {
            margin-block-end: 15px;
            text-align: center;
        }

        @include desktop {
            font-size: clamp(40px, calc(2.67vw + 18.4px), 60px);
            margin-block-end: 2.5vw;
        }
    }

    &-HeadingContent {
        @include desktop {
            --font-size-base: clamp(15px, calc(0.67vw + 9.6px), 20px);
            --paragraph-font-size: var(--font-size-base);
            --paragraph-line-height: 1.5;

            padding-inline-end: 70px;
            padding-block-end: 6vw;
            margin-top: 5vw;

            &::before {
                content: "";
                display: block;
                width: 60vw;
                height: 100%;
                background-color: var(--color-white);
                position: absolute;
                right: 0;
                top: 0;
            }

            &, a {
                font-size: var(--paragraph-font-size);
                font-weight: var(--paragraph-font-weight);
                line-height: var(--paragraph-line-height);
            }
        }

        @include mobile {
            margin-top: 20px;

            p {
                text-align: center;
            }
        }
    }

    &-HeadingImageDesktop {
        .block__visual__inner {
            aspect-ratio: 88 / 53;
        }

        .Image {
            display: block;
            padding-block-end: 0;

            &-Image {
                width: 100%;
                object-fit: cover;
            }
        }

        @include mobile {
            display: none;
        }
    }

    &-HeadingImageVideo {
        .Image {
            height: auto;
            padding-bottom: percentage(870 / 790);

            &-Image {
                display: block;
                width: 100%;
                object-fit: cover;
            }
        }

        @include desktop {
            width: calc(100% + var(--content-wrapper-padding));

            .VideoThumbnail {
                &,
                &-Button {
                    height: 100%;
                }
            }
        }

        @media (min-width: 1580px) {
            width: calc(100% + 50vw - 746px);
        }
    }

    &-HeadingContentDesktop {
        position: absolute;
        bottom: -40px;
        left: -28.35%;
        width: 100%;
        max-width: 460px;
        padding: 9% 10%;
        background-color: var(--color-white);
        font-size: 16px;
        font-weight: 300;
        font-style: italic;
        line-height: 1.6;
    }

    &-Products {
        padding-block: 2.5vw;
        text-align: center;
        padding-bottom: 4vw;

        @media (min-width: 1580px) {
            padding-block: 40px;
        }

        @include desktop {
            background-color: var(--secondary-light-color);
        }

        @include mobile {
            background: transparent linear-gradient(180deg, #F0E6DE 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
        }

        [data-content-type='products'] {
            text-align: left;

            @include desktop {
                margin-block: 4vw;
            }

            @include mobile {
                margin-block: 2rem;
                margin-bottom: 4rem;
            }

            &[data-appearance="carousel"] {
                // @include narrow-mobile {
                @include mobile {
                    .ProductListPage {
                        grid-template-columns: 1fr;
                        grid-row-gap: 15px;
                    }

                    @include product-card-horizontal();
                }
            }
        }

        // @include narrow-mobile {
        @include mobile {
            .ProductListPage {
                li {
                    &:nth-child(n+2) {
                        display: none;
                    }
                }
            }

            .Tutorial-ProductsButton {
                display: none;
            }

            &_isContentExpanded {
                .ProductListPage {
                    li {
                        &:nth-child(n+2) {
                            display: flex;
                        }
                    }
                }

                .Tutorial-ProductsButton {
                    display: inline-flex;
                }
            }
        }

        &Title {
            margin-top: 1em;
            margin-bottom: 1.5em;
            font-size: var(--h1-font-size);
            font-weight: 300;
            text-align: center;

            @include mobile {
                font-size: 22px;
            }
        }

        &Content {
            max-width: var(--content-wrapper-width);
            padding-inline: var(--content-wrapper-padding);
            margin-inline: auto;

            @include mobile {
                padding-inline: var(--content-wrapper-padding-mobile);
            }
        }

        &ShowMore {
            display: none;

            // @include narrow-mobile {
            @include mobile {
                &_isVisible {
                    display: inline-block;
                    color: $pink-dark;
                    font-weight: 500;
                    font-size: 14px;

                    &::before {
                        display: inline-block;
                        content: "";
                        background-image: url($path-img + 'icon-more.svg');
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        width: 10px;
                        height: 10px;
                        margin-right: 7px;
                    }

                    span {
                        text-decoration: underline;
                        color: $pink-dark;
                        font-weight: 500;
                        font-size: 14px;
                    }
                }
            }
        }

        .Product {
            &List {
                @include desktop {
                    padding-bottom: 80px;
                }
            }
        }
    }

    &-Related {
        @include desktop {
            padding-block: 4vw;
        }

        @include mobile {
            padding-block: 40px;
        }

        .PageRelatedList {
            &-Title {
                font-weight: 300;

                @include mobile {
                    font-size: 22px;
                }
            }

            li {
                .PageCard-Link {
                    span {
                        color: $default-primary-dark-color;
                        text-decoration: underline;
                        font-weight: 500;
                    }
                }
            }

            .PageCard-Title {
                @include desktop {
                    min-height: 40px;
                    font-size: 22px;
                    line-height: 1.1;
                    margin-top: 10px;
                }

                @include mobile {
                    font-size: 18px;
                    margin-bottom: 12px;
                    margin-top: 10px;
                    line-height: 1.1;
                }
            }
        }
    }
}

div[data-content-type^='Tutorial_Step'] {
    // overflow: hidden;
    padding-bottom: 5.5vw;

    @include desktop {
        &::before {
            content: "";
            display: block;
            width: 30vw;
            background-color: var(--secondary-light-color);
            position: absolute;
            top: -30vw;
            bottom: 0;
            left: 0;
            z-index: -1;
        }
    }

    @include mobile {
        padding-inline: var(--content-wrapper-padding-mobile);
        margin-block-end: 40px;
    }

    &:last-child {
        margin-bottom: 6vw;
    }

    .block {
        &__cols {
            @include mobile {
                flex-direction: column-reverse;
            }

            @include desktop {
                max-width: var(--content-wrapper-width);
                padding-inline: var(--content-wrapper-padding);
                margin-inline: auto;
                display: grid;
                grid-template-columns: 40.88% auto;
                grid-template-rows: auto auto auto;
                column-gap: 9.7855%;

                &:not(.hasVisual) {
                    grid-template-columns: 23.99% auto;

                    > * {
                        max-width: 736px;
                    }
                }
            }
        }

        &__visual {
            grid-column: 1;
            grid-row-start: 1;
            grid-row-end: 5;

            @include mobile {
                margin-block-end: 20px;
            }

            img {
                display: block;
                width: 100%;
            }

            @include desktop {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                &__inner,
                .pagebuilder-video-container {
                    width: 100%;
                    max-width: 484px;
                }
            }
        }

        &__step {
            grid-column: 2;
            font-weight: 700;
            font-size: 13px;
            text-transform: uppercase;

            @include desktop {
                margin-block-start: 35px;
                font-size: 15px;
            }
        }

        &__title {
            grid-column: 2;
            margin-block-start: 5px;
            font-weight: 300;
            font-size: 22px;
            text-align: left;

            @include desktop {
                &:first-child {
                    margin-block-start: 35px;
                    font-size: 30px;
                }
            }
        }

        &__col-right {
            grid-column: 2;
        }

        &__footer {
            margin-top: 3vw;
            overflow: hidden;

            .ProductListWidget {
                .ProductListPage {
                    @include desktop {
                        grid-template-columns: repeat(4, 168px);
                    }

                    @include mobile {
                        @include product-card-horizontal();
                    }
                }

                .ProductCard {
                    .AddToCart {
                        --product-card-btn-addtocart-width: 46px;
                    }

                    &-Content {
                        padding-top: 11px;
                    }

                    &-Name {
                        padding-bottom: 0;
                        margin-bottom: 0;
                        font-size: 12px;

                        @include desktop {
                            max-width: 120px;
                        }
                    }

                    .ProductPrice-PriceValue {
                        @include desktop {
                            font-size: 13px;
                        }

                        @include mobile {
                            font-size: 14px;
                        }
                    }
                }

                @include desktop {
                    padding-inline-end: var(--content-wrapper-padding);

                    &.swiper {
                        overflow: visible;

                        &:not(.swiper-scrollbar-disabled) {
                            margin-block-end: 0;
                        }
                    }

                    .swiper-pagination {
                        &:not(:last-child) {
                            display: none; // bug multiple paginations
                        }
                    }

                    .swiper-pagination-bullets {
                        position: relative;
                        inset: auto;
                        margin-block-start: 30px;
                    }
                }
            }

            @include desktop {
                //left: 33.78%;
                //width: 66.22%;
                padding-inline-start: 33.78%;
            }

            @media (min-width: 1580px) {
                //left: calc((0.3378 * 1492px) + (50vw - 746px));
                //width: calc((0.6622 * 1492px) + (50vw - 746px));
                padding-inline-start: calc((0.3378 * 1492px) + (50vw - 746px));
            }

            .ProductListPage {
                @include mobile {
                    grid-template-columns: 1fr;
                    gap: 15px;

                    @include product-card-horizontal();
                }
            }
        }
    }

    div[data-element='description'] {
        --paragraph-font-size: 16px;

        grid-column: 2;
        font-size: var(--paragraph-font-size);
        font-weight: 300;

        @include mobile {
            font-size: var(--paragraph-font-size-mobile);
        }
    }

    @include desktop {
        .ProductCard {
            max-width: 168px;
        }
    }

    // ANIMATIONS

    /*.CmsPage_type_tutorial & {
        @include desktop {
            .block__visual {
                @include outOfView(-200px);
            }

            .block__step {
                @include outOfView(200px);
            }

            .block__title {
                @include outOfView(200px, 0, 0, 1s, .1s);
            }

            div[data-element='description'] {
                @include outOfView(200px, 0, 0, 1s, .2s);
            }

            &.is-inview {
                .block__visual,
                .block__step,
                .block__title,
                div[data-element='description'] {
                    @include inView();
                }
            }
        }
    }*/
}


