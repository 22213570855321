/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Field {
    &_type {
        &_numberWithControls {
            [type="number"] {
                min-width: 32px;
                font-size: 13px;
                background-color: transparent;
                pointer-events: auto;

                @include desktop {
                    min-width: 42px;
                    font-size: 14px;
                }

                ~ button {
                    --plus-minus-icon-size: 15px;

                    width: 30px;
                    height: 30px;
                    border: 1px solid var(--color-black);
                    background-color: var(--color-white);
                    cursor: pointer;

                    svg {
                        fill: var(--color-black);
                    }

                    @include mobile {
                        width: 30px;
                        height: 30px;
                    }

                    @include hoverable {
                        &:hover {
                            border-color: var(--primary-dark-color);

                            svg {
                                fill: var(--primary-dark-color);
                            }
                        }
                    }

                    &:active {
                        border-color: var(--primary-dark-color);

                        svg {
                            fill: var(--primary-dark-color);
                        }
                    }
                }
            }
        }
    }
}
