/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.LocationListItem {
    .Button {
        &_likeLink {
            font-size: 12px;
            font-weight: 500;
            text-decoration: underline;
            text-underline-offset: 0.15em;
        }

        @include mobile {
            width: auto;
        }
    }

    &-Info {
        display: flex;
        align-items: flex-end;
    }

    .LocationDetails {
        flex: 1 1 auto;
    }

    &-GoToMapButton {
        flex: 0 0 auto;
        display: inline-flex;
        align-items: center;
        margin-inline-start: 20px;
        margin-bottom: 15px;

        &::before {
            flex: 0 0 auto;
            content: "";
            display: block;
            width: 11px;
            height: 16px;
            background: url(/style/images/icon-marker-small.svg) no-repeat center;
            margin-inline-end: 8px;
        }
    }

    &-Actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
    }

    &-SelectLocationButton {
        --button-height: 40px;
        --button-hover-height: var(--button-height);
        --button-font-size: 11px;
    }
}
