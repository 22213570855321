/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

@mixin expanded-content {
    opacity: 1;
    max-height: 100%;
}

.ExpandableContent {
    &-Button {
        cursor: pointer;
    }

    &:first-child {
        border-top: none;
    }

    .Tab-ExpandableContentHeading {
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 1.1px;
    }

    &-ButtonIcon {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 500;

        .ChevronIcon {
            margin-left: 0.75em;
        }
    }

    &-ToggleIcon {
        display: none;
        flex: 0 0 auto;
        margin-inline-start: 15px;

        &_isVisible {
            display: flex;
        }
    }

    &-Heading {
        word-break: normal;
        font-weight: 600;

        @include desktop {
            cursor: pointer;
            font-size: 15px;
        }
    }

    /*&-Content {
        @include desktop {
            margin-block-start: 0;
            // Same as mobile
            max-height: 0;
            overflow: hidden;
            opacity: 0;
        }

        &_isContentExpanded {
            @include desktop {
                @include expanded-content;
            }
        }
    }*/
}
