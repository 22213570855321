.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    padding-inline-end: 40px;

    &--focused {
        outline: none;
    }

    &--open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.react-autosuggest__suggestions {
    &-container {
        display: none;

        &--open {
            display: block;
            position: absolute;
            inset-block-start: 47px;
            width: 100%;
            border: {
                style: solid;
                color: var(--input-border-color);
                width: 0 1px 1px 1px;
            }

            background-color: var(--input-background-color);
            font-weight: 300;
            font-size: 16px;
            border-bottom-left-radius: var(--input-border-radius);
            border-bottom-right-radius: var(--input-border-radius);
            z-index: 2;
        }
    }

    &-list {
        margin: 0;
        padding: 0 var(--input-padding);
        list-style-type: none;

        li {
            margin: 0;
            border-top: 1px solid var(--primary-divider-color);
            list-style-type: none;

            &::before {
                display: none;
            }
        }
    }
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 12px 0;

    &--highlighted {
        background-color: var(--secondary-light-color);
    }
}
