/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.LocationTabs {
    display: flex;
    border-block-start: 1px solid var(--primary-divider-color);
    //border-block-end: 1px solid var(--primary-divider-color);

    /*@include desktop {
        display: flex;
    }*/

    &-Wrapper {
        margin-inline: calc(-1 * var(--checkout-wrapper-padding));

        @include mobile {
            margin-inline: calc(-1 * var(--checkout-wrapper-padding-mobile));
            padding: 0;
        }

        .ExpandableContent-Heading {
            text-transform: uppercase;
        }

        .ExpandableContent {
            border-block-end: 0;
        }

        /*& > :first-child {
            border-block-start: 0;
        }*/
    }
}
