/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.SearchOverlay {
    &-Loader {
        min-height: 200px;
    }

    &-Results {
        margin-block-start: 20px;

        @include desktop {
            margin-block-start: 30px;
        }
    }

    &-TabList {
        @include product-card-horizontal();

        .ProductCard-FigureReview {
            flex-basis: 20%;
            max-width: 20%;
        }

        @include mobile {
            padding-inline: var(--content-wrapper-padding-mobile);
            padding-block-end: 20px;
        }
    }

    &-TabListContent {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 20px;

        &_type {
            &_contents {
                grid-row-gap: 30px;
            }
        }
    }

    &-TabListItemGroup {
        &Title {
            margin-block: 0 .5em;
            font-size: 15px;
        }

        &Content {
            display: grid;
            grid-template-columns: 1fr;
            grid-row-gap: 20px;
        }
    }

    &-TabListItem {
        &.PageCard {
            .PageCard {
                &-WrapperLink {
                    border-width: 0;
                }

                &-Visual {
                    flex: 0 0 30%;

                    .Image {
                        width: 100%;
                        aspect-ratio: 184 / 138;
                    }
                }

                &-Content {
                    padding-block: 0;
                }

                &-Type {
                    display: none;
                }

                &-Title {
                    font-weight: 400;
                }

                &-Link {
                    margin-block-start: 1em;
                    font-size: 12px;
                }

                @include desktop {
                    &-Title {
                        font-size: 18px;
                    }

                    &-Link {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    &-TabListButton {
        margin-block-start: 30px;
        text-align: center;

        .Button {
            --button-height: 40px;
            --button-hover-height: 40px;
            --button-padding: 30px;
            --button-hover-padding: 30px;
            --button-font-size: 10px;
            --button-border: var(--primary-dark-color);
            --button-background: var(--primary-dark-color);
            $earchoverlay-button-hover-color: darken($default-primary-dark-color, 5%);
            --button-hover-border: #{$earchoverlay-button-hover-color};
            --button-hover-background: #{$earchoverlay-button-hover-color};
        }
    }
}
