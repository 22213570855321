/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.PaytweakOrder {
    padding-block: 25px 50px;

    @include desktop {
        padding-block-start: 40px;
    }

    &-Wrapper {
        max-width: 1070px;
    }

    &-PageTitle {
        @include mobile {
            font-size: var(--h2-font-size-mobile);
            font-weight: 800;
            text-align: center;
            text-transform: uppercase;
        }
    }

    .MyAccountOrder {
        &-Header {
            @include desktop {
                flex-direction: column;
                align-items: flex-start;
                margin-block-start: 25px;
            }
        }

        &-Content {
            margin-block-start: 35px;

            @include mobile {
                border-block-end: 1px solid var(--primary-divider-color);
            }
        }
    }

    &-PaymentMessage {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 1.5em;
        border: 1px solid;
        border-radius: 10px;

        &_isPending {
            background-color: var(--secondary-light-color);
            border-color: var(--secondary-base-color);

            &::before {
                flex: 0 0 auto;
                content: "";
                display: block;
                width: 29px;
                height: 29px;
                background: url($path-img + 'info.svg') no-repeat center;
                background-size: contain;
                margin-right: 15px;
            }
        }

        &_isValidated {
            justify-content: center;
            background-color: var(--primary-light-color);
            border-color: var(--primary-base-color);
            text-align: center;
        }

        p {
            --paragraph-font-size: 14px;
            --paragraph-font-size-mobile: 13px;
            --paragraph-font-weight: 400;

            &:last-child {
                margin-block-end: 0;
            }

            strong {
                font-weight: 500;
            }
        }
    }

    &-Form {
        &Actions {
            margin-block-start: 30px;
            text-align: center;
        }
    }

    &-Checkbox {
        margin-block-start: 35px;

        label {
            padding-block-start: 0;
            font-size: 1.3rem;
            line-height: 1.5;
        }
    }
}
