/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CollectionCard {
    .Image {
        display: block;

        &::after {
            display: block;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: transparent linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%) 0% 0% no-repeat padding-box;
        }

        &-Image {
            object-fit: cover;
        }
    }

    &-Content {
        position: absolute;
        color: $white;
        z-index: 10;
        bottom: 0;
        padding: 15px;

        * {
            color: $white;
        }
    }

    &-Category {
        font-size: 11px;
        font-weight: 300;
    }

    &-Title {
        margin-top: 5px;
        margin-bottom: 0;
        font-size: 16px;
        line-height: 1.2;
    }

    &-WrapperLink {
        display: block;
    }
}
