/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.SearchField {
    &-SearchIcon {
        inset-inline-end: auto;
        inset-inline-start: 8px;

        .SearchIcon {
            @include mobile {
                fill: #6F6F6F;
            }
        }
    }

    &-ClearButton {
        display: none;
        position: absolute;
        inset-block-start: calc(50% - 7px);
        inset-inline-end: 35px;
        font-size: 11px;
        font-weight: 500;
        color: var(--link-color);
        text-decoration: underline;

        &_isVisible {
            display: inline-flex;
        }
    }

    &-Input {
        padding-inline: 40px;
    }

    @include mobile {
        margin: 0;

        &_isVisible {
            margin-top: 0;
        }

        &-Input {
            // --input-padding: 0 15px;
            --input-border-color: transparent;
            --input-border-radius: 0;
            --input-background-color: var(--secondary-light-color);

            height: 40px;
            padding-block: 0;
        }

        &-ClearButton {
            inset-inline-end: 10px;
        }
    }

    @include desktop {
        // --input-padding: 3px 10px;
        --input-border-color: transparent;
        --input-border-radius: 0;
        --input-background-color: transparent;

        width: 240px;
        margin: 0 0 0 auto;
        padding-block: 3px;

        &-CloseIcon,
        &-SearchIcon {
            inset-inline-end: 0;
        }

        &-SearchIcon {
            inset-inline-start: 0;
        }

        &-Input {
            padding-inline-start: 32px;
            border-width: 0 0 1px;
            border-color: var(--primary-divider-color);

            &:focus {
                border-width: 0 0 1px;
                border-color: var(--primary-divider-color);
            }
        }
    }
}
