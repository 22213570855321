/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.SearchPopup {
    .Popup-Header {
        display: none;
    }

    .Popup-Main {
        scrollbar-gutter: stable;

        @include desktop {
            padding-block-start: 8px;
            padding-inline: 32px;
        }
    }

    .SearchField {
        width: 100%;
        max-width: none;
    }

    .SearchOverlay-Results {
        position: relative;
        box-shadow: none;
    }

    @media (min-width: 1580px) {
        --popup-max-width: 700px;

        .Popup-Content {
            width: calc(50vw - 778px + 490px);
        }
    }
}
