/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.TrainingCenterPopup {
    .Popup {
        &-Heading {
            margin-block-end: .5em;
        }
    }

    .TrainingCenter {
        &-Address {
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            line-height: 1.4;
        }

        &-Phone {
            margin-top: 10px;
            font-weight: 700;
            font-size: 14px;
        }

        &-TrainingSectionTitle {
            margin-block-end: .5em;
            font-weight: 300;
            font-size: 22px;
        }

        &-TrainingSectionDescription {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-block-end: 35px;

            &::before {
                flex: 0 0 auto;
                content: "";
                display: block;
                width: 48px;
                height: 33px;
                background-image: url($path-img + 'training/icon-diplome.svg');
                background-size: 100% 100%;
                background-repeat: no-repeat;

                @include desktop {
                    width: 57px;
                    height: 39px;
                }
            }

            p {
                flex: 1 1 auto;
                margin-block-end: 0;
                font-weight: 500;
                font-size: 14px;
            }
        }

        &-TrainingTitle {
            margin-bottom: 5px;
            font-size: 12px;
            letter-spacing: 1px;

            @include desktop {
                font-size: 14px;
            }
        }

        &-TrainingDescription {
            --paragraph-font-size: 13px;
            --paragraph-font-size-mobile: 12px;

            margin-block-end: 20px;
            font-size: var(--paragraph-font-size);
            font-weight: var(--paragraph-font-weight);
            line-height: var(--paragraph-line-height);

            @include mobile {
                font-size: var(--paragraph-font-size-mobile);
                font-weight: var(--paragraph-font-weight-mobile);
                line-height: var(--paragraph-line-height-mobile);
            }
        }
    }
}
