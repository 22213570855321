/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CheapestShippingMethod {
    display: flex;
    align-items: center;
    column-gap: 14px;
    flex: 0 0 calc(50% - 10px);
    margin-bottom: 20px;

    @include desktop {
        flex: 0 0 calc(50% - 40px);
    }

    &::before {
        content: "";
        display: block;
        width: 31px;
        height: 31px;
        flex: 0 0 31px;
        background-image: url($path-img + 'icon-store.svg');
        background-repeat: no-repeat;
        background-size: 100% 100%;

        @include desktop {
            width: 48px;
            height: 48px;
            flex: 0 0 48px;
        }
    }

    p {
        margin-block-end: 0;
        font-size: 11px;

        @include desktop {
            font-size: 13px;
        }
    }

    &-Placeholder {
        .TextPlaceholder {
            display: block;
        }
    }
}
