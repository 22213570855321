/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ClientIssue {
    &-BackLink {
        order: -1; // move it before page title
        display: inline-flex;
        align-items: center;
        margin-block-end: 10px;
        font-weight: 500;
        font-size: 11px;
        color: var(--default-color);

        .ChevronIcon {
            margin-inline-end: 6px;
        }
    }

    &-Card {
        position: sticky;
        inset-block-start: 0;
        z-index: 2;
        padding-block-start: 15px;
        background-color: var(--color-white);
        border-block-end: 1px solid var(--primary-divider-color);

        @include mobile {
            margin-inline: -16px;
            padding-inline: 20px;
        }
    }

    &-CardHeader {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 15px;
    }

    &-CardContent {
        padding: 15px 20px;
    }

    &-Status {
        display: flex;
        align-items: center;
        font-size: 11px;
        color: #676767;

        &Bullet {
            width: 15px;
            height: 15px;
            margin-right: 5px;
            border-radius: 50%;
            background-color: #F0B583;

            &_Status_id {
                &_3 {
                    background-color: #B9D9AD;
                }
            }
        }
    }

    &-Order {
        padding-inline-start: 20px;
        font-weight: 500;
        font-size: 12px;
    }

    &-Subject {
        --h3-font-weight: 700;
        --h3-font-weight-mobile: 700;
        --h3-font-size: 16px;
        --h3-font-size-mobile: 16px;
        --h3-text-transform: none;
        --h3-text-transform-mobile: none;
        --h3-line-height: 1.5;
        --h3-line-height-mobile: 1.5;

        margin-block: 0;
    }

    &-MessageList {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding-block: 20px;

        .TextPlaceholder::after {
            display: block;
            min-height: 150px;
        }
    }

    &-Message {
        padding: 15px;
        background-color: var(--secondary-light-color);
        text-align: left;

        &_isAnswer {
            margin-inline-start: 20px;
            background-color: transparent;
            border-inline-start: 1px solid var(--primary-divider-color);
        }
    }

    &-MessageDate {
        font-weight: 500;
        font-size: 12px;
        text-align: end;
    }

    &-MessageAuthor {
        display: flex;
        align-items: center;
        column-gap: 10px;
        margin-block: 5px 10px;
        font-weight: 700;
        font-size: 12px;

        .ps-icon {
            width: 30px;
            height: 30px;
            background-color: var(--color-black);
            border-radius: 50%;
            font-size: 10px;
            font-weight: 700;
            color: var(--color-white);
            text-align: center;
            line-height: 30px;
        }
    }

    &-MessageContent {
        margin-block-end: 10px;
        font-size: 12px;
        font-weight: 400;
    }

    &-AttachmentContent {
        display: inline-block;
        margin: 1em 0;
    }

    @include desktop {
        &-MessageAuthor,
        &-MessageContent {
            font-size: 14px;
        }
    }

    &-Actions {
        margin-block-start: 15px;
        text-align: center;
    }

    &Form {
        &-Message {
            textarea {
                height: 200px;
            }
        }

        &-FileField {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin-block-start: 15px;

            .Field {
                margin-block-start: 0;

                [type='file'] {
                    height: auto;
                    opacity: 1;
                    border-color: var(--input-border-color);

                    & + label {
                        all: unset;
                        display: none;
                        flex-wrap: wrap;
                        justify-content: center;
                        align-items: baseline;
                        column-gap: 10px;
                        margin-block-start: 5px;

                        p {
                            display: none;
                        }

                        button {
                            all: unset;
                            font-size: 13px;
                            font-weight: 500;
                            text-decoration: underline;
                            text-underline-offset: .15em;
                            color: var(--primary-dark-color);
                            cursor: pointer;
                        }

                        &.FieldFile-Label_hasFile {
                            display: flex;
                        }
                    }
                }

                .FieldFile {
                    &-AllowedTypes {
                        display: none;
                    }
                }
            }
        }

        &-FileActions {
            font-weight: 500;
            font-size: 12px;
            color: var(--primary-dark-color);
            text-align: center;

            .Button {
                margin-inline-end: .3em;
                font-weight: 500;
                text-decoration: underline;
                text-underline-offset: .15em;
            }
        }

        &-Actions {
            margin-block-start: 20px;

            .Button {
                width: 100%;
            }
        }
    }
}
