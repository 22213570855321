/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CustomerService {
    --button-height: 34px;
    --button-hover-height: var(--button-height);
    --hollow-button-height: var(--button-height);
    --hollow-button-hover-height: var(--button-height);
    --hollow-button-border: var(--default-color);
    --hollow-button-color: var(--default-color);

    text-align: center;

    &-Title {
        margin-block: 0 0.5em;
        font-size: 13px;
        font-weight: 600;
        text-transform: none;
        line-height: 1.3;

        @include mobile {
            margin-block-end: 0.75em;
        }
    }

    &-Content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .Button {
        margin-block: 7px;
        margin-inline: 10px;

        &_CustomerService {
            --button-padding: 10px;
            --button-height: 28px;
            --button-hover-height: 28px;
            --button-hover-padding: 10px;
            --button-letter-spacing: 0;
            --button-font-size: 12px;

            background-color: var(--secondary-base-color);
            border-color: var(--secondary-base-color);

            &::before {
                content: "";
                display: block;
                width: 15px;
                height: 17px;
                margin-right: 5px;
                background: url($path-img + 'phone-white.svg') no-repeat center;
                background-size: contain;
            }

            @include mobile {
                --button-padding: 10px;

                margin: 0;
            }
        }

        &_Contact {
            --button-font-size: 11px;

            &::before {
                content: "";
                display: block;
                width: 20px;
                height: 16px;
                margin-right: 8px;
                background: url($path-img + 'mail.svg') no-repeat center;
                background-size: contain;
            }

            @include mobile {
                display: none;
            }
        }
    }

    @include desktop {
        flex-basis: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &-Title {
            margin-right: 10px;
            margin-bottom: 0;
        }
    }

    @include wide-desktop {
        flex-basis: auto;
        justify-content: flex-start;
    }
}
