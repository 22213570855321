/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.TrainingPopup {
    .TrainingDetails {
        &-Description {
            p {
                font-size: 14px;

                @include desktop {
                    font-size: 16px;
                }
            }
        }

        &-Duration {
            margin-block-end: 15px;
            font-weight: 700;
            font-size: 14px;

            @include desktop {
                font-size: 16px;
            }
        }

        &-Condition {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-block-end: 15px;

            &::before {
                flex: 0 0 auto;
                content: "";
                display: block;
                width: 48px;
                height: 33px;
                background-image: url($path-img + 'training/icon-diplome.svg');
                background-size: 100% 100%;
                background-repeat: no-repeat;

                @include desktop {
                    width: 57px;
                    height: 39px;
                }
            }

            p {
                flex: 1 1 auto;
                margin-block-end: 0;
                font-weight: 500;
                font-size: 14px;
            }
        }

        &-Center {
            border-top: solid 1px #e5e5e5;
            margin-bottom: 30px;

            &Title {
                display: flex;
                align-items: center;
                gap: 8px;
                font-size: 12px;
                letter-spacing: 1px;

                &::before {
                    content: "";
                    display: block;
                    width: 15px;
                    height: 20px;
                    background-image: url($path-img + 'icon-marker-border.svg');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }

                @include desktop {
                    font-size: 14px;
                }
            }
        }
    }
}
