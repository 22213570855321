/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.LocationTab {
    &-Item {
        flex: 1 1 50%;
        margin: 0;
        padding-inline-start: 0;
        background-color: var(--secondary-light-color);
        border: {
            width: 0 1px 1px 0;
            style: solid;
            color: var(--primary-divider-color);
        }

        &:last-child {
            margin: 0;
            padding: 0;
            border-right-width: 0;
        }

        &::before {
            content: '';
        }

        button {
            opacity: .5;
        }

        &_isActive {
            background-color: var(--color-white);
            border-bottom-color: transparent;

            button {
                opacity: 1;
            }
        }
    }

    &-Button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
        margin: 0;
        padding: 18px 24px;
        cursor: pointer;

        span {
            &.icon-list {
                display: block;
                width: 17px;
                height: 14px;
                background: url(/style/images/icon-list.svg) no-repeat center;
                margin-right: .5em;
            }

            &.icon-map {
                display: block;
                width: 13px;
                height: 19px;
                background: url(/style/images/icon-marker.svg) no-repeat center;
                margin-right: .5em;
                margin-top: -4px;
                margin-bottom: -2px;
            }
        }
    }
}
