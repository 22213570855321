/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.LocationMap {
    &-MapContainer {
        width: 100%;
        height: calc(100dvh - 58px);

        @include desktop {
            height: 550px;
        }
    }
}

.InfoPopin {
    position: absolute;
    inset-inline: 0;
    inset-block-start: 150px;
    width: calc(100% - 4rem);
    max-width: 350px;
    max-height: calc(100% - 170px);
    margin-inline: auto;
    padding: 2rem;
    background-color: var(--color-white);
    overflow: auto;
    overscroll-behavior: contain;

    &-CloseButton {
        position: absolute;
        z-index: 1;
        inset-inline-end: 1rem;
        inset-block-start: 1rem;
    }

    &-Footer {
        margin-block-start: 25px;
        text-align: center;
    }

    &-SelectLocationButton {
        --button-height: 40px;
        --button-hover-height: var(--button-height);
        --button-font-size: 11px;

        @include mobile {
            width: auto;
        }
    }
}
