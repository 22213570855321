/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

@mixin expanded-content {
    opacity: 1;
    max-height: 100%;
}

.FaqItem {
    &-ExpandableContent {
        border-top: solid 1px #e5e5e5;

        @include mobile {
            padding-left: 14px;
            padding-right: 14px;
        }

        &:first-child {
            border-top: solid 1px #e5e5e5;
        }

        &Button {
            @include desktop {
                padding-block: 25px;
            }
        }

        &Content {
            max-height: 0;
            overflow: hidden;
            opacity: 0;

            &_isContentExpanded {
                @include expanded-content;

                font-weight: 100;

                @include mobile {
                    padding-bottom: 14px;
                }

                @include desktop {
                    padding-bottom: 35px;
                    font-size: 14px;
                }
            }

            @include desktop {
                margin-top: 0;
            }
        }

        @include mobile {
            &:last-of-type {
                border-bottom-width: 0;
            }
        }

        @include desktop {
            border-block-start: 1px solid var(--expandable-content-divider-color);
        }
    }
}
