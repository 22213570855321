/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Breadcrumb {
    $crumb-padding: 6px;

    margin-bottom: 0;
    padding-inline-end: $crumb-padding;

    &:first-child:last-child:not(.Breadcrumb_isLoading) {
        display: none; // hide element when single
    }

    &:last-child {
        margin-bottom: 0;

        .Breadcrumb-Separator {
            display: none;
        }
    }

    &,
    &-Link {
        font-size: 12px;

        @include mobile {
            font-size: 11px;
        }
    }

    &-Link {
        text-transform: none;
    }

    span.Breadcrumb-Link {
        &:hover {
            color: var(--breadcrumbs-color);
        }
    }

    &-Separator {
        margin-inline-start: $crumb-padding;
        top: 1px;
    }
}
