/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.TrainingSession {
    padding: 10px 12px;

    &:nth-child(odd) {
        background: $default-secondary-light-color;
    }

    &-Content {
        font-size: 12px;
        font-weight: 500;
        display: flex;
        justify-content: space-between;

        .Button {
            text-decoration: underline;
            font-weight: 500;
        }
    }

    &-Unavailable {
        font-weight: bold;
        text-transform: uppercase;
    }

    &List-ToggleLinkWrapper {
        margin-top: 15px;

        .Button {
            text-decoration: underline;
            font-weight: 500;
        }
    }

    &-Form {
        margin-block: 15px;
        margin-inline: -12px;
        padding: 20px 15px;
        background-color: lighten($default-primary-base-color, 12%);

        .TrainingCallbackForm {
            &-Title {
                margin: 0;
                text-transform: uppercase;
                font-size: 18px;
                text-align: center;
                font-weight: 800;
            }

            p {
                text-align: center;
            }

            &-SubmitButton {
                width: 100%;
                margin-top: 20px;
            }

            &-Info {
                text-align: center;
                margin-top: 15px;

                p {
                    font-weight: 300;
                    font-size: 12px;
                }
            }

            .TrainingCallbackForm-Legend {
                &:nth-child(2) {
                    display: flex;
                    gap: 10px;

                    .Field-Wrapper {
                        &:nth-child(1) {
                            flex-basis: 60%;
                        }

                        &:nth-child(2) {
                            flex-basis: 40%;
                        }
                    }

                    @media (max-width: 420px) {
                        display: block;
                    }
                }
            }
        }
    }
}
