/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.PageCard {
    &-WrapperLink {
        color: var(--default-color);
    }

    &-Visual {
        &Inner {
            aspect-ratio: 484 / 400;
        }

        .Image {
            display: block;

            &-Image {
                object-fit: cover;
            }
        }
    }

    &-Content {
        padding-block: 20px;
        padding-inline-end: 20px;
    }

    &-Category {
        margin-block-end: 0.5em;
        font-weight: 700;
    }

    &-Tag {
        display: inline-block;
        background-color: var(--secondary-base-color);
        color: var(--color-white);
        text-transform: uppercase;
        font-weight: 500;
        font-size: 8px;
        padding: 2px 5px;
        margin-bottom: 10px;

        @include desktop {
            font-size: 10px;
        }
    }

    &-Title {
        margin-block: 0 .75em;
        font-weight: 700;
        font-size: clamp(18px, calc(0.34vw + 16.73px), 22px);
        line-height: 1.3;
        text-transform: none;

        .TextPlaceholder {
            display: block;

            &::after {
                display: block;
            }
        }
    }

    &-Link {
        font-size: 14px;
        font-weight: 500;

        &,
        span {
            color: var(--link-color);
        }

        span {
            &:not(.TextPlaceholder) {
                text-decoration: underline;
                text-underline-offset: .25em;
            }
        }

        @include desktop {
            font-size: 16px;
        }
    }

    &-LoaderPreventClick {
        @include full-cover();

        background-color: rgba(255, 255, 255, 0.01);
    }

    .RestrictedContentOverlay {
        --restricted-content-overlay-padding-top: #{percentage(400 / 484 / 2)};
    }

    &_horizontal {
        .PageCard {
            &-WrapperLink {
                display: flex;
                border: 1px solid var(--primary-divider-color);
                background-color: var(--color-white);
            }

            &-VisualInner {
                height: 100%;
                aspect-ratio: initial;
            }

            &-Visual {
                .Image {
                    aspect-ratio: 1;
                    width: 140px;

                    &-Image {
                        object-fit: cover;
                    }
                }
            }

            &-Content {
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 16px 30px;
            }

            &-Type {
                font-weight: 300;
                font-size: 13px;
                color: var(--color-black);
                margin-block-end: 15px;
            }

            &-Title {
                margin-block-end: 0;
                font-size: 16px;
                font-weight: 600;
                color: var(--color-black);
            }

            @include narrow-mobile {
                &-Visual {
                    .Image {
                        width: 75px;
                    }
                }

                &-Content {
                    padding-inline: 16px;
                }

                &-Type {
                    font-size: 12px;
                    margin-block-end: 0;
                }

                &-Title {
                    font-size: 13px;
                    font-weight: 700;
                }
            }
        }

        .RestrictedContentOverlay {
            align-items: center;
            padding-block-start: 0;
        }
    }
}
