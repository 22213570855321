/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.PushBrandHomepage {
    $push-brand-hp-breakpoint: 1024px;

    &::after {
        content: "";
        display: block;
        aspect-ratio: 608 / 649;
        width: #{fluid(260px, 608px)};
        background: url($path-img + 'home/brindille.jpg') no-repeat center;
        background-size: 100% auto;
        z-index: -2;
        position: absolute;
        //top: #{fluid(15px, 90px)}; // maquette
        top: 0;
        left: -70px;

        @media (min-width: $push-brand-hp-breakpoint) {
            left: 0;
        }
    }

    &-Wrapper {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 10.5%;
        row-gap: 30px;
        align-items: flex-start;
        //padding-block-start: #{fluid(140px, 320px)}; // maquette
        padding-block-start: #{fluid(135px, 230px)};

        @media (min-width: $push-brand-hp-breakpoint) {
            grid-template-columns: 50% auto;
        }
    }

    &-ImageWrapper {
        &::after {
            content: "";
            display: block;
            width: 89.5%;
            height: 73%;
            background: url($path-img + 'home/marbre.jpg') no-repeat center;
            background-size: cover;
            z-index: -1;
            position: absolute;
            right: -53.5%;
            top: -22.5%;
        }

        @media (max-width: $push-brand-hp-breakpoint - 1px) {
            width: 90%;
        }
    }

    &-Image {
        aspect-ratio: 75 / 67;

        .Image-Image {
            object-fit: cover;
        }
    }

    &-Content {
        max-width: 660px;

        @media (min-width: $push-brand-hp-breakpoint) {
            display: flex;
            flex-direction: column;
            max-width: none;
        }
    }

    &-Title {
        margin-block: 0 .6em;
        font-size: #{fluid(34px, 60px)};
        line-height: 1.1;

        @media (min-width: $push-brand-hp-breakpoint) {
            margin-block-start: 3.8em;
        }
    }

    &-Description {
        margin-block-end: 15px;

        p {
            font-weight: 300;
            font-size: #{fluid(14px, 16px)};
            line-height: 1.7;
        }

        @media (min-width: $push-brand-hp-breakpoint) {
            max-width: 470px;
        }
    }

    &-Link {
        .Button {
            border-color: var(--button-background);

            @include buttonWithArrow();

            @include mobile {
                --button-height: 40px;
                --button-font-size: 9px;
                --button-padding: 35px;
                --button-hover-padding: 35px;

                width: auto;
            }
        }
    }
}
