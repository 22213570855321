/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ContactPage {
    &-Heading {
        height: auto;
        margin-block-start: 0;
        margin-block-end: .5em;
        font-size: var(--h1-font-size-mobile);

        @include desktop {
            font-size: var(--h1-font-size);
        }
    }

    &-ColumnWrapper {
        @include mobile {
            padding-block: 0;
        }

        @include desktop {
            display: grid;
            grid-template-columns: 65% auto;
            column-gap: 5%;
        }

        @include wide-desktop {
            grid-template-columns: 50% auto;
            column-gap: 10%;
        }
    }

    &-Column {
        &_isContent {
            @include mobile {
                margin-block-start: 3em;
            }
        }
    }

    &-Description {
        margin-block-end: 2em;

        @include desktop {
            margin-block-end: 3em;
        }
    }

    &-CustomerService {
        padding: 30px;
        background-color: var(--secondary-light-color);
        text-align: center;

        &,
        .CustomerService .Button_CustomerService {
            --button-height: 34px;
            --button-hover-height: var(--button-height);
            --button-padding: 20px;
            --button-hover-padding: var(--button-padding);
            --button-font-size: 14px;
            --hollow-button-height: var(--button-height);
            --hollow-button-hover-height: var(--button-height);
            --hollow-button-border: var(--default-color);
            --hollow-button-color: var(--default-color);
        }

        &Title {
            margin-block-start: 0;
        }

        &Description {
            // ...
        }

        &Button {
            --button-font-size: 11px;

            margin-block-start: 20px;
        }

        &OpeningHours {
            margin-block-end: 0;
            font-weight: 600;
            font-size: 13px;
            opacity: .7;
        }
    }

    .CustomerService {
        @include desktop {
            flex-direction: column;

            &-Title {
                margin-inline-end: 0;
            }

            &-Content {
                flex-direction: column;
            }
        }
    }
}
