/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ClientIssuesList {
    margin-block-start: 30px;

    &-Title {
        margin-block: 10px;
        font-size: 16px;
    }

    &-Items {
        display: flex;
        flex-direction: column;
        gap: 10px;

        @include mobile {
            margin-inline: -16px;
        }

        .TextPlaceholder {
            height: 180px;

            &::after {
                display: block;
                height: 100%;
            }
        }
    }

    &-NoItems {
        font-weight: 300;

        @include mobile {
            margin-inline: 16px;
            font-size: 16px;
        }
    }
}
