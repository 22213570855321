/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.DocumentLastAdded {
    margin-block-start: #{fluid(20px, 100px)};
    padding-block: #{fluid(30px, 70px)};

    &::before {
        @include full-page-cover(true);

        background-color: var(--secondary-light-color);
    }

    &-Title {
        margin-block: 0 1.3em;
        font-weight: 300;
        font-size: #{fluid(22px, 40px)};
        text-align: center;
    }

    &-Items {
        &_display {
            &_grid {
                @include desktop {
                    display: flex;
                    justify-content: center;
                    gap: 20px;

                    > * {
                        flex-basis: calc(#{percentage(1 / 3)} - #{20 * 2 / 3}px);
                    }
                }

                @include wide-desktop {
                    > * {
                        flex-basis: calc(#{percentage(1 / 4)} - #{20 * 3 / 4}px);
                    }
                }
            }

            &_slider {
                .swiper {
                    &-slide {
                        height: auto;
                    }
                }

                @include mobile {
                    margin-inline: calc(var(--content-wrapper-padding-mobile) * -1);

                    .swiper {
                        padding-inline: var(--content-wrapper-padding-mobile);
                    }
                }
            }
        }
    }

    .RestrictedContentOverlay {
        background-color: rgba($default-secondary-light-color, 0.7);
    }
}
