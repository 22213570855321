/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.DocumentCard {
    display: flex;
    flex-direction: column;
    height: 100%;

    &-Visual {
        flex: 0 0 auto;
        aspect-ratio: 300 / 224;

        .Image {
            display: block;

            &-Image {
                object-fit: cover;
            }
        }

        .DocumentCard-Category {
            position: absolute;
            inset-block-start: 12px;
            inset-inline-start: 10px;
            padding: 1px 5px;
            background-color: var(--secondary-base-color);
            border: 1px solid transparent;
            font-size: 9px;
            font-weight: 500;
            text-transform: uppercase;
            color: var(--color-white);

            @include desktop {
                font-size: 10px;
            }
        }
    }

    &-VisualLink {
        @include full-cover();
    }

    &-VisualButtonVideo {
        --video-play-icon-background: var(--color-black);

        @include full-cover();

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    &-Content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding-block: 15px;
    }

    &-Title {
        margin-block-end: 0.4em;
        font-weight: 700;
        font-size: 18px;
        line-height: 1.2;
    }

    &-Date {
        font-weight: 500;
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: .05em;
    }

    &-Products {
        margin-block-end: 5px;

        > * {
            font-size: 12px;
            font-weight: 500;
        }

        &Label {
            margin-block: 0;
            line-height: 1.5;
        }

        &Link {
            text-decoration: underline;

            @include mobile {
                width: auto;
            }
        }
    }

    &-ProductLink {
        // ...
    }

    &-Actions {
        --hollow-button-border: var(--color-black);
        --hollow-button-color: var(--color-black);
        --hollow-button-height: 40px;
        --hollow-button-hover-height: 40px;
        --button-font-size: 10px;

        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        row-gap: 7px;
        column-gap: 20px;
        margin-block-start: auto;

        .Button {
            &[disabled] {
                opacity: 1;
            }

            @include mobile {
                width: auto;
            }
        }
    }

    &-ButtonVideo {
        gap: 10px;

        &Icon {
            border: {
                width: 5px 8px;
                style: solid;
                color: transparent;
            }

            border-inline-start-color: var(--hollow-button-color);
            border-inline-end-width: 0;
            transition: border .25s ease-out;
        }

        &:hover {
            .DocumentCard-ButtonVideoIcon {
                border-inline-start-color: var(--hollow-button-hover-color);
            }
        }
    }

    &-ButtonDownload {
        gap: 10px;

        .DownloadIcon {
            fill: var(--hollow-button-color);
            transition: fill .25s ease-out;
        }

        &:hover {
            .DownloadIcon {
                fill: var(--hollow-button-hover-color);
            }
        }
    }

    &-FileData {
        flex: 1 1 auto;
        display: flex;
        justify-content: center;
        gap: .3em;
        font-weight: 500;
        font-size: 10px;
        text-align: center;
    }

    .RestrictedContentOverlay {
        --restricted-content-overlay-padding-top: #{percentage(224 / 300 / 2)};
    }

    &_isCondensed {
        min-height: 75px;
        flex-direction: row;
        border: 1px solid var(--primary-divider-color);

        &.DocumentCard {
            &_type {
                &_file,
                &_photo,
                &_photo_hd {
                    .DocumentCard-Category {
                        display: none;
                    }
                }

                &_category,
                &_page {
                    .DocumentCard-Actions {
                        display: contents;
                    }

                    .DocumentCard-Link {
                        position: absolute;
                        inset: 0;
                        width: 100%;
                        height: 100%;
                        border: none;
                        border-radius: 0;
                        background: transparent;
                        padding: 0;

                        span {
                            display: none;
                        }
                    }
                }
            }
        }

        .DocumentCard {
            &-Visual {
                flex-basis: 75px;
                aspect-ratio: 1;
            }

            &-VisualButtonVideo {
                display: none;
            }

            &-Content {
                flex-direction: row;
                align-items: center;
                padding-block: 10px;
                padding-inline: 10px;
            }

            &-Header {
                flex: 1 1 auto;
            }

            &-Title {
                margin-block-end: .2em;
                font-size: 13px;
                font-weight: 600;
            }

            &-Actions {
                flex: 0 0 auto;
                margin-block-start: 0;
                align-items: flex-end;
                padding-bottom: 5px;
            }

            &-ButtonDownload {
                --hollow-button-padding: 0;
                --hollow-button-hover-padding: 0;

                width: var(--hollow-button-height);

                span {
                    display: none;
                }
            }

            &-ButtonVideo {
                //--hollow-button-height: 32px;
                //--hollow-button-hover-height: 32px;
                --hollow-button-background: var(--color-black);
                --hollow-button-padding: 0;
                --hollow-button-hover-padding: 0;
                --hollow-button-color: var(--color-white);

                @include no-txt();

                gap: 0;
                width: var(--hollow-button-height);
                letter-spacing: 0;
            }

            &-FileData {
                justify-content: flex-start;
                font-size: 11px;
                font-weight: 400;
                opacity: .8;
            }
        }

        &:has(.RestrictedContentOverlay) {
            .DocumentCard-Actions {
                opacity: 0;
            }
        }

        .RestrictedContentOverlay {
            --restricted-content-overlay-padding-top: 0;
            --restricted-content-overlay-size: 40px;

            right: 0px;
            left: auto;
            align-items: flex-end;
            padding-bottom: 5px;
            justify-content: flex-end;
            padding-inline: 10px;

            &-Content {
                &::before {
                    width: 10px;
                    height: 10px;
                }

                span {
                    font-size: 6px;
                }
            }
        }
    }
}
