/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.DocumentList {
    &-WrapperItems {
        @include mobile {
            margin-inline: calc(var(--content-wrapper-padding-mobile) * -1);
            padding: 35px;

            &::before {
                @include full-page-cover(true);

                background-color: var(--secondary-light-color);
            }
        }
    }

    &-Items {
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 35px;

        @media (min-width: 580px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include desktop {
            grid-template-columns: repeat(3, 1fr);
            grid-row-gap: 50px;
        }

        @include wide-desktop {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &-Item {
        &,
        &:last-child {
            margin-block-end: 0;
        }

        &::before {
            display: none;
        }
    }

    .RestrictedContentOverlay {
        @include mobile {
            background-color: rgba($default-secondary-light-color, 0.7);
        }
    }
}
