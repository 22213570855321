/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --wishlist-item-border-color: var(--expandable-content-divider-color);
}

.FavoriteShop {
    .Title {
        margin-bottom: 35px;
    }

    .FavoriteShopElement {
        padding: 5px;
        line-height: 1.6;
        font-size: 16px;
        color: rgb(54, 54, 54);
    }

    .EelementName {
        font-size: 18px;
        font-weight: bold;
        color: black;
    }

    .EelementTel {
        font-size: 16px;
        font-weight: 600;
        color: black;
        margin-top: 7px;
        margin-bottom: 9px;
    }

    &-Suggestions {
        display: flex ;
        flex-direction: column;
        text-align: center;
        padding-bottom: 10px;
    }

    &-ActionContainer {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .Suggestion {
        margin: 5px;
    }

    &-LayoutWrapper {
        @include mobile {
            display: flex;
            justify-content: center;
            justify-items: center;
            order: 2;
        }
    }

    &-Loader {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &-Products {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: max-content;
        grid-gap: 24px;

        @include tablet {
            grid-template-columns: repeat(2, 1fr);
            inset-block-start: var(--myaccount-wishlist-action-bar-offset);
            padding-block-end: var(--myaccount-wishlist-action-bar-offset);
        }

        @include narrow-desktop {
            grid-template-columns: repeat(3, 1fr);
            inset-block-start: var(--myaccount-wishlist-action-bar-offset);
            padding-block-end: var(--myaccount-wishlist-action-bar-offset);
        }

        @include wide-desktop {
            grid-template-columns: repeat(4, 1fr);
            inset-block-start: 0;
        }

        @include mobile {
            grid-column-gap: 28px;
            grid-template-columns: repeat(2, 1fr);
            border: 0;
            max-width: 100%;
            margin-top: 20px;

            & > .Loader .Loader-Scale {
                position: fixed;
                inset-block-start: auto;
                inset-block-end: 50%;
                transform: translate(-50%, 50%);
            }
        }

        @include desktop {
            grid-column: 2;
        }
    }

    &-NoProducts {
        @include tablet {
            position: relative;
            inset-block-start: 60px;
        }
    }

    &-ActionBar {
        display: flex;
        padding: 0;
        padding-block-end: 12px;
        justify-content: flex-end;

        @include desktop {
            position: absolute;
            inset-block-start: 0;
            inset-inline-end: 0;
        }

        @include wide-desktop {
            inset-block-start: calc(0px - var(--myaccount-wishlist-action-bar-offset));
        }

        @include mobile-bottom-wrapper {
            padding: 16px;
            inset-block-end: 0px;
        }

        @include tablet {
            .AddToCart-Icon {
                display: none;
            }
        }
    }

    &-ActionBarActionWrapper {
        display: flex;
        justify-content: space-between;
        margin-block-end: 10px;
    }

    &-NoProductsButton {
        margin-block-start: 12px;

        @include mobile {
            margin-block-start: 14px;
        }
    }

    &-Button {
        @include desktop {
            margin-inline-end: 30px;
            margin-inline-start: 30px;
        }

        @include mobile {
            width: 100%;
            margin-bottom: 16px;
        }

        .CartIcon {
            fill: var(--color-white);
            margin-inline-end: 12px;
            width: 20px;
            height: 20px;

            &:hover {
                fill: var(--color-white);
            }
        }
    }

    &-ClearRemoveItemsButton {
        text-transform: uppercase;
        color: initial;

        &.Button {
            width: auto;
        }
    }

    &-ClearWishlistButton {
        font-weight: bold;
        text-transform: uppercase;

        @include desktop {
            order: 1;
        }

        &.Button {
            width: auto;
        }
    }

    &-Filter {
        @include mobile {
            justify-content: center;
            font-weight: 700;
            font-size: 14px;
            text-transform: uppercase;

            .FilterIcon {
                display: none;
            }
        }

        @include desktop {
            display: inline-flex;
        }
    }

    .Input, .Input:focus {
        border: none;
        padding: 0;
        margin: 0;
    }

    &-InputContainer {
        border-radius: 15px ;
        padding-left: 25px;
        padding-right: 25px;
        border: 1px solid var(--primary-divider-color);
    }

    &-Subheading {
        margin-left: 10px;
        width: 18px;
        height: 18px;
        background-color: var(--color-black);
        border-radius: 50%;
        font-size: 8px;
        font-weight: 400;
        color: var(--color-white);
        text-align: center;
        line-height: 18px;
    }

    &-MiscellaneousLayoutWrapper {
        @include mobile {
            display: flex;
            justify-content: center;
            width: 100%;
        }
    }

    &-BackButton {
        padding-left: 3px;
        font-size: 12px;
        display: flex;
        align-items: center;
    }

    .ChevronIcon_direction_left {
        margin-right: 10px;
        display: inline-block;
        vertical-align: bottom;
    }

    &-Title {
        font-size: 18px;
        margin-top: 30px;
        font-weight: bold;
        text-transform: uppercase;
    }

    .Shop {
        margin-left: 30px;
    }

    &-Miscellaneous {
        z-index: 5;

        @include mobile {
            display: flex;
            border-block-start: 1px solid var(--primary-divider-color);
            border-block-end: 1px solid var(--primary-divider-color);

            &::before {
                content: "";
                display: block;
                height: calc(100% - 8px);
                border-left: 1px solid var(--primary-divider-color);
                position: absolute;
                top: 4px;
                left: 50%;
            }

            > * {
                flex-basis: 50%;
            }
        }

        @include desktop {
            justify-content: flex-end;
            float: right;
            padding-block: 0 20px;

            & + * {
                clear: both;
            }
        }

        .CategoryPage-Filter {
            @include desktop {
                display: none;
            }
        }
    }

    &-SetContainer {
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: center;
    }

    .Description {
        padding: 0 10%  20px 10%;
        font-size: 16px;
        font-weight: 700;
    }

    .Locate {
        font-size: 13px;
        text-transform: capitalize;
        text-decoration: underline;
    }

    &-ShareWishlistButton {
        .ShareIcon {
            margin-inline-end: 12px;
        }

        &:not([disabled]):hover .ShareIcon_isPrimary {
            fill: var(--primary-dark-color);
        }
    }
}
