/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.StarFavoriteIcon {
    stroke: var(--color-black);

    &:hover {
        stroke: var(--primary-base-color);
        fill: var(--primary-base-color);
    }

    &_isActive {
        fill: var(--primary-base-color);
        stroke: var(--primary-base-color);

        &:hover {
            fill: none;
            stroke: var(--color-black);
        }
    }

    @include mobile {
        pointer-events: none;
    }

    height: 18px;
    min-width: 18px;
    cursor: pointer;
}
