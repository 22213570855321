/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.VideoThumbnail {
    &-Button {
        cursor: grab;
    }

    &-PlayIcon {
        content: "";
        display: block;
        background: url(/style/images/play.svg) no-repeat center;
        background-size: 100% 100%;
        width: 66px;
        height: 62px;
        border-block-start: none;
        border-inline-start: none;
        border-block-end: none;
        font-size: 0;
        cursor: pointer;
    }
}
