/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.SearchResultsTab {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary-light-color);
    padding: 1.2em 1.5em;
    border: 1px solid var(--primary-divider-color);
    border-inline-start-width: 0;
    cursor: pointer;
    font-size: 1.1rem;

    @include desktop {
        font-size: 1.5rem;
    }

    span {
        opacity: .3;
    }

    &-Title {
        font-size: 1em;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.1em;
    }

    &-Count {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-inline-start: .5em;
        background-color: var(--color-black);
        width: 17px;
        height: 17px;
        border-radius: 50%;
        font-size: 8px;
        font-weight: 700;
        color: var(--color-white);
        text-align: center;
    }

    &_isActive {
        background-color: var(--color-white);
        border-block-end-color: transparent;

        span {
            opacity: 1;
        }
    }

    &:first-child {
        @include desktop {
            border-inline-start-width: 1px;
        }
    }

    &:last-child {
        @include mobile {
            border-inline-end-width: 0;
        }
    }
}
