/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.SearchResultsTabs {
    display: flex;
    margin-block-end: 35px;

    @include desktop {
        &::before,
        &::after {
            content: "";
            border-block-end: 1px solid var(--primary-divider-color);
            position: absolute;
            inset-block-end: 0;
        }

        &::before {
            width: calc(100% + 32px);
            inset-inline-start: 0;
        }

        &::after {
            width: 32px;
            inset-inline-end: 100%;
        }
    }
}
