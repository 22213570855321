/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.PaymentMethods {
    text-align: center;

    &-Title {
        margin-block: 0 0.5em;
        font-size: 13px;
        font-weight: 600;
        text-transform: none;
        line-height: 1.3;
    }

    &-Content {
        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            li {
                max-width: 48px;
                height: 22px;
                margin: 5px 8px;

                &::before {
                    display: none;
                }

                img {
                    width: 100%;
                    max-width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        @include narrow-mobile {
            ul {
                margin-inline: -8px;

                li {
                    max-width: 38px;
                    height: 16px;
                    margin-inline: 5px;
                }
            }
        }
    }

    @include desktop {
        display: flex;
        align-items: center;
        justify-content: center;

        &-Title {
            margin-right: 15px;
            margin-bottom: 0;
        }
    }
}
