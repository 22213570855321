/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Pagination {
    flex-direction: column;
    width: auto;
    margin-block: 25px 20px;
    margin-inline: 0;

    &_isLoading {
        flex-direction: row;
        width: auto;
    }

    > p {
        margin-block-end: 1.5em;
        font-weight: 500;
        font-size: 11px;
    }

    .Button {
        --hollow-button-height: 40px;
        --hollow-button-border: var(--secondary-base-color);
        --hollow-button-color: var(--secondary-base-color);
        --button-font-size: 11px;
        --hollow-button-hover-height: var(--hollow-button-height);

        width: 100%;
        max-width: 340px;
    }

    @include mobile {
        display: flex;
    }

    @include desktop {
        margin-block: 70px 30px;

        > p {
            font-size: 13px;
        }
    }
}
