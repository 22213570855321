/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.DeliveryOption {
    display: flex;
    align-items: center;

    strong {
        white-space: normal;
    }

    &-Visual {
        flex-basis: 30%;
        padding: 0 10px;
        text-align: center;

        > span {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 50px;

            &::before {
                @include full-cover(true);

                max-width: 75px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }

            &[data-carrier="chronopost"],
            &[data-carrier="chronorelais"],
            &[data-carrier="chronocclassic"] {
                strong {
                    display: none;
                }

                &::before {
                    background-image: url($path-img + 'transporters/chronopost.svg');
                }
            }

            &[data-carrier="colissimo"] {
                strong {
                    display: none;
                }

                &::before {
                    background-image: url($path-img + 'transporters/colissimo.svg');
                }

                &[data-country="BE"] {
                    &::before {
                        background-image: url($path-img + 'transporters/bpost.svg');
                    }
                }

                &[data-country="DE"] {
                    &::before {
                        background-image: url($path-img + 'transporters/dpd.svg');
                    }
                }

                &[data-country="ES"] {
                    &::before {
                        background-image: url($path-img + 'transporters/seur.svg');
                        max-width: 110px;
                    }
                }
            }

            &[data-carrier="collectinstore"] {
                strong {
                    display: none;
                }

                &::before {
                    background-image: url($path-img + 'transporters/pickup.svg');
                }
            }

            &[data-carrier^="dpd"][data-carrier$="predict"] {
                strong {
                    display: none;
                }

                &::before {
                    background-image: url($path-img + 'transporters/dpd-predict.svg');
                }
            }

            &[data-carrier="dpdrelais"] {
                strong {
                    display: none;
                }

                &::before {
                    background-image: url($path-img + 'transporters/dpd-pickup.svg');
                }
            }

            &[data-carrier="gls"] {
                strong {
                    display: none;
                }

                &[data-medthod="tohome"],
                &[data-medthod="fds"] {
                    min-height: 60px;

                    &::before {
                        background-image: url($path-img + 'transporters/gls-flex.svg');
                    }
                }

                &[data-medthod="relay"] {
                    min-height: 60px;

                    &::before {
                        background-image: url($path-img + 'transporters/gls-relais.svg');
                    }
                }
            }
        }
    }

    &-Details {
        flex-basis: 70%;

        > span {
            display: block;
        }
    }

    &-DeliveryDate {
        font-weight: 300;
    }

    &-Price {
        strong {
            font-weight: 500;
        }
    }

    &-Alternative {
        margin-top: 10px;
        margin-left: calc(20px + 30%);
        font-size: 12px;
        color: var(--primary-dark-color);
    }

    @include desktop {
        &-Details {
            line-height: 1.3;
        }

        &-Rate {
            font-size: 16px;
        }

        &-Price {
            margin-block-start: 8px;
            font-size: 18px;
        }
    }
}
