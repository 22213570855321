/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.InteractiveCatalog {
    &-Wrapper {
        @include mobile {
            margin-block-start: 0;
        }

        @include desktop {
            padding-top: 24px;
        }
    }

    &Page {
        &-Heading {
            margin-bottom: 26px;

            @include mobile {
                text-align: center;
            }
        }

        &-LoadMore {
            text-align: center;
        }

        &-LoadMoreButton {
            --hollow-button-border: #DEC7B5;
            --hollow-button-color: #DEC7B5;
            --button-font-size: 10px;
            --hollow-button-height: 40px;
            --button-hover-height: 40px;

            &:hover {
                --hollow-button-height: 40px;
                --button-hover-height: 40px;
            }

            @include mobile {
                margin-top: 25px;
            }

            @include desktop {
                --hollow-button-padding: 60px;
                --button-hover-padding: 60px;

                &:hover {
                    --hollow-button-hover-padding: 60px;
                    --hollow-button-hover-height: 40px;
                }

                margin-top: 60px;
            }
        }

        &-Items {
            @include mobile {
                grid-row-gap: 30px !important;
            }

            @include desktop {
                grid-row-gap: 55px !important;
            }
        }
    }

    &-Title {
        font-weight: 300;
        margin-bottom: 10px;

        @include mobile {
            font-size: 14px;
            margin-top: 10px;
        }

        @include desktop {
            font-size: 18px;
            margin-top: 15px;
        }
    }

    &-Link {
        font-weight: 500;
        text-decoration: underline;

        @include mobile {
            font-size: 12px;
        }
    }

    &-Visual {
        aspect-ratio: 350 / 430;

        .Image {
            display: block;

            &-Image {
                object-fit: cover;
            }
        }
    }

    .RestrictedContentOverlay {
        --restricted-content-overlay-padding-top: #{percentage(430 / 350 / 2)};
    }
}
