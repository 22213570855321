/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.SearchResultsCategories {
    margin-block-end: 35px;

    &-Title {
        margin-block-end: 1em;
        font-weight: 700;
        font-size: var(--paragraph-font-size);
        text-transform: uppercase;
        letter-spacing: .1em;
    }

    &-Content {
        display: flex;
        flex-wrap: wrap;
        gap: 7px;
    }

    &-ItemLink {
        display: inline-flex;
        padding: 7px 12px;
        border-radius: 20px;
        background-color: var(--secondary-light-color);
        font-size: 11px;
        font-weight: 500;
        color: #676767;
        transition-duration: .25s;
        transition-timing-function: ease-out;
        transition-property: all;

        &:hover {
            background-color: $beige;
        }
    }

    @include mobile {
        margin-block-end: 10px;
        padding-block-end: 10px;
        padding-inline: var(--content-wrapper-padding-mobile);
        overflow: auto;

        &-Content {
            flex-wrap: nowrap;
            white-space: nowrap;
        }
    }
}
