/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.DocumentListPage {
    overflow: hidden;

    &-Back {
        padding-block: 15px;
    }

    &-BackLink {
        --link-color: var(--color-black);

        display: flex;
        align-items: center;
        gap: 6px;
        font-weight: 400;
        font-size: 12px;
    }

    &-Heading {
        margin-block-end: 0.5em;
        text-align: center;

        @include mobile {
            &:first-child {
                margin-block-start: 1em;
            }
        }
    }

    &-Description {
        margin-block-end: 2em;
        font-weight: 500;
        font-size: #{fluid(14px, 20px)};
        text-align: center;
    }

    &-SearchBar {
        max-width: 780px;
        margin-inline: auto;
    }

    &-Separator {
        padding-block: 20px;
        text-align: center;

        p {
            margin: 0;
        }
    }

    &-DocumentList {
        margin-block-start: 25px;
    }
}

.isDocumentListPageHome {
    .Breadcrumbs,
    .Breadcrumbs-List {
        display: none;
    }

    .DocumentListPage {
        @include desktop() {
            padding-block-start: 32px; // height of an empty breadcrumb
        }
    }
}
