/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.QuickOrderSuggestion {
    display: grid;
    grid-template-columns: #{"min(25%, 120px)"} auto;
    grid-gap: 20px;
    container: quickOrderSuggestion / inline-size;

    &-VisualThumbnail {
        display: block;
        height: 0;
        padding-block-end: var(--ratio-product-image);

        .Image-Image {
            width: 100%;
        }
    }

    &-Content {
        display: flex;
        flex-direction: column;
    }

    &-ContentFooter {
        display: flex;
        align-items: flex-end;
        column-gap: 20px;
        row-gap: 15px;
        margin-block-start: 15px;

        > * {
            flex: 1 1 auto;
        }

        a {
            font-size: 13px;
            text-decoration: underline;
        }

        @container quickOrderSuggestion (max-width: 330px) {
            flex-direction: column;
            align-items: stretch;
        }
    }

    &-Title {
        margin-block-end: 0;
        font-weight: 700;
    }

    &-ProductSku,
    &-ProductContenance {
        font-weight: 400;
        font-size: 11px;
        color: #676767;
    }

    &-Details {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    &-Actions {
        text-align: end;

        @include mobile {
            --button-height: 35px;
            --button-font-size: 10px;
        }

        @include desktop {
            .Button {
                width: 100%;
                max-width: 200px;
            }
        }
    }

    &-SelectionRequired {
        display: inline-flex;
        max-width: 120px;
        margin-block-end: 0;
        font-size: 12px;
        font-weight: 400;
        text-align: center;

        @container quickOrderSuggestion (max-width: 330px) {
            max-width: none;
        }

        @container quickOrderSuggestion (min-width: 500px) {
            max-width: 200px;
            font-size: 13px;
        }
    }
}
