/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.PageReviewItem {
    --paragraph-font-size: 16px;
    --paragraph-font-size-mobile: 12px;
    --paragraph-line-height: 1.5;

    border-block-end: 1px solid var(--primary-divider-color);
    padding-block-end: 30px;
    margin-block-end: 30px;

    &::before {
        display: none !important;
    }

    &:last-child {
        border: none;
        margin-block-end: 0;
    }

    &-Header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 10px;

        @include mobile {
            justify-content: space-between;
        }
    }

    &-Author {
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 700;

        .user-group {
            display: inline-block;
            padding: .2em .8em;
            border-radius: 10px;
            background-color: var(--color-black);
            font-weight: 400;
            font-size: 10px;
            color: var(--color-white);
            text-transform: uppercase;

            @include mobile {
                font-size: 9px;
            }
        }
    }

    &-ReviewDate {
        font-weight: 500;
        font-size: 14px;

        @include mobile {
            font-size: 12px;
        }
    }

    &-Content {
        margin-block-start: 10px;
    }

    &-Message_answer {
        margin-block-start: 20px;
        padding: 20px;
        background: var(--secondary-light-color);

        @include desktop {
            margin-block-start: 30px;
            padding: 30px;
            padding-inline-start: 70px;
        }

        .ps-icon {
            display: inline-block;
            width: 30px;
            height: 30px;
            background: var(--color-black);
            color: var(--color-white);
            border-radius: 50%;
            font-size: 10px;
            font-weight: 500;
            line-height: 30px;
            text-align: center;

            @include desktop {
                position: absolute;
                left: -45px;
                top: -5px;
            }
        }
    }
}
