/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --footer-nav-height: 0; // 48px
}

.Footer {
    &-Blocks {
        @include mobile {
            padding-block: 20px 30px;

            &Content {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: calc(var(--content-wrapper-padding-mobile) * 2);

                &::before {
                    content: "";
                    display: block;
                    height: 100%;
                    border-left: 1px solid var(--primary-divider-color);
                    position: absolute;
                    top: 0;
                    left: 50%;
                    pointer-events: none;
                }
            }
        }

        @media (max-width: 359px) {
            &Content {
                grid-template-columns: 34% auto;

                &::before {
                    left: calc(34% + var(--content-wrapper-padding-mobile) * 1.3);
                }
            }
        }

        @include desktop {
            background-color: var(--secondary-light-color);

            &Content {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: center;
                padding-block: 15px;

                &::before {
                    content: "";
                    display: block;
                    width: calc(100% - var(--content-wrapper-padding) * 2);
                    border-top: 1px solid var(--primary-divider-color);
                    position: absolute;
                    top: 0;
                    left: var(--content-wrapper-padding);
                }

                > * {
                    padding-block: 15px;
                }
            }
        }

        @include wide-desktop {
            &Content {
                flex-wrap: nowrap;
            }
        }
    }

    &-Reinsurance {
        --paragraph-font-size: 14px;
        --paragraph-font-size-mobile: 11px;

        padding-block: 30px;

        .pagebuilder-column-group {
            gap: 0;
        }

        .pagebuilder-column {
            &,
            #html-body & {
                flex-grow: 1;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-inline: var(--content-wrapper-padding-mobile);
            }

            [data-content-type="image"] {
                flex: 0 0 auto;
                margin-right: 10px;
                max-width: 60px;
            }

            [data-content-type="text"] {
                flex: 1 1 auto;

                p {
                    margin: 0;
                }
            }
        }

        @include mobile {
            .pagebuilder-column-group {
                display: grid !important;
                grid-template-columns: 1fr 1fr;
                column-gap: 10px;
                row-gap: 10px;
                padding-inline: var(--content-wrapper-padding-mobile);
            }

            .pagebuilder-column {
                &,
                #html-body & {
                    width: 100%;
                    padding-inline: 0;
                }
            }
        }

        @include narrow-mobile {
            .pagebuilder-column {
                [data-content-type="image"] {
                    max-width: 50px;
                }
            }
        }

        @include desktop {
            padding-block: 70px;
        }

        @include wide-desktop {
            --content-wrapper-width: 1430px;

            .CmsBlock-Wrapper {
                @include wrap();
            }

            .pagebuilder-column {
                [data-content-type="image"] {
                    max-width: 85px;
                    margin-right: 16px;
                }
            }
        }
    }

    &-SocLinks {
        padding: var(--content-wrapper-padding-mobile);

        @include desktop {
            padding-block: 35px;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            li {
                margin: 5px 10px;

                &::before {
                    display: none;
                }

                a {
                    display: block;
                    width: 30px;
                    height: 30px;
                    background-size: contain;

                    @include no-txt();

                    &::before {
                        content: "";
                        display: block;
                        width: 25px;
                        height: 25px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                    }

                    $soc-links-list: facebook instagram linkedin pinterest twitter youtube;

                    @each $soc-link in $soc-links-list {
                        &[href*="#{$soc-link}"] {
                            &::before {
                                background-image: url($path-img + 'logos/soc-#{$soc-link}.svg');
                            }
                        }
                    }

                    &[href*="facebook"]::before {
                        height: 21px;
                    }

                    &[href*="pinterest"]::before {
                        height: 21px;
                    }

                    &[href*="youtube"]::before {
                        width: 30px;
                    }
                }
            }
        }
    }

    &-Content {
        background-color: var(--secondary-light-color);
    }

    &-Columns {
        @include mobile {
            padding: 0;
            text-align: left;
        }

        @include desktop {
            padding-top: 60px;
            padding-bottom: 15px;
        }

        @include ultra-narrow-desktop {
            flex-wrap: wrap;
        }
    }

    &-Column {
        @include mobile {
            .ExpandableContent {
                padding-inline: var(--content-wrapper-padding-mobile);
                border-bottom: 0;
                border-top: 1px solid var(--primary-divider-color);

                &-Heading {
                    text-transform: uppercase;
                    font-size: 12px;
                    font-weight: bold;
                }

                &-Content {
                    h2 {
                        display: none;
                    }
                }
            }
        }

        &:not(:last-of-type),
        &:last-of-type {
            @include ultra-narrow-desktop {
                flex-basis: 50%;
                max-width: 50%;
                padding-left: 10%;
            }
        }

        &:not(:last-of-type) {
            @include mobile {
                margin-block-end: 0;
            }

            @include wide-desktop {
                margin-block-end: 0;
                max-width: calc(100% / 4);
            }
        }

        &:last-of-type {
            @include wide-desktop {
                padding-right: 5%;
            }
        }

        &Title {
            margin-block: 0 0.5em;
            font-size: 13px;
            font-weight: 700;
            line-height: 1.3;

            @include desktop {
                margin-bottom: .8em;
            }
        }

        &:not(.Footer-Column_newsletter) {
            ul {
                padding-block: 5px 30px;

                li {
                    margin-bottom: 12px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &::before {
                        display: none;
                    }

                    a {
                        --link-color: var(--paragraph-color);

                        font-weight: 500;
                        font-size: 12px;
                    }
                }
            }
        }

        &_newsletter {
            .Footer-ColumnTitle {
                font-weight: 300;
                font-size: 22px;
                text-transform: none;
            }

            .Footer-ColumnContent {
                p {
                    margin-bottom: 20px;
                    font-size: 13px;
                }
            }

            &.Footer-Column_isEmbed {
                .Footer-ColumnTitle {
                    text-align: center;
                }
            }
        }
    }

    &-Newsletter {
        // only on mobile
        padding-inline: var(--content-wrapper-padding-mobile);
    }

    .CustomerService {
        &-Content {
            @include mobile {
                column-gap: 10px;
                row-gap: 15px;
            }
        }

        .Button {
            @include mobile {
                width: auto;
                margin: 0;
            }

            &_Contact {
                display: inherit;

                @include mobile {
                    --hollow-button-height: 28px;
                    --hollow-button-hover-height: var(--hollow-button-height);
                }

                @include narrow-mobile {
                    --button-font-size: 10px;
                    --hollow-button-padding: 10px;
                    --hollow-button-hover-padding: var(--hollow-button-padding);
                    --button-letter-spacing: 0;
                }
            }

            &_CustomerService {
                @include desktop {
                    --button-height: 34px;
                    --button-hover-height: 34px;
                    --button-padding: 20px;
                    --button-hover-padding: 20px;
                    --button-font-size: 14px;
                }
            }
        }
    }

    &-ByGrdnrs {
        @include mobile {
            padding-inline: var(--content-wrapper-padding-mobile);
            padding-block: 20px;
            border-top: 1px solid var(--primary-divider-color);
        }

        @include desktop {
            margin-left: auto;
        }

        &Link {
            @include no-txt();

            display: block;
            width: 80px;
            height: 16px;
            background: url($path-img + 'by-gardeners.svg') no-repeat center;
            background-size: contain;
        }
    }

    &-StoreSwitcher {
        flex-shrink: 0;

        @include mobile {
            padding: var(--content-wrapper-padding-mobile);
            border-top: 1px solid var(--primary-divider-color);
        }
    }

    &-Bottom {
        padding-block: 15px 20px;

        &Content {
            display: flex;
            justify-content: space-between;
        }
    }

    &_isCheckout {
        @include mobile {
            margin-block-start: 30px;

            .Footer {
                &-Blocks {
                    padding-block: 10px;

                    &Content {
                        grid-template-columns: 1fr;

                        &::before {
                            display: none;
                        }
                    }

                    .PaymentMethods {
                        margin-block-start: 15px;

                        &-Title {
                            display: none;
                        }

                        &-Content {
                            ul {
                                li {
                                    max-width: 70px;
                                    height: 25px;
                                    margin-inline: 10px;
                                }
                            }
                        }
                    }
                }

                &-Reinsurance {
                    padding-block: 10px;
                }
            }

            .CustomerService {
                .Button {
                    width: auto;

                    &_Contact {
                        display: none;
                    }
                }
            }
        }

        @include desktop {
            .Footer {
                &-Reinsurance {
                    padding-block: 30px 60px;
                }
            }
        }
    }
}
