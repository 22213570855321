/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CollectionListPage {
    .CategoryDetails-Heading {
        @include mobile {
            text-align: center;
        }
    }

    &-WrapperItems {
        @include mobile {
            padding: 0 var(--content-wrapper-padding-mobile);
        }
    }

    &-Items {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: #{fluid(8px, 25px)};

        @media (min-width: 480px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include desktop {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &-Item {
        margin-block-end: 0;

        &::before {
            display: none;
        }
    }

    @include desktop {
        &-Item {
            margin-block-end: 0;

            @for $i from 0 through 4 {
                &:nth-child(#{1 + $i * 26}) {
                    grid-column: 1 / span 2;
                    grid-row-start: 1 + $i * 8;
                    grid-row-end: span 2;

                    .CollectionCard {
                        &-Content {
                            padding-block: 4.5%;
                        }

                        &-Category {
                            font-size: #{fluid(18px,22px,811px)};
                        }

                        &-Title {
                            font-size: #{fluid(28px,39px,811px)};
                        }
                    }
                }
            }

            @for $i from 0 through 4 {
                &:nth-child(#{16 + $i * 26}) {
                    grid-column: 3 / span 2;
                    grid-row-start: 5 + $i * 8;
                    grid-row-end: span 2;

                    .CollectionCard {
                        &-Content {
                            padding-block: 4.5%;
                        }

                        &-Category {
                            font-size: #{fluid(18px,22px,811px)};
                        }

                        &-Title {
                            font-size: #{fluid(28px,39px,811px)};
                        }
                    }
                }
            }
        }
    }

    .CollectionCard {
        &-Content {
            padding: 8% 6%;
        }

        &-Category {
            margin-block-end: 0.7em;
            font-size: 15px;

            @include desktop {
                font-size: #{fluid(13px,15px,811px)};
            }
        }

        &-Title {
            font-weight: 700;
            font-size: 22px;

            @include desktop {
                font-size: #{fluid(18px,22px,811px)};
            }
        }
    }
}
