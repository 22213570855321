/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.PushCollectionHomepage {
    &-SectionTitle {
        @include homepageSectionTitle();

        @include desktop {
            margin-block-end: 1.8em;
        }
    }

    &-Main {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 30px;
        column-gap: 3vw;
        align-items: flex-end;

        @include desktop {
            grid-template-columns: 30% auto;
            align-items: flex-start;
        }
    }

    &-ImageWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        height: 100%;
        z-index: 10;
    }

    &-Image {
        aspect-ratio: 1.36;
        min-height: 50vw;

        @include desktop {
            width: calc(100% + var(--content-wrapper-padding));
            min-height: 100%;
            aspect-ratio: 1;
        }

        @media (min-width: 1580px) {
            width: calc(100% + 50vw - 746px);
        }

        .Image-Image {
            object-fit: cover;
        }
    }

    &-Category {
        font-size: 18px;
        display: none;
    }

    &-Title {
        margin-block: 0.3em 0.7em;
        font-weight: 700;
        font-size: #{fluid(20px, 24px)};
        line-height: 1.2;
    }

    &-Description {
        p {
            font-size: 16px;
        }

        > .pagebuilder-column-group {
            > .pagebuilder-column {
                &:first-child {
                    width: auto !important;
                }

                &:not(:first-child) {
                    display: none !important;
                }
            }
        }
    }

    &-Link {
        margin-block-start: 40px;

        .Button {
            @include buttonHollowWithArrow();
        }
    }

    &-Items {
        @include desktop {
            margin-top: 4vw;
        }
    }

    .swiper {
        overflow: visible;
        padding-block-end: 50px;
    }

    .swiper-slide {
        max-width: 168px; // @TODO TEMPORARY ?
    }

    .ProductCard {
        --product-card-btn-addtocart-width: 45px;
        --product-card-btn-addtocart-height: 45px;

        &-Name {
            font-size: 12px;
        }

        .ProductPrice-PriceValue {
            font-size: 13px;
        }
    }
}
