/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.PageReviews {
    max-width: var(--content-wrapper-width);
    margin-inline: auto;
    padding-block: 40px;
    padding-inline: var(--content-wrapper-padding-mobile);

    @include desktop {
        padding-block: 100px;
        padding-inline: var(--content-wrapper-padding);
    }

    &-Title {
        margin-block-start: 0;
        text-transform: uppercase;

        @include mobile {
            text-align: center;
        }

        @include desktop {
            font-weight: 300;
            font-size: clamp(30px, calc(1.33vw + 19.2px), 40px);
        }
    }

    &-Button {
        --button-background: var(--secondary-base-color);
        --button-border: var(--secondary-base-color);

        max-width: 340px;
        grid-column: 1;

        @include mobile {
            display: block;
            margin-inline: auto;
        }
    }

    &-LoginRequired {
        &Buttons {
            display: flex;
            gap: 15px;
            margin-block-start: 30px;

            @include mobile {
                flex-direction: column;
            }
        }
    }

    // LAYOUT

    @include desktop {
        display: grid;
        grid-template-columns: 40% 1fr;
        grid-template-rows: auto 2fr;
        grid-column-gap: 35px;

        &-Title {
            grid-row: 1;
        }

        &-Button {
            margin-block-start: 20px;
        }

        .PageReviewList {
            grid-row-start: 1;
            grid-row-end: 3;
            grid-column: 2;
        }

        // When only 2 children => no reviews => align center

        > *:nth-last-child(-n+2):first-child,
        > *:nth-last-child(-n+2):first-child ~ * {
            grid-column-start: span 2;
            margin-inline: auto;
            text-align: center;
        }
    }
}
