/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ShareButton {
    &-Button {
        width: auto;

        .ShareIcon {
            margin-inline-end: 10px;
            transition: fill .25s ease-out;
        }

        &:hover {
            .ShareIcon {
                fill: var(--hollow-button-hover-color);
            }
        }
    }

    &-Button,
    &-Link {
        --hollow-button-border: var(--color-black);
        --hollow-button-color: var(--color-black);
        --hollow-button-height: 40px;
        --hollow-button-hover-height: 40px;
        --button-font-size: 11px;
    }

    &-Links {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
    }

    &-Link {
        svg {
            width: 40px;

            path {
                transition: all .25s ease-out;
            }
        }

        &:hover {
            svg {
                path {
                    fill: var(--hollow-button-hover-color);

                    &[fill="none"] {
                        fill: none;
                        stroke: var(--hollow-button-hover-color);
                    }
                }
            }
        }
    }

    &-CopyLink {
        display: flex;
        align-items: center;
        margin-block: 20px;
        padding: 8px;
        border-radius: 25px;
        background-color: var(--secondary-light-color);

        &Text {
            flex: 1 1 auto;
            padding-inline: 8px;
            font-size: 11px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &Button {
            --button-background: var(--secondary-base-color);
            --button-hover-background: var(--secondary-dark-color);
            --button-border: var(--secondary-base-color);
            --button-hover-border: var(--secondary-dark-color);
            --button-height: 32px;
            --button-hover-height: 32px;
            --button-font-size: 11px;

            flex: 0 0 auto;
            width: auto;
        }

        &Success {
            // display: none;
            position: absolute;
            inset-block-start: calc(100% + 5px);
            width: 100%;
            font-size: 13px;
            font-style: italic;
            text-align: center;
            pointer-events: none;
            opacity: 0;
            transition: opacity .15s ease-in-out;

            &_isVisible {
                //display: block;
                opacity: 1;
            }
        }
    }
}
