/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --menu-column-gap: 30px;
    --menu-mobile-transition-duration: .2s;
    --menu-desktop-padding-inline: 15px;
    --menu-desktop-box-shadow: 0 10px 12px rgba(0, 0, 0, 0.1);

    @include wide-desktop {
        --menu-desktop-padding-inline: var(--content-wrapper-padding);
    }
}

.Menu {
    &-MenuWrapper {
        @include mobile {
            --border-width: 1px;

            //min-height: calc(100vh - var(--header-total-height) + 2 * var(--border-width));
            // margin-block-end: var(--footer-total-height);
            transform: translate(-150%);
            transition: transform 0.5s ease-in-out;
            position: fixed;
            top: calc(var(--header-nav-height) - var(--demo-notice-height));
            left: 0;
            width: 100%;
            height: calc(100dvh - var(--header-nav-height) - var(--demo-notice-height) - var(--top-page-banner-height));
            background-color: var(--color-white);
            overflow: auto;

            &_isVisible {
                transform: translateX(0);
            }
        }

        @include desktop {
            padding-inline: var(--menu-desktop-padding-inline);
        }
    }

    &-Link {
        font-size: 15px;

        @include desktop {
            font-size: #{fluid(12px, 14px, 811px, 1560px)};
        }
    }

    &-MainCategories {
        @include mobile {
            display: flex;
            flex-direction: column;
            min-height: 100%;
            transition: transform var(--menu-mobile-transition-duration) ease-out;
            transform: translateX(var(--menu-mobile-transform, 0));
        }

        @include desktop {
            display: flex;
            justify-content: space-between;
            gap: 2vw;
            width: auto;
        }

        @media (min-width: 1580px) {
            gap: 40px;
        }
    }

    &-Item {
        &_isNewProducts {
            .Menu-ItemCaption_type_main {
                font-weight: 700;
            }
        }

        @include mobile {
            border-block-end: 1px solid var(--primary-divider-color);

            &,
            &:last-child {
                margin-block-end: 0;
            }

            > .Menu-SubCatLink {
                > .Menu-SubMenu {
                    left: 100%;

                    .Menu-SubMenu {
                        left: 200%;

                        .Menu-SubMenu {
                            left: 300%;
                        }
                    }
                }
            }

            button {
                text-align: start;
                cursor: pointer;
            }
        }

        @include desktop {
            height: auto;

            &:not(:first-of-type) {
                margin-left: 0 !important;
            }

            &,
            &:last-child {
                margin-block-end: 16px;
            }

            > .Menu-Link {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
                font-size: clamp(12px, calc(0.27vw + 9.84px), 14px);
                color: var(--color-black);
            }

            .Menu-Link {
                &::after {
                    content: "";
                    display: block;
                    width: 100%;
                    border-block-end: 1px solid var(--color-black);
                    position: absolute;
                    left: 0;
                    bottom: -2px;
                    transition: transform .2s ease-in;
                    transform-origin: left center;
                    transform: scale(0, 1);
                }

                &:hover {
                    &::after {
                        transform: scale(1, 1);
                    }
                }
            }
        }

        &Caption {
            @include mobile {
                padding-inline-end: 25px;
            }

            &_type {
                &_main {
                    font-weight: 500;
                    line-height: 1.3;

                    @include desktop {
                        margin-block-end: 0;
                        text-align: center;
                    }
                }

                &_subcategory {
                    @include mobile {
                        margin-inline-end: 16px;
                        font-weight: 500;
                        line-height: 1.3;
                        text-transform: uppercase;
                    }
                }
            }
        }

        &List {
            &_type_main {
                @include desktop {
                    justify-content: space-between;
                    align-items: center;
                    gap: 15px;
                    flex: 1 1 auto;
                }
            }

            &_type_subcategory {
                @include mobile {
                    padding-block: 0;

                    > div {
                        border-bottom: 1px solid var(--primary-divider-color);
                    }
                }
            }
        }
    }

    &-SubCategoriesWrapper {
        position: absolute;
        width: 100%;
        inset-inline-start: 0;
        background-color: var(--color-white);
        box-shadow: var(--menu-desktop-box-shadow);

        /* &::before {
            @include full-page-cover(true);

            background-color: var(--color-white);
            border-block-end: 1px solid var(--primary-divider-color);
        } */

        &Inner {
            max-width: var(--content-wrapper-width);
            margin-inline: auto;
        }
    }

    &-SubCategories {
        @include desktop {
            padding-inline: var(--menu-desktop-padding-inline);
        }
    }

    &-Sub {
        &ItemWrapper {
            > .Menu-Link {
                text-transform: uppercase;
            }

            &_isPush {
                margin-block-start: 20px;
            }

            @include desktop {
                > .Menu-Link {
                    letter-spacing: .1em;

                    &::after {
                        display: inline-block;
                        content: "";
                        background: url($path-img + 'arrow.svg') no-repeat left center;
                        width: 5px;
                        height: 9px;
                        background-size: 100% 100%;
                        margin-left: 18px;
                    }
                }

                .Menu-Link {
                    figcaption {
                        &::before {
                            content: "";
                            display: block;
                            width: 100%;
                            border-block-end: 1px solid var(--color-black);
                            position: absolute;
                            left: 0;
                            bottom: 6px;
                            transition: transform .2s ease-in;
                            transform-origin: left center;
                            transform: scale(0, 1);
                        }
                    }

                    &:hover {
                        figcaption {
                            &::before {
                                transform: scale(1, 1);
                            }
                        }
                    }
                }

                .Menu-SubLevelDesktop {
                    .Menu-Link {
                        figcaption {
                            display: table;
                        }
                    }
                }
            }

            @include wide-desktop {
                > .Menu-Link {
                    letter-spacing: .2em;
                }
            }
        }

        &Menu {
            > .Menu-Link {
                > .Menu-ItemCaption_type_subcategory {
                    color: $default-primary-dark-color;
                    text-decoration: underline;
                    font-weight: 600;
                }
            }

            @include mobile {
                position: fixed;
                top: 0;
                width: 100%;
                // display: block;
                // pointer-events: none;
                opacity: 0;
                transition: all 0s linear;
                transition-delay: var(--menu-mobile-transition-duration);

                &_isVisible {
                    padding-inline: 0;
                    background: none;
                    // pointer-events: auto;
                    opacity: 1;
                    transition-delay: 0s;
                }

                .Menu-ItemCaption {
                    padding-block: 16px;
                }
            }

            @include desktop {
                display: grid;
                grid-template-columns: auto calc(33.3333% - (var(--menu-column-gap) * 2 / 3));
                row-gap: 0;
                column-gap: calc(var(--menu-column-gap) + 15px);

                > .Menu-Link {
                    display: block;
                    grid-column-start: span 2;
                }

                > .Menu-ItemList {
                    columns: 2;
                    column-gap: var(--menu-column-gap);

                    &:last-child {
                        columns: 3;
                        grid-column-start: span 2;
                    }
                }

                > .Menu-SubItemWrapper_isPush {
                    margin-block-start: 0;
                }
            }

            @include wide-desktop {
                grid-template-columns: auto calc(25% - (var(--menu-column-gap) * 3 / 4));

                > .Menu-ItemList {
                    columns: 3;

                    &:last-child {
                        columns: 4;
                    }
                }
            }
        }

        &ItemWrapperBack {
            padding-inline: 10px;
        }

        &ItemHeader {
            padding: 20px 15px;

            .Menu-Link {
                margin-block: 0;
            }

            .Menu-ItemCaption {
                padding: 0;

                &_isTitle {
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 1.2;
                    text-transform: uppercase;
                }

                &_isLink {
                    font-weight: 500;
                    font-size: 12px;
                    color: var(--color-link);
                    text-decoration: underline;
                    text-transform: none;
                }
            }
        }
    }

    &-BackButton {
        display: flex;
        align-items: center;
        width: 100%;

        .ChevronIcon {
            min-height: 38px;
            margin-inline-end: 6px;
            font-size: 12px;
        }
    }

    &-AdditionalMenu {
        li {
            &::before {
                display: none;
            }
        }

        &MinimizedItem {
            display: none;
        }

        &Inner {
            display: contents;

            @include mobile {
                > ul {
                    padding: 25px 16px;

                    li {
                        margin-block-end: 0;
                        font-weight: 300;

                        &:not(:first-child) {
                            margin-block-start: 16px;
                        }

                        a {
                            display: inline-flex;
                            // padding-block: 8px;
                            font-size: 15px;
                            font-weight: 300;
                            color: var(--color-black);
                        }

                        ul {
                            margin-block-start: 15px;
                            margin-inline-start: 25px;
                        }
                    }
                }
            }

            @include desktop {
                > ul {
                    display: flex;
                    justify-content: space-between;
                    gap: 2vw;

                    > li {
                        text-align: center;
                    }

                    li {
                        cursor: pointer;

                        &::after { // to prevent lost of hover on space between link and submenu
                            content: "";
                            display: none;
                            width: 100%;
                            height: 15px;
                            background: rgba(#fff, 0.01);
                            position: absolute;
                            top: 100%;
                            left: 0;
                        }

                        &,
                        a {
                            font-style: italic;
                            font-size: clamp(12px, calc(0.27vw + 9.84px), 14px);
                            color: var(--color-black);
                            font-weight: 700;
                            line-height: 1.3em;
                            text-rendering: optimizeLegibility;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                        }

                        a {
                            display: block;
                        }

                        ul {
                            display: none;
                            position: absolute;
                            inset-block-start: 100%;
                            inset-inline-end: -30px;
                            min-width: 100%;
                            padding: 15px;
                            background: var(--color-white);
                            box-shadow: var(--menu-desktop-box-shadow);
                            transform: translate(0, 10px); // prevent overlap cursive letters...
                            text-align: left;

                            li {
                                margin-block-end: 0;

                                &:not(:first-child) {
                                    margin-block-start: 15px;
                                }

                                &,
                                a {
                                    line-height: 1.3;
                                }

                                a {
                                    white-space: nowrap;
                                }
                            }
                        }

                        &:hover {
                            &::after {
                                display: block;
                            }

                            &,
                            > a {
                                color: var(--link-color);
                            }

                            ul {
                                display: block;
                            }
                        }
                    }
                }
            }

            @media (min-width: 1580px) {
                > ul {
                    gap: 40px;
                }
            }
        }

        @include small-desktop {
            &MinimizedItem {
                display: block;
                margin-block-end: 16px;
                font-size: 35px;
                line-height: 16px;
                cursor: pointer;

                &::after { // to prevent lost of hover on space between link and submenu
                    content: "";
                    display: none;
                    width: 100px;
                    height: 30px;
                    background: rgba(#fff, 0.01);
                    position: absolute;
                    inset-block-end: -25px;
                    inset-inline-end: calc(50% - 25px);
                }
            }

            &Inner {
                display: flex;
                align-items: center;
                height: 100%;

                > ul {
                    display: none;
                    position: absolute;
                    inset-block-start: 100%;
                    inset-inline-end: -15px;
                    padding: 15px 30px;
                    background: var(--color-white);
                    box-shadow: var(--menu-desktop-box-shadow);
                    white-space: nowrap;

                    > li {
                        margin-block: 0;
                        text-align: start;
                        cursor: default;

                        &:not(:first-child) {
                            margin-block-start: 15px;
                        }

                        &:hover {
                            color: var(--color-black);
                        }
                    }

                    li {
                        ul {
                            display: block;
                            position: relative;
                            inset-block-start: auto;
                            inset-inline-end: auto;
                            padding-block: 0;
                            padding-inline: 20px 0;
                            background: transparent;
                            box-shadow: none;
                            transform: none;

                            li {
                                margin-block-start: 15px;
                            }
                        }
                    }
                }

                &:hover {
                    .Menu-AdditionalMenuMinimizedItem {
                        &::after {
                            display: block;
                        }
                    }

                    > ul {
                        display: block;
                    }
                }
            }
        }
    }

    &-AdditionalLinks {
        --link-color: var(--default-color);

        display: flex;
        background-color: var(--secondary-light-color);

        > * {
            flex-grow: 1;

            &:not(:first-child) {
                border-left: 1px solid var(--primary-divider-color);
            }
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            font-size: 12px;
            text-align: center;
            text-transform: uppercase;
        }
    }

    &-AdditionalInformation {
        background-color: var(--secondary-light-color);
        border-top: 1px solid var(--primary-divider-color);
        padding-block: 20px;
        padding-inline: var(--content-wrapper-padding-mobile);

        @include mobile {
            margin-top: auto;
        }
    }

    &-RelatedPages {
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: var(--menu-column-gap);
        row-gap: 15px;
        padding: var(--content-wrapper-padding-mobile);

        @include desktop {
            grid-template-columns: repeat(3, 1fr);
            padding-block: 0 30px;
            padding-inline: var(--menu-desktop-padding-inline);

            > *:nth-child(n+4) {
                display: none;
            }
        }

        @include wide-desktop {
            grid-template-columns: repeat(4, 1fr);

            > *:nth-child(n+4) {
                display: inherit;
            }
        }
    }

    &-RelatedPage {
        &Link {
            display: grid;
            grid-template-columns: 40% auto;
            height: 100%;
            border: 1px solid var(--primary-divider-color);
        }

        &Visual {
            .Image {
                aspect-ratio: 1;

                &-Image {
                    object-fit: cover;
                }
            }
        }

        &Content {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 10% 12%;
        }

        &Type {
            margin-block-end: 0.75em;
            font-weight: 300;
            font-size: 13px;
            color: var(--color-black);
        }

        &Title {
            font-weight: 600;
            font-size: #{fluid(13px, 16px, 811px, 1560px)};
            color: var(--color-black);
        }

        @include mobile {
            &Link {
                grid-template-columns: 75px auto;
            }

            &Content {
                padding: 16px;
            }

            &Type {
                margin-block-end: 0;
                font-size: 12px;
            }

            &Title {
                font-weight: 700;
                font-size: 13px;
            }
        }
    }

    &-Push {
        &Image {
            aspect-ratio: 350 / 300;

            .Image-Image {
                object-fit: cover;
            }
        }

        &Content {
            background-color: var(--primary-base-color);
            color: #fff;
            z-index: 10;
            transition: all .3s ease;
            padding: 20px;
            padding-top: 0;

            &::after {
                content: " ";
                background-color: var(--primary-base-color);
                position: absolute;
                left: 0;
                right: 0;
                z-index: -1;
                top: -35px;
                transform: skewY(-7deg);
                height: 60px;
            }
        }

        &SuperTitle {
            margin-block-end: 0.5em;
            font-size: #{fluid(14px, 16px, 811px, 1560px)};
            font-weight: 700;
        }

        &Title {
            font-size: #{fluid(20px, 25px, 811px, 1560px)};
            font-weight: 300;
        }
    }
}
