/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CollectionsPage {
    &-Heading {
        @include mobile {
            text-align: center;
        }
    }

    &-Item {
        margin-block-end: 0;

        &::before {
            display: none;
        }

        @include mobile {
            margin-bottom: 8px;
        }
    }

    @include desktop {
        &-Items {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 24px;
            grid-row-gap: 22px;
        }

        &-Item {
            margin-block-end: 0;

            @for $i from 0 through 4 {
                &:nth-child(#{1 + $i * 26}) {
                    grid-column: 1 / span 2;
                    grid-row-start: 1 + $i * 8;
                    grid-row-end: span 2;

                    .CollectionCard {
                        &-Content {
                            padding: 45px 40px;
                        }

                        &-Category {
                            font-size: 22px;
                        }

                        &-Title {
                            font-size: 39px;
                        }
                    }
                }
            }

            @for $i from 0 through 4 {
                &:nth-child(#{16 + $i * 26}) {
                    grid-column: 3 / span 2;
                    grid-row-start: 5 + $i * 8;
                    grid-row-end: span 2;

                    .CollectionCard {
                        &-Content {
                            padding: 45px 40px;
                        }

                        &-Category {
                            font-size: 22px;
                        }

                        &-Title {
                            font-size: 39px;
                        }
                    }
                }
            }
        }
    }

    .CollectionCard {
        &-Content {
            padding: 28px 20px;
        }

        &-Category {
            @include mobile {
                font-size: 13px;
            }

            @include desktop {
                font-size: 15px;
            }
        }

        &-Title {
            font-weight: bold;

            @include mobile {
                font-size: 21px;
            }

            @include desktop {
                font-size: 22px;
            }
        }
    }

    &-LoadMore {
        text-align: center;

        @include desktop {
            margin-top: 60px;
        }

        @include mobile {
            margin-top: 12px;
        }

        .Button_isHollow {
            --hollow-button-padding: 65px;
            --hollow-button-hover-padding: var(--hollow-button-padding);
            --hollow-button-height: 40px;
            --hollow-button-hover-height: var(--hollow-button-height);
            --hollow-button-color: var(--secondary-base-color);
            --hollow-button-border: var(--secondary-base-color);
            --button-font-size: 10px;
        }
    }

    &-FilterBtn {
        @include filter-button();

        margin-block: 55px 35px;
    }

    &FilterPopup {
        .Popup-Main {
            display: flex;
            flex-direction: column;
            padding-block-end: 0;

            > *:last-child {
                margin-block-start: auto;
            }
        }
    }

    &-PopupAttributes {
        @include desktop {
            .ExpandableContent {
                &-Button {
                    padding-block: 15px;
                }

                &-Content {
                    max-height: 0;
                    overflow: hidden;
                    opacity: 0;
                    margin-top: 0;

                    &_isContentExpanded {
                        opacity: 1;
                        max-height: 100%;
                    }
                }
            }
        }
    }

    &-PopupActions {
        padding: 15px;
        border-block-start: 1px solid var(--primary-divider-color);

        @include desktop {
            padding: 30px 0;
        }
    }

    &-PopupSubmitButton {
        width: 100%;
    }
}
