/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.PageRelatedList {
    @include wrap();

    &-Title {
        margin-top: 0;
        margin-bottom: 1.3em;
        font-weight: var(--h1-font-weight);
        font-size: var(--h1-font-size);
        text-align: center;

        @include mobile {
            font-size: var(--h1-font-size-mobile);
        }
    }

    &-Content {
        &.swiper {
            @include mobile {
                margin-inline: calc(var(--content-wrapper-padding-mobile) * -1);
                padding-inline: calc(var(--content-wrapper-padding-mobile) * 2);
            }
        }
    }

    &-Items {
        &:not(.swiper-wrapper) {
            display: flex;
            justify-content: center;
            gap: 20px;

            @include desktop {
                > * {
                    flex-basis: calc(#{percentage(1 / 3)} - 13.33px);
                }
            }
        }

        &.swiper-wrapper {
            display: flex;
            gap: 0;
        }

        &_horizontal {
            display: flex;
            flex-direction: column;
            gap: 14px;
        }
    }

    &-Item {
        &,
        &:last-child {
            margin-block-end: 0;
        }

        &::before {
            display: none;
        }
    }
}
